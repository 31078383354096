import React, { useEffect } from "react";
import { darkTheme, lightTheme, GlobalStyle } from "./Config";
import { ThemeProvider } from "styled-components";
import { useTheme } from "contexts/ThemeContext";

const ModeProvider = ({ children }) => {
  const { Theme } = useTheme();
  const [mounted, setMounted] = React.useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const body = (
    <ThemeProvider theme={Theme === "DARK" ? darkTheme : lightTheme}>
      <GlobalStyle Mode={Theme} />
      {children}
    </ThemeProvider>
  );

  if (!mounted) {
    return <div style={{ visibility: "hidden" }}>{body}</div>;
  }

  return body;
};

export default ModeProvider;
