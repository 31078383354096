import * as anchor from "@project-serum/anchor";
import lp_idl from "idls/lpfinance.json";
import lpIncentives_Idl from "idls/lpIncentives.json";
import marinade_idl from "idls/marinade.json";
import {
  getCTokenInfo,
  config,
  NLP_SEED_PDA,
  nLP_MINT,
  NLP_STAKING_PROGRAM,
  SEED_ZSOL_MINT_AUTHORITY_PDA,
  zSOL_MINT,
  SEED_CONFIG,
} from "constants/global";
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
  getAccount,
  getAssociatedTokenAddress,
} from "@solana/spl-token";
import { AggregatorAccount } from "@switchboard-xyz/switchboard-v2";

const { Connection, PublicKey } = anchor.web3;

export const getConnection = () => {
  const Cluster = localStorage.getItem("web3.endpoint");

  var ClusterEnv;

  if (Cluster === "Custom") {
    const getCustomRpc = localStorage.getItem("web3.customeRpc");
    ClusterEnv = getCustomRpc;
  } else if (Cluster === "Quiknode") {
    ClusterEnv = process.env.REACT_APP_QUIKNODE_CLUSTER;
  }
  return new Connection(ClusterEnv, "processed");
};

export const getNetwork = () => {
  const Cluster = localStorage.getItem("web3.endpoint");
  const getCustomRpc = localStorage.getItem("web3.customeRpc");

  var networkEnv;

  if (getCustomRpc) {
    networkEnv = "mainnet-beta";
  } else if (Cluster === "Quiknode") {
    networkEnv = "mainnet-beta";
  }
  return networkEnv;
};

export const getProvider = (wallet) => {
  const anchorWallet = {
    publicKey: wallet.publicKey,
    signAllTransactions: wallet.signAllTransactions,
    signTransaction: wallet.signTransaction,
  };
  const connection = getConnection();
  const provider = new anchor.AnchorProvider(connection, anchorWallet, {
    preflightCommitment: "processed",
    skipPreflight: true,
  });
  return provider;
};

export const getProgram = (wallet, idl_name) => {
  let idl;
  if (idl_name === "lpIdl") {
    idl = lp_idl;
  } else if (idl_name === "lpIn_Idl") {
    idl = lpIncentives_Idl;
  } else if (idl_name === "marinade_idl") {
    idl = marinade_idl;
  }
  const provider = getProvider(wallet);

  anchor.setProvider(provider);
  const programId = new PublicKey(idl.metadata.address);
  const program = new anchor.Program(idl, programId);
  return program;
};

export const getATAPublicKey = async (tokenMint, owner) => {
  return await getAssociatedTokenAddress(
    tokenMint,
    owner,
    true,
    TOKEN_PROGRAM_ID,
    ASSOCIATED_TOKEN_PROGRAM_ID
  );
};

export const getTokenATABalance = async (connection, tokenATA) => {
  try {
    const tokenATAInfo = await getAccount(connection, tokenATA);
    return new anchor.BN(tokenATAInfo.amount.toString());
  } catch (error) {
    console.log(error);
  }
};

export const findAssociatedTokenAddress = async (
  walletAddress,
  tokenMintAddress
) => {
  return (
    await PublicKey.findProgramAddress(
      [
        walletAddress.toBuffer(),
        TOKEN_PROGRAM_ID.toBuffer(),
        tokenMintAddress.toBuffer(),
      ],
      ASSOCIATED_TOKEN_PROGRAM_ID
    )
  )[0];
};

export const tokenBalance = async (connection, ata) => {
  const accountInfo = await connection.getTokenAccountBalance(ata);
  return accountInfo.value.uiAmount;
};

export const convert_to_wei_value_with_decimal = (val, decimal) => {
  const decimalBN = Math.pow(10, decimal);
  const wei_value = Number(val) * Number(decimalBN);
  return new anchor.BN(wei_value.toFixed(0));
};

export const convert_from_wei_value_with_decimal = (wei_value, decimal) => {
  const decimalBN = Math.pow(10, decimal);
  const val = Number(wei_value) / Number(decimalBN);
  return val;
};

// Convert token amount to wei value: Important
export const convert_to_wei_value = (token_mint, amount) => {
  const ctoken_info = getCTokenInfo(token_mint);
  const decimal = ctoken_info.decimal;
  const decimalBN = Math.pow(10, decimal);
  const wei_value = Number(amount) * Number(decimalBN);
  return new anchor.BN(wei_value.toString());
};

// Convert token amount to wei value: Important
export const convert_from_wei_value = (token_mint, wei_value) => {
  const ctoken_info = getCTokenInfo(token_mint);
  const decimal = ctoken_info.decimal;
  const decimalBN = Math.pow(10, decimal);
  return Number(wei_value) / Number(decimalBN);
};

export const getSwitchboardPrice = async (program, switchboardFeed) => {
  const aggregator = new AggregatorAccount({
    program,
    publicKey: switchboardFeed,
  });
  const result = await aggregator.getLatestValue();
  return Number(result);
};

export const getTokenValue = async (
  switchboardProgram,
  amount,
  decimal,
  switchboardFeed
) => {
  const tokenPrice = await getSwitchboardPrice(
    switchboardProgram,
    switchboardFeed
  );
  const decimalPow = Math.pow(10, decimal);
  const tokenValue = (tokenPrice * Number(amount)) / Number(decimalPow);
  return Number(tokenValue);
};

export const getPendingRewardAmount = (
  new_reward_rate,
  prev_reward_rate,
  current_deposited_balance
) => {
  if (current_deposited_balance === 0) {
    return 0;
  } else if (new_reward_rate === prev_reward_rate) {
    return 0;
  } else {
    const added_reward_rate = new_reward_rate - prev_reward_rate;
    const added_reward_amount = added_reward_rate * current_deposited_balance;
    return added_reward_amount;
  }
};

//epoch_stability_fee_instructions
export const epoch_stability_fee_instructions = async (
  program,
  user_wallet,
  stability_fee
) => {
  const stabilityFeeData = await program.account.stabilityFee.fetch(
    stability_fee
  );
  const lastTs = stabilityFeeData.lastActionTs.toNumber();
  const freq = stabilityFeeData.frequency.toNumber();
  const cur = Date.now() / 1000;
  const tx = [];

  if (lastTs + freq < cur) {
    const zsolMintAuthorityPda = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_ZSOL_MINT_AUTHORITY_PDA)],
      program.programId
    );

    // eslint-disable-next-line no-unused-vars
    const [nlpConfig, _nlpBump] = await PublicKey.findProgramAddress(
      [Buffer.from(NLP_SEED_PDA), Buffer.from(nLP_MINT.toBuffer())],
      NLP_STAKING_PROGRAM
    );

    const NLP_POOL_AUTHORITY = await PublicKey.findProgramAddress(
      [Buffer.from(NLP_SEED_PDA)],
      NLP_STAKING_PROGRAM
    );

    const NLP_POOL_ATA = await getATAPublicKey(
      zSOL_MINT,
      NLP_POOL_AUTHORITY[0]
    );

    const configdata = await program.account.config.fetch(config);

    const zsol_fee_ata = await getATAPublicKey(
      zSOL_MINT,
      configdata.feeAccount
    );

    tx.push(
      await program.methods
        .epochStabilityFee()
        .accounts({
          userAuthority: user_wallet,
          config,
          stabilityFee: stability_fee,
          zsolMint: zSOL_MINT,
          zsolMintAuthorityPda: zsolMintAuthorityPda[0],
          zsolFeeAccount: zsol_fee_ata,
          nlpZsolAta: NLP_POOL_ATA,
          nlpConfig: nlpConfig,
          nlpStakingProgram: NLP_STAKING_PROGRAM,
          tokenProgram: TOKEN_PROGRAM_ID,
        })
        .instruction()
    );
  }
  return tx;
};

//lp_epoch_stability_fee_instructions
export const lp_epoch_stability_fee_instructions = async (
  nlpProgram,
  lpfinanceProgram,
  nlpconfig,
  user_wallet,
  stability_fee
) => {
  const stabilityFeeData = await lpfinanceProgram.account.stabilityFee.fetch(
    stability_fee
  );

  const lastTs = stabilityFeeData.lastActionTs.toNumber();
  const freq = stabilityFeeData.frequency.toNumber();
  const cur = Date.now() / 1000;
  const tx = [];

  if (lastTs + freq < cur) {
    const zsolMintAuthorityPda = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_ZSOL_MINT_AUTHORITY_PDA)],
      lpfinanceProgram.programId
    );

    // eslint-disable-next-line no-unused-vars
    const [lpconfig, _bump] = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_CONFIG)],
      lpfinanceProgram.programId
    );

    const NLP_POOL_AUTHORITY = await PublicKey.findProgramAddress(
      [Buffer.from(NLP_SEED_PDA)],
      nlpProgram.programId
    );

    const NLP_POOL_ATA = await getATAPublicKey(
      zSOL_MINT,
      NLP_POOL_AUTHORITY[0]
    );
    const configdata = await lpfinanceProgram.account.config.fetch(lpconfig);

    const zsol_fee_ata = await getATAPublicKey(
      zSOL_MINT,
      configdata.feeAccount
    );

    tx.push(
      await lpfinanceProgram.methods
        .epochStabilityFee()
        .accounts({
          userAuthority: user_wallet,
          config: lpconfig,
          stabilityFee: stability_fee,
          zsolMint: zSOL_MINT,
          zsolMintAuthorityPda: zsolMintAuthorityPda[0],
          zsolFeeAccount: zsol_fee_ata,
          nlpZsolAta: NLP_POOL_ATA,
          nlpConfig: nlpconfig,
          nlpStakingProgram: nlpProgram.programId,
          tokenProgram: TOKEN_PROGRAM_ID,
        })
        .instruction()
    );
  }
  return tx;
};

export const getBorrowedAmount = (userData, stabilityFeeData) => {
  try {
    const frequency = Number(stabilityFeeData.frequency);
    const lastActionTs = Number(stabilityFeeData.lastActionTs);
    const curTime = Date.now() / 1000;
    const epochCount = parseInt(
      ((curTime - lastActionTs) / frequency).toString()
    );
    const cur_global_fee_rate = Number(stabilityFeeData.totalMultiplier);
    const daily_fee_multiplier = Number(stabilityFeeData.dailyMultiplier);
    const multiplier = Math.pow(daily_fee_multiplier, epochCount);
    const global_fee_rate = cur_global_fee_rate * multiplier;
    const borrowed_amount = userData.borrowedZsolAmount;
    const user_fee_rate = Number(userData.stabilityFeeRate);
    const newBorrowedAmount =
      Number(borrowed_amount) * (global_fee_rate / user_fee_rate);

    return newBorrowedAmount;
  } catch (error) {
    return 0;
  }
};
