import React from "react";
import ScreenLoaderWrapper from "./ScreenLoader.style";

const ScreenLoader = ({ Theme }) => {
  return (
    <>
      <ScreenLoaderWrapper>
        <div className="snipper">
          <img
            src={Theme === "DARK" ? "/images/Logo.png" : "/images/xLogo.png"}
            alt="Loading..."
            loading="lazy"
          />
        </div>
      </ScreenLoaderWrapper>
    </>
  );
};

export default ScreenLoader;
