import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PublicKey } from '@solana/web3.js';

import BorrowWrapper from 'styles/Borrow.style';
import Card from 'Layout/Card';
import Button from 'Layout/Button';
import { useCrypto } from 'contexts/CryptoContext';
import { useContractSnackbar } from 'contexts/ContractSnackbarContext';
import { useGlobal } from 'contexts/GlobalContext';
import { useTheme } from 'contexts/ThemeContext';
import Overview from './Overview';
import Tabs from './Tabs';

const Borrow = () => {
  const [searchParams] = useSearchParams();
  const { Theme } = useTheme();
  const {
    PriceList,
    PriceHandler,
    BalanceList,
    BalanceHandler,
    wallet,
    publicKey,
  } = useCrypto();
  const { OpenContractSnackbar, ContractSnackbarType } = useContractSnackbar();
  const {
    cbsInfo,
    cbsUserInfo,
    handleCbsInfo,
    handleCbsUserInfo,
    handle_token_infos,
    token_infos,
    stabilityFeeInfo,
    handleCbsChart,
  } = useGlobal();

  const [Search, setSearch] = useState('');

  useEffect(() => {
    if (ContractSnackbarType === 'Success') {
      handleCbsInfo();
      handleCbsUserInfo(wallet, publicKey);
      handle_token_infos(wallet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ContractSnackbarType]);

  useEffect(() => {
    const wallet_search = searchParams.get('wallet');

    if (wallet_search) {
      setSearch(wallet_search);
      const Key = new PublicKey(wallet_search);
      handleCbsUserInfo(wallet, Key);
    } else if (!wallet_search) {
      if (publicKey) {
        handleCbsUserInfo(wallet, publicKey);
      }
    }
    return () => {
      setSearch('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, publicKey]);

  return (
    <>
      <BorrowWrapper pie={cbsInfo.NET_LTV} Theme={Theme}>
        <div className="container nofity_message">
          <div className="row">
            <div className="col-12">
              <Card
                active={1}
                br="20px"
                p="1rem 1.5rem"
                className="text-center py-4 alert"
                Theme={Theme}
              >
                <p>
                  LP Finance Protocol has been acquired by Saber DAO For
                  support, contact Saber DAO team
                </p>
                <Button
                  style={{ width: '200px' }}
                  active={5}
                  p="0.5rem 0.5rem"
                  br="4px"
                  size="0.9rem"
                  Theme={Theme}
                >
                  <a
                    href="https://discord.gg/kkGMk94MuR"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Saber Discord
                  </a>
                </Button>
              </Card>
            </div>
          </div>
        </div>
        <div className="container borrow">
          <div className="row mt-4">
            <div className="col-12 d-flex justify-content-center flex-column">
              <div className="borrow_title text-center">
                <h1>LP Finance Protocol</h1>
              </div>
              <div className="borrow_subtitle text-center d-flex flex-column">
                <p>Leverage Using zSOL</p>
                <p className="mt-1">
                  Stability Fee Epoch : {stabilityFeeInfo?.nextEpochDate}
                </p>
              </div>
            </div>
          </div>
          <Overview
            {...{
              ...cbsInfo,
              publicKey,
              handleCbsChart,
              Theme,
              stabilityFee: stabilityFeeInfo?.stabilityFee,
              maxMsolApy: stabilityFeeInfo?.maxMsolApy,
            }}
          />
          <Tabs
            {...{
              wallet,
              publicKey,
              PriceList,
              BalanceList,
              BalanceHandler,
              OpenContractSnackbar,
              PriceHandler,
              cbsUserInfo,
              Search,
              Theme,
              is_deposit_paused: cbsInfo.is_deposit_paused,
              is_withdraw_paused: cbsInfo.is_withdraw_paused,
              is_repay_paused: cbsInfo.is_repay_paused,
              is_borrow_paused: cbsInfo.is_borrow_paused,
              cTokenData: token_infos?.cTokenData,
              deposit_fee: cbsInfo.deposit_fee,
            }}
          />
        </div>
      </BorrowWrapper>
    </>
  );
};

export default Borrow;
