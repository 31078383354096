import React, { useContext, createContext, useState, useEffect } from "react";

export const ClusterContext = createContext();

export const ClusterProvider = ({ children }) => {
  const [Cluster, setCluster] = useState("");
  const [CustomCluster, setCustomCluster] = useState("");

  const changeCluster = (endpoint) => {
    setCluster(endpoint);
    localStorage.setItem("web3.endpoint", endpoint);
    localStorage.removeItem("web3.customeRpc");
    setCustomCluster("");
  };

  const customCluster = (endpoint) => {
    setCluster(endpoint);
    localStorage.setItem("web3.endpoint", endpoint);
    localStorage.setItem("web3.customeRpc", CustomCluster);
  };

  useEffect(() => {
    let getCluster = localStorage.getItem("web3.endpoint");
    let getClusterRpc = localStorage.getItem("web3.customeRpc");

    if (getClusterRpc) {
      setCustomCluster(getClusterRpc);
      setCluster("Custom");
      localStorage.setItem("web3.endpoint", "Custom");
    } else if (!getCluster) {
      setCluster("Quiknode");
      localStorage.setItem("web3.endpoint", "Quiknode");
    } else if (getCluster) {
      if (getCluster === "Quiknode") {
        setCluster(getCluster);
      } else {
        localStorage.setItem("web3.endpoint", "Quiknode");
        setCluster("Quiknode");
      }
    }
  }, []);

  return (
    <ClusterContext.Provider
      value={{
        Cluster,
        changeCluster,
        customCluster,
        setCustomCluster,
        CustomCluster,
      }}
    >
      {children}
    </ClusterContext.Provider>
  );
};

export const useCluster = () => useContext(ClusterContext);
