import * as anchor from "@project-serum/anchor";
import { loadSwitchboardProgram } from "@switchboard-xyz/switchboard-v2";
import { Keypair } from "@solana/web3.js";
import axios from "axios";
import api from "api";
import { TokenPriceRegistry } from "assets/registry";
import { getMint, nLP_MINT, zSOL_ATA, SOL_ATA } from "constants/global";
import { getConnection, getNetwork, getSwitchboardPrice } from "utils/contract";

export const getTokenPrice = async () => {
  try {
    const network = getNetwork();
    const connection = getConnection();

    const switchboardProgram = await loadSwitchboardProgram(
      network,
      connection,
      Keypair.fromSeed(new Uint8Array(32).fill(1))
    );

    const PriceList = [];
    let PriceListObj = {};

    for (let i = 0; i < TokenPriceRegistry.length; i++) {
      const { symbol, switchboardAccount } = TokenPriceRegistry[i];

      let price;

      if (symbol === "mSOL") {
        var res = await axios.get(api.mSOLPrice);
        price = res.data;
      } else if (symbol === "SOL-zSOL") {
        price = await getLpTokenPrice(connection);
      } else {
        price = await getSwitchboardPrice(
          switchboardProgram,
          switchboardAccount
        );
      }

      if (price) {
        PriceList.push({ price, symbol });
        PriceListObj = {
          ...PriceListObj,
          [symbol]: price,
        };
      } else {
        PriceList.push({ price: 0, symbol });
        PriceListObj = {
          ...PriceListObj,
          [symbol]: 0,
        };
      }
    }

    return {
      PriceList,
      PriceListObj,
    };
  } catch (error) {}
};

export const getBalance = async (token, publicKey, connection) => {
  try {
    if (token === "SOL") {
      if (publicKey) {
        const bal = await connection.getBalance(publicKey);
        return bal / 1000000000;
      } else {
        return 0.0;
      }
    } else {
      const mintAddress = getMint(token);

      const res = await connection.getTokenAccountsByOwner(publicKey, {
        mint: mintAddress,
      });

      if (res.value.length === 0) {
        return 0.0;
      } else {
        const balance = await connection.getParsedAccountInfo(
          new anchor.web3.PublicKey(res.value[0].pubkey.toString())
        );
        if (balance && balance.value) {
          return balance.value.data.parsed.info.tokenAmount.uiAmount;
        }
      }
    }
  } catch (error) {
    return 0.0;
  }
};

export const getLpTokenPrice = async (connection) => {
  try {
    let zSOL_Amount = await connection.getTokenAccountBalance(zSOL_ATA);
    let SOL_Amount = await connection.getTokenAccountBalance(SOL_ATA);

    let zSOL_Balance = parseInt(zSOL_Amount.value.amount) / 10 ** 9;
    let SOL_Balance = parseInt(SOL_Amount.value.amount) / 10 ** 9;

    let zSOL_res = await axios.get(`${api.price}?ids=zSOL`);
    let zSOL_values = zSOL_res.data.data;
    let zSOL_Price = zSOL_values["zSOL"].price;

    let SOL_res = await axios.get(`${api.price}?ids=SOL`);
    let SOL_Data_values = SOL_res.data.data;
    let SOL_Price = SOL_Data_values["SOL"].price;

    let zsolValue = zSOL_Balance * zSOL_Price;
    let solValue = SOL_Balance * SOL_Price;
    let tvl = zsolValue + solValue;

    let lpSupply = await connection.getTokenSupply(nLP_MINT);
    lpSupply = parseInt(lpSupply.value.amount) / 10 ** lpSupply.value.decimals;

    let lpTokenPrice = tvl / lpSupply;
    return lpTokenPrice;
  } catch (error) {
    let SOL_res = await axios.get(`${api.price}?ids=SOL`);
    let SOL_Data_values = SOL_res.data.data;
    let SOL_Price = SOL_Data_values["SOL"].price;
    return SOL_Price;
  }
};

