import styled from "styled-components";

const ButtonWrapper = styled.button`
  border: none;
  outline: none;
  width: 100%;
  padding: ${(props) => props.p};
  border-radius: ${(props) => props.br};
  font-size: ${(props) => props.size};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s;

  ${(props) =>
    props.active === 1 &&
    (props.Theme === "DARK"
      ? ` 
      color: #0c0;
      background: #161724;
      border-color: rgba(9, 9, 14, 0.5);
      box-shadow: 7px 7px 10px rgba(9, 9, 14, 0.5), -7px -7px 10px rgba(87, 87, 87, 0.2);
      font-weight:600;
  
      &:focus {
        box-shadow: inset -4px -4px 5px rgba(87, 87, 87, 0.2), inset 4px 4px 5px rgba(9, 9, 14, 0.5);
    `
      : `
      color: #7620e0ff;
      background: #e4ebf5;
      border-color: #bec8e4;
      box-shadow: 3px 3px 6px #bec8e4, -3px -3px 6px #fff;
      font-weight:600;
  
      &:focus {
        box-shadow: inset -2px -2px 5px #fff, inset 2px 2px 5px #bec8e4;
      }
  `)}

  ${(props) =>
    props.active === 2 &&
    (props.Theme === "DARK"
      ? ` 
      color:#0c0;
      background: none;
      
      &:hover {
        background: #1C1E2E;
      }
    `
      : `
      color: #3d3d3d;
      background: none;
   
      &:hover {
        background:  linear-gradient(85.12deg, rgba(185, 185, 241, 0.2) 0%, rgba(84, 132, 234, 0.2) 56.29%, rgba(58, 142, 137, 0.2) 99.99%);
      }
  `)}


  ${(props) =>
    props.active === 3 &&
    (props.Theme === "DARK"
      ? ` 
    color: #0c0;
    background: #161724;
    border-color: rgba(9, 9, 14, 0.5);
    box-shadow: 4px 4px 6px rgba(9, 9, 14, 0.5), -4px -4px 6px rgba(87, 87, 87, 0.2);
  `
      : `
    color: #3d3d3d;  
    border-color: #bec8e4;
    background: #e4ebf5;
    box-shadow: 2px 2px 5px #bec8e4, -2px -2px 5px #fff;
  `)}



  ${(props) =>
    props.active === 4 &&
    (props.Theme === "DARK"
      ? `
      color:#0c0;
      background: none;
    }
  `
      : ` 
      color: black;
      background: none;
    `)}

  ${(props) =>
    props.active === 5 &&
    (props.Theme === "DARK"
      ? `
      color:#0c0;
      font-weight:600;
      background: #161724;
      border-color: rgba(9, 9, 14, 0.5);
      box-shadow: 4px 4px 6px rgba(9, 9, 14, 0.5), -4px -4px 6px rgba(87, 87, 87, 0.2);
    `
      : `
      color: #7620e0ff;
      background: #e4ebf5;
      border-color: #bec8e4;
      box-shadow: 2px 2px 5px #bec8e4, -2px -2px 5px #fff;
      font-weight:600;
  `)}

  ${(props) =>
    props.active === 6 &&
    (props.Theme === "DARK"
      ? `
      color: rgba(255,255,255,0.6);
      background: #161724;
      box-shadow: 4px 4px 6px rgba(9, 9, 14, 0.5), -4px -4px 6px rgba(87, 87, 87, 0.2);
    }
    `
      : `
      color: black;
      box-shadow: 2px 2px 5px #bec8e4, -2px -2px 5px #fff;
  `)}

  @media only screen and (max-width: 600px) {
    ${(props) =>
      props.active === 2 &&
      `
      padding: 0rem;
      &:hover {
        background: none !important;
      }
    `}
  }
`;

export default ButtonWrapper;
