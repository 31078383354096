import React, { useEffect, memo } from "react";
import PieChartWrapper from "../CbsPieChartModel/PieChart.style";
import GlobalChart from "components/globalComponents/GlobalChart";
import { DayList } from "assets/registry";
import Button from "Layout/Button";
import {
  NlpTotalStakedChartList,
  NlpZsolAprChartList,
} from "assets/registry/LPIncentivesRegistry";
import DataLoader from "components/globalComponents/Loaders/DataLoader";

const NlpDataModel = ({
  isOpen,
  isClose,
  List,
  setDays,
  Days,
  loadingChart,
  Theme,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.querySelector(".popup").classList.add("active");
    }
  }, [isOpen]);

  const CloseModel = () => {
    document.querySelector(".popup").classList.remove("active");
    setTimeout(() => {
      isClose();
    }, 400);
  };

  return (
    <PieChartWrapper width="1200px" Theme={Theme}>
      <div className="popup">
        <div className="popup-container">
          <div className="container-fluid pieChart">
            <div className="row d-flex align-items-center pieChart_top_Section">
              <div className="col-lg-8 col-md-8 col-10 d-flex align-items-center">
                <div className="title">
                  <p>Data</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-2 close d-flex justify-content-end">
                <i className="zmdi zmdi-close" onClick={CloseModel} />
              </div>
            </div>
            {List?.length !== 0 && (
              <div className="row mt-4">
                <div className="col-12 period_list d-flex justify-content-lg-end justify-content-md-center justify-content-center">
                  <ul className="d-flex align-items-center">
                    {DayList.map((item, ind) => {
                      return (
                        <li
                          onClick={() => setDays(item.day)}
                          key={ind}
                          className="mx-2 mt-lg-0 mt-md-0 mt-2"
                        >
                          <Button
                            active={Days === item.day ? 5 : 6}
                            br="4px"
                            p="0.2rem 0.5rem"
                            id="btn"
                            size="0.8rem"
                            Theme={Theme}
                          >
                            {item.title}
                          </Button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            )}

            {loadingChart ? (
              <div className="col-12 message d-flex justify-content-center">
                <DataLoader size="2rem" h="430px" Theme={Theme} />
              </div>
            ) : (
              <div className="row pie_Section my-lg-3">
                {List?.length === 0 ? (
                  <div className="col-12 message d-flex justify-content-center mt-4">
                    <p>Data not found</p>
                  </div>
                ) : (
                  <>
                    <div className="col-lg-6 col-12  mt-3">
                      <div className="chart_title">
                        <span>Total Staked</span>
                      </div>
                      <div className="mt-3">
                        <GlobalChart
                          list={List}
                          filterList={NlpTotalStakedChartList}
                          height="300px"
                          Theme={Theme}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 mt-3">
                      <div className="chart_title">
                        <span>Rewards APY</span>
                      </div>
                      <div className="mt-3">
                        <GlobalChart
                          list={List}
                          filterList={NlpZsolAprChartList}
                          height="300px"
                          activeKey="%"
                          Theme={Theme}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </PieChartWrapper>
  );
};

export default memo(NlpDataModel);
