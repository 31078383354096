/* eslint-disable no-unused-vars */
import * as anchor from "@project-serum/anchor";
import { getDecimal } from "helper";
import { calculateMaxAmount } from "utils/lp-protocol/get_user_info";
import {
  getProgram,
  getATAPublicKey,
  getConnection,
  convert_to_wei_value,
  convert_to_wei_value_with_decimal,
  epoch_stability_fee_instructions,
} from "utils/contract";
import {
  SEED_PDA,
  SEED_SOL,
  SEED_TRV_PDA,
  zSOL_MINT,
  STATE_PUB,
  SEED_ZSOL_MINT_AUTHORITY_PDA,
  config,
  FEE_ADMIN,
  switchboardSolAccount,
  switchboardUsdcAccount,
  switchboardZsolAccount,
  cTokenInfoAccounts,
  getMint,
  getSwitchboardAccount,
  zSOL_DECIMAL,
  getSymbol,
} from "constants/global";
import {
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
} from "@solana/spl-token";

const { PublicKey, SystemProgram, SYSVAR_RENT_PUBKEY } = anchor.web3;

// deposit function for csb
// ==============================================
export const deposit_cbs = async (
  wallet,
  array,
  OpenCommand,
  PriceHandler,
  BalanceHandler
) => {
  try {
    const user_wallet = wallet.publicKey;

    if (!user_wallet) {
      OpenCommand(false, "Error", "Connect wallet");
      return;
    }

    const amount = parseFloat(array[1]);
    const token = array[2];
    const symbol = getSymbol(token);

    OpenCommand(true, "Processing", "Processing...");

    if (amount === 0) {
      OpenCommand(false, "Error", "Enter an amount");
      return;
    } else if (amount >= BalanceHandler[symbol]) {
      OpenCommand(false, "Error", "Insufficient Balance");
      return;
    } else if (amount <= getDecimal(10 / PriceHandler[symbol], 4)) {
      OpenCommand(
        false,
        "Error",
        `Required minimum amount - ${getDecimal(10 / PriceHandler[symbol], 4)}`
      );
      return;
    }

    const connection = getConnection();

    const program = getProgram(wallet, "lpIdl");

    const tokenMint = getMint(symbol);

    const switchboardAccount = getSwitchboardAccount(symbol);

    const configData = await program.account.config.fetch(config);

    const feeAccount = configData.feeAccount;

    const userAccountPDA = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from(user_wallet.toBuffer())],
      program.programId
    );

    let PDA;

    if (symbol === "SOL") {
      PDA = await PublicKey.findProgramAddress(
        [Buffer.from(SEED_SOL)],
        program.programId
      );
    } else {
      PDA = await PublicKey.findProgramAddress(
        [Buffer.from(SEED_PDA)],
        program.programId
      );
    }

    let userAta;
    let feeAta;
    let cbsAta;
    if (symbol !== "SOL") {
      userAta = await getATAPublicKey(tokenMint, user_wallet);
      feeAta = await getATAPublicKey(tokenMint, feeAccount);
      cbsAta = await getATAPublicKey(tokenMint, PDA[0]);
    }

    const userAccountInfo = await connection.getAccountInfo(userAccountPDA[0]);
    const tx = new anchor.web3.Transaction();

    const [stability_fee, _bump] = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from("stability_fee")],
      program.programId
    );

    if (userAccountInfo === null || userAccountInfo.data.length === 0) {
      tx.add(
        await program.methods
          .initUserAccount()
          .accounts({
            userAccount: userAccountPDA[0],
            userAuthority: user_wallet,
            feeAccount: FEE_ADMIN,
            stabilityFee: stability_fee,
            config,
            systemProgram: SystemProgram.programId,
            rent: SYSVAR_RENT_PUBKEY,
          })
          .instruction()
      );
    }

    const txInstructions = await epoch_stability_fee_instructions(
      program,
      user_wallet,
      stability_fee
    );
    txInstructions.forEach((txItem) => {
      tx.add(txItem);
    });

    if (symbol !== "SOL" && symbol !== "mSOL") {
      tx.add(
        await program.methods
          .deposit(convert_to_wei_value(tokenMint, amount))
          .accounts({
            tokenInstr: {
              token: tokenMint,
              cbsAta,
              userAta,
              feeAta,
            },
            commonDeposit: {
              userAuthority: user_wallet,
              userAccount: userAccountPDA[0],
              config,
              switchboardAcc: switchboardAccount,
              stabilityFee: stability_fee,
              ctokenInfoAccounts: cTokenInfoAccounts,
              systemProgram: SystemProgram.programId,
              tokenProgram: TOKEN_PROGRAM_ID,
              rent: SYSVAR_RENT_PUBKEY,
            },
          })
          .instruction()
      );
    } else if (symbol === "SOL") {
      tx.add(
        await program.methods
          .depositSol(convert_to_wei_value_with_decimal(amount, 9))
          .accounts({
            commonDeposit: {
              userAuthority: user_wallet,
              config,
              userAccount: userAccountPDA[0],
              switchboardAcc: switchboardAccount,
              stabilityFee: stability_fee,
              ctokenInfoAccounts: cTokenInfoAccounts,
              systemProgram: SystemProgram.programId,
              tokenProgram: TOKEN_PROGRAM_ID,
              rent: SYSVAR_RENT_PUBKEY,
            },
            solAccount: PDA[0],
            feeAccount,
          })
          .instruction()
      );
    } else if (symbol === "mSOL") {
      tx.add(
        await program.methods
          .depositMsol(convert_to_wei_value(tokenMint, amount))
          .accounts({
            tokenInstr: {
              token: tokenMint,
              cbsAta,
              userAta,
              feeAta,
            },
            commonDeposit: {
              userAuthority: user_wallet,
              userAccount: userAccountPDA[0],
              config,
              switchboardAcc: switchboardAccount,
              stabilityFee: stability_fee,
              ctokenInfoAccounts: cTokenInfoAccounts,
              systemProgram: SystemProgram.programId,
              tokenProgram: TOKEN_PROGRAM_ID,
              rent: SYSVAR_RENT_PUBKEY,
            },
            marinadeState: STATE_PUB,
          })
          .instruction()
      );
    }
    const provider = anchor.getProvider();
    const conTx = await provider.sendAndConfirm(tx);

    if (conTx) {
      OpenCommand(true, "Processing", `${amount} ${symbol} deposited`);
      OpenCommand(false, "Success", conTx);
    } else {
      OpenCommand(false, "Error", "Transaction Failed");
    }
  } catch (error) {
    OpenCommand(false, "Error", "Transaction Failed");
  }
};

// borrow function for csb
// ==============================================
export const borrow_cbs = async (wallet, array, OpenCommand) => {
  try {
    const user_wallet = wallet.publicKey;

    if (!user_wallet) {
      OpenCommand(false, "Error", "Connect wallet");
      return;
    }

    OpenCommand(true, "Processing", "Processing...");

    const token = array[0];
    const amount = parseFloat(array[1]);
    const symbol = getSymbol(token);

    if (amount === 0) {
      OpenCommand(false, "Error", "Enter an amount");
      return;
    }

    var getMaxAmount = await calculateMaxAmount(wallet, symbol, "Borrow");

    if (amount > getMaxAmount) {
      OpenCommand(false, "Error", "Borrow amount exceeded");
      return;
    }

    const program = getProgram(wallet, "lpIdl");

    const tokenMint = getMint(symbol);

    const is_max = false;

    const userAccountPDA = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from(user_wallet.toBuffer())],
      program.programId
    );

    const zsolMintAuthorityPda = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_ZSOL_MINT_AUTHORITY_PDA)],
      program.programId
    );

    const userZsolAta = await getATAPublicKey(tokenMint, user_wallet);

    const [stability_fee, _bump] = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from("stability_fee")],
      program.programId
    );

    const tx = new anchor.web3.Transaction();

    const txInstructions = await epoch_stability_fee_instructions(
      program,
      user_wallet,
      stability_fee
    );
    txInstructions.forEach((txItem) => {
      tx.add(txItem);
    });

    tx.add(
      await program.methods
        .borrow(convert_to_wei_value_with_decimal(amount, zSOL_DECIMAL), is_max)
        .accounts({
          userAuthority: user_wallet,
          userAccount: userAccountPDA[0],
          zsolMintAuthorityPda: zsolMintAuthorityPda[0],
          config,
          stabilityFee: stability_fee,
          zsolMint: tokenMint,
          userZsolAta,
          switchboardZsol: switchboardZsolAccount,
          switchboardSol: switchboardSolAccount,
          marinadeState: STATE_PUB,
          switchboardUsdc: switchboardUsdcAccount,
          ctokenInfoAccounts: cTokenInfoAccounts,
          systemProgram: SystemProgram.programId,
          tokenProgram: TOKEN_PROGRAM_ID,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          rent: SYSVAR_RENT_PUBKEY,
        })
        .instruction()
    );

    const provider = anchor.getProvider();
    const conTx = await provider.sendAndConfirm(tx);

    if (conTx) {
      OpenCommand(true, "Processing", `${amount} ${symbol} borrowed`);
      OpenCommand(false, "Success", conTx);
    } else {
      OpenCommand(false, "Error", "Transaction Failed");
    }
  } catch (error) {
    console.log(error);
    OpenCommand(false, "Error", "Transaction Failed");
  }
};

// withdraw function for csb
// ==============================================
export const withdraw_cbs = async (wallet, array, OpenCommand) => {
  try {
    const user_wallet = wallet.publicKey;

    if (!user_wallet) {
      OpenCommand(false, "Error", "Connect wallet");
      return;
    }

    OpenCommand(true, "Processing", "Processing...");

    const token = array[2];
    const amount = parseFloat(array[1]);
    const symbol = getSymbol(token);

    if (amount === 0) {
      OpenCommand(false, "Error", "Enter an amount");
      return;
    }

    var getMaxAmount = await calculateMaxAmount(wallet, symbol, "Withdraw");

    if (amount > getMaxAmount) {
      OpenCommand(false, "Error", "Withdraw amount exceeded");
      return;
    }

    const program = getProgram(wallet, "lpIdl");

    const tokenMint = getMint(symbol);

    const is_max = false;

    const userAccountPDA = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from(user_wallet.toBuffer())],
      program.programId
    );

    const PDA = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA)],
      program.programId
    );

    let switchboardDest;
    let userCollateralAta;
    let cbsCollateralAta;

    if (symbol !== "SOL") {
      switchboardDest = getSwitchboardAccount(symbol);
      userCollateralAta = await getATAPublicKey(tokenMint, user_wallet);
      cbsCollateralAta = await getATAPublicKey(tokenMint, PDA[0]);
    }

    const [stability_fee, _bump] = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from("stability_fee")],
      program.programId
    );

    const tx = new anchor.web3.Transaction();

    const txInstructions = await epoch_stability_fee_instructions(
      program,
      user_wallet,
      stability_fee
    );
    txInstructions.forEach((txItem) => {
      tx.add(txItem);
    });

    if (symbol !== "SOL" && symbol !== "mSOL") {
      tx.add(
        await program.methods
          .withdraw(convert_to_wei_value(tokenMint, amount), is_max)
          .accounts({
            commonWithdraw: {
              userAuthority: user_wallet,
              userAccount: userAccountPDA[0],
              programPda: PDA[0],
              config,
              switchboardSol: switchboardSolAccount,
              switchboardUsdc: switchboardUsdcAccount,
              marinadeState: STATE_PUB,
              stabilityFee: stability_fee,
              ctokenInfoAccounts: cTokenInfoAccounts,
            },
            tokenMint: tokenMint,
            userCollateralAta,
            cbsCollateralAta,
            switchboardDest: switchboardDest,
            systemProgram: SystemProgram.programId,
            tokenProgram: TOKEN_PROGRAM_ID,
            associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
            rent: SYSVAR_RENT_PUBKEY,
          })
          .instruction()
      );
    } else if (symbol === "SOL") {
      const SOL_PDA = await PublicKey.findProgramAddress(
        [Buffer.from(SEED_SOL)],
        program.programId
      );

      tx.add(
        await program.methods
          .withdrawSol(convert_to_wei_value_with_decimal(amount, 9), is_max)
          .accounts({
            commonWithdraw: {
              userAuthority: user_wallet,
              userAccount: userAccountPDA[0],
              programPda: PDA[0],
              config,
              switchboardSol: switchboardSolAccount,
              switchboardUsdc: switchboardUsdcAccount,
              marinadeState: STATE_PUB,
              stabilityFee: stability_fee,
              ctokenInfoAccounts: cTokenInfoAccounts,
            },
            solAccount: SOL_PDA[0],
            switchboardSol: switchboardSolAccount,
            systemProgram: SystemProgram.programId,
          })
          .instruction()
      );
    } else if (symbol === "mSOL") {
      tx.add(
        await program.methods
          .withdrawMsol(convert_to_wei_value(tokenMint, amount), is_max)
          .accounts({
            commonWithdraw: {
              userAuthority: user_wallet,
              userAccount: userAccountPDA[0],
              programPda: PDA[0],
              config,
              switchboardSol: switchboardSolAccount,
              switchboardUsdc: switchboardUsdcAccount,
              marinadeState: STATE_PUB,
              stabilityFee: stability_fee,
              ctokenInfoAccounts: cTokenInfoAccounts,
            },
            tokenMint: tokenMint,
            userCollateralAta,
            cbsCollateralAta,
            switchboardSol: switchboardSolAccount,
            systemProgram: SystemProgram.programId,
            tokenProgram: TOKEN_PROGRAM_ID,
            associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
            rent: SYSVAR_RENT_PUBKEY,
          })
          .instruction()
      );
    }

    const provider = anchor.getProvider();
    const conTx = await provider.sendAndConfirm(tx);

    if (conTx) {
      OpenCommand(true, "Processing", `${amount} ${symbol} withdrawn`);
      OpenCommand(false, "Success", conTx);
    } else {
      OpenCommand(false, "Error", "Transaction Failed");
    }
  } catch (error) {
    OpenCommand(false, "Error", "Transaction Failed");
  }
};

// repay function for csb
// ==============================================
export const repay_cbs = async (wallet, array, OpenCommand) => {
  try {
    const user_wallet = wallet.publicKey;

    if (!user_wallet) {
      OpenCommand(false, "Error", "Connect wallet");
      return;
    }

    OpenCommand(true, "Processing", "Processing...");

    const amount = parseFloat(array[1]);
    const token = array[2];
    const symbol = getSymbol(token);

    if (amount === 0) {
      OpenCommand(false, "Error", "Enter an amount");
      return;
    }

    var getMaxAmount = await calculateMaxAmount(wallet, symbol, "Repay");

    if (amount > getMaxAmount) {
      OpenCommand(false, "Error", "Repay amount exceeded");
      return;
    }

    const program = getProgram(wallet, "lpIdl");

    const tokenMint = getMint(symbol);

    const is_max = false;

    const userAccountPDA = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from(user_wallet.toBuffer())],
      program.programId
    );

    const PDA = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_TRV_PDA)],
      program.programId
    );

    const [stability_fee, _bump] = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from("stability_fee")],
      program.programId
    );

    const tx = new anchor.web3.Transaction();

    const userZsolAta = await getATAPublicKey(tokenMint, user_wallet);

    const txInstructions = await epoch_stability_fee_instructions(
      program,
      user_wallet,
      stability_fee
    );
    txInstructions.forEach((txItem) => {
      tx.add(txItem);
    });

    tx.add(
      await program.methods
        .repayZsol(
          convert_to_wei_value_with_decimal(amount, zSOL_DECIMAL),
          is_max
        )
        .accounts({
          userAuthority: user_wallet,
          userAccount: userAccountPDA[0],
          config,
          trvcAccount: PDA[0],
          zsolMint: zSOL_MINT,
          stabilityFee: stability_fee,
          userZsolAta,
          systemProgram: SystemProgram.programId,
          tokenProgram: TOKEN_PROGRAM_ID,
          rent: SYSVAR_RENT_PUBKEY,
        })
        .instruction()
    );

    const provider = anchor.getProvider();
    const conTx = await provider.sendAndConfirm(tx);

    if (conTx) {
      OpenCommand(true, "Processing", `${amount} ${symbol} repaid`);
      OpenCommand(false, "Success", conTx);
    } else {
      OpenCommand(false, "Error", "Transaction Failed");
    }
  } catch (error) {
    console.log(error);
    OpenCommand(false, "Error", "Transaction Failed");
  }
};
