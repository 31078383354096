import { loadSwitchboardProgram } from "@switchboard-xyz/switchboard-v2";
import { Keypair, PublicKey } from "@solana/web3.js";
import {
  getProgram,
  getConnection,
  getNetwork,
  getSwitchboardPrice,
  getTokenValue,
  convert_from_wei_value,
  convert_from_wei_value_with_decimal,
  getATAPublicKey,
  tokenBalance,
  getBorrowedAmount,
} from "../contract";
import {
  SEED_PDA,
  cTokenInfoAccounts,
  switchboardSolAccount,
  getCollateralTokenSymbol,
  zSOL_DECIMAL,
  getMint,
  STATE_PUB,
  MSOL_DENOMINATOR,
  getSwitchboardAccount,
} from "constants/global";

// Get tokenInfo index for the specific token
const getCTokenInfoIndex = (cTokenInfos, destToken, totalCount) => {
  for (let i = 0; i < totalCount; i++) {
    const cTokenInfo = cTokenInfos[i];
    if (cTokenInfo.tokenMint.equals(destToken)) {
      return i;
    }
  }
  return undefined;
};

/// Get Sum of collateral's Value
const getCollateralsValue = async (
  switchboardProgram,
  configData,
  cTokenInfos,
  totalCount,
  msol_rate
) => {
  try {
    const deposited_amounts = configData.depositedAmounts;

    let total_deposited_value = 0;
    let total_borrowed_value = 0;

    let collateral_infos = [];

    for (let i = 0; i < totalCount; i++) {
      const cTokenInfo = cTokenInfos[i];
      const idx = cTokenInfo.infoIndex;
      const is_active = cTokenInfo.isActive;

      if (is_active === false) continue;
      const name = getCollateralTokenSymbol(cTokenInfo.name);

      if (name === undefined) {
        throw new Error("Undefined token");
      }

      const switchboard_acc_pub = cTokenInfo.switchboardAccPub;
      const decimal = cTokenInfo.tokenDecimal;
      const borrow_rate = cTokenInfo.borrowableMaxLtv;

      let deposited_value = await getTokenValue(
        switchboardProgram,
        deposited_amounts[idx],
        decimal,
        switchboard_acc_pub
      );

      if (name === "mSOL") {
        deposited_value = (deposited_value * msol_rate) / MSOL_DENOMINATOR;
      }

      const borrowRate = borrow_rate / 100;
      const borrowedValue = deposited_value * Number(borrowRate);

      const amountNumber = Number(deposited_amounts[idx].toString());
      const decimalPow = Math.pow(10, decimal);
      const token_amount = amountNumber / decimalPow;

      let collateral_info = {
        idx,
        name,
        value: deposited_value,
        amount: token_amount,
      };

      collateral_infos.push(collateral_info);
      total_deposited_value = total_deposited_value + deposited_value;
      total_borrowed_value = total_borrowed_value + borrowedValue;
    }

    return {
      total_deposited_value,
      total_borrowed_value,
      collateral_infos,
    };
  } catch (err) {
    return {
      total_deposited_value: 0,
      total_borrowed_value: 0,
      collateral_infos: [],
    };
  }
};

// Get max depositable amount for the specific token
const getMaxDepositAmount = (
  userData,
  ctokenInfos,
  ctokenIndex,
  user_token_balance
) => {
  try {
    const ctokenInfo = ctokenInfos[ctokenIndex];
    const userDepositedAmount = convert_from_wei_value(
      ctokenInfo.tokenMint,
      userData.depositedAmounts[ctokenIndex]
    );

    const deposit_cap = ctokenInfo.depositCap;
    let max_amount = convert_from_wei_value(ctokenInfo.tokenMint, deposit_cap);

    if (max_amount === 0) return user_token_balance;

    return Math.min(max_amount - userDepositedAmount, user_token_balance);
  } catch (err) {
    return 0;
  }
};

// Get Borrowed Value
const getBorrowedValue = async (switchboardProgram, userLoanedAmount) => {
  try {
    const getSolPrice = await getTokenValue(
      switchboardProgram,
      userLoanedAmount,
      9,
      switchboardSolAccount
    );
    return getSolPrice;
  } catch (err) {
    return 0;
  }
};

export const fetch_user_infos = async (wallet, user_wallet) => {
  try {
    const network = getNetwork();
    const connection = getConnection();
    const program = getProgram(wallet, "lpIdl");
    const marinadeProgram = getProgram(wallet, "marinade_idl");

    const switchboardProgram = await loadSwitchboardProgram(
      network,
      connection,
      Keypair.fromSeed(new Uint8Array(32).fill(1))
    );

    const userAccountPDA = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from(user_wallet.toBuffer())],
      program.programId
    );

    // eslint-disable-next-line no-unused-vars
    const [stability_fee, _bump] = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from("stability_fee")],
      program.programId
    );

    const userData = await program.account.userAccount.fetch(userAccountPDA[0]);

    const stabilityFeeData = await program.account.stabilityFee.fetch(
      stability_fee
    );

    const marinadeStateData = await marinadeProgram.account.state.fetch(
      STATE_PUB
    );

    const userLoanedAmount = getBorrowedAmount(userData, stabilityFeeData);

    // ------Collaterals Info -----------------------
    const cToken_info_accounts_data =
      await program.account.cTokenInfoAccounts.fetch(cTokenInfoAccounts);

    const cTokenInfos = cToken_info_accounts_data.ctokenInfos;
    const totalCount = cToken_info_accounts_data.totalCount;

    // ===== get User Account info ======
    const { total_deposited_value, total_borrowed_value, collateral_infos } =
      await getCollateralsValue(
        switchboardProgram,
        userData,
        cTokenInfos,
        totalCount,
        Number(marinadeStateData.msolPrice.toString())
      );

    // Borrowed Value
    const borrowed_value = await getBorrowedValue(
      switchboardProgram,
      userLoanedAmount
    );
    const PercentStandard = Number(100);

    const borrowAmount = userLoanedAmount / 10 ** 9;

    const borrow_infos = [
      {
        idx: 1,
        amount: borrowAmount,
        value: borrowed_value,
        name: "zSOL",
      },
    ];

    const LTV = (borrowed_value * PercentStandard) / total_deposited_value;

    var collateral_type;

    for (let i = 0; i < collateral_infos.length; i++) {
      const { name, amount } = collateral_infos[i];
      if (amount !== 0) {
        collateral_type = name;
      }
    }

    return {
      TotalDeposited: total_deposited_value,
      CollateralInfos: collateral_infos,
      TotalBorrowed: borrowed_value,
      BorrowedInfos: borrow_infos,
      BorrowLimit: total_borrowed_value,
      LTV,
      collateral_type,
    };
  } catch (error) {
    return {
      TotalDeposited: 0,
      CollateralInfos: [],
      TotalBorrowed: 0,
      BorrowedInfos: [],
      BorrowLimit: 0,
      LTV: 0,
      collateral_type: "",
    };
  }
};

export const calculateMaxAmount = async (wallet, symbol, type, zSOLBalance) => {
  try {
    const user_wallet = wallet.publicKey;

    if (user_wallet) {
      const network = getNetwork();
      const connection = getConnection();
      const program = getProgram(wallet, "lpIdl");
      const marinadeProgram = getProgram(wallet, "marinade_idl");

      const destToken = getMint(symbol);

      let SwitchboardAccount;

      if (symbol === "zSOL") {
        SwitchboardAccount = getSwitchboardAccount("SOL");
      } else {
        SwitchboardAccount = getSwitchboardAccount(symbol);
      }

      const switchboardProgram = await loadSwitchboardProgram(
        network,
        connection,
        Keypair.fromSeed(new Uint8Array(32).fill(1))
      );

      const token_price = await getSwitchboardPrice(
        switchboardProgram,
        SwitchboardAccount
      );

      const userAccountPDA = await PublicKey.findProgramAddress(
        [Buffer.from(SEED_PDA), Buffer.from(user_wallet.toBuffer())],
        program.programId
      );

      // eslint-disable-next-line no-unused-vars
      const [stability_fee, _bump] = await PublicKey.findProgramAddress(
        [Buffer.from(SEED_PDA), Buffer.from("stability_fee")],
        program.programId
      );

      const userData = await program.account.userAccount.fetch(
        userAccountPDA[0]
      );

      const stabilityFeeData = await program.account.stabilityFee.fetch(
        stability_fee
      );

      const marinadeStateData = await marinadeProgram.account.state.fetch(
        STATE_PUB
      );

      const userLoanedAmount = getBorrowedAmount(userData, stabilityFeeData);

      const cToken_info_accounts_data =
        await program.account.cTokenInfoAccounts.fetch(cTokenInfoAccounts);

      const cTokenInfos = cToken_info_accounts_data.ctokenInfos;
      const totalCount = cToken_info_accounts_data.totalCount;

      const cTokenIndex = getCTokenInfoIndex(
        cTokenInfos,
        destToken,
        totalCount
      );

      const { total_borrowed_value } = await getCollateralsValue(
        switchboardProgram,
        userData,
        cTokenInfos,
        totalCount,
        Number(marinadeStateData.msolPrice.toString())
      );

      const borrowed_value = await getBorrowedValue(
        switchboardProgram,
        userLoanedAmount
      );
      const PercentStandard = Number(100);

      //=====calculate max value for symbol==========
      let MaxAmount;
      if (type === "Deposit") {
        // Max Deposited amount
        const user_ata = await getATAPublicKey(destToken, user_wallet);
        const user_token_balance = await tokenBalance(connection, user_ata);

        const MaxDepositedAmount = await getMaxDepositAmount(
          userData,
          cTokenInfos,
          cTokenIndex,
          user_token_balance
        );

        MaxAmount = MaxDepositedAmount;
      } else if (type === "Borrow") {
        // Max Borrowed amount
        const MaxBorrowedValue = total_borrowed_value - borrowed_value;
        const MaxBorrowedAmount = MaxBorrowedValue / token_price;
        MaxAmount = MaxBorrowedAmount;
      } else if (type === "Withdraw") {
        // -- Max withdraw amount
        const borrowed_val = total_borrowed_value - borrowed_value;
        const borrow_rate = cTokenInfos[cTokenIndex].borrowableMaxLtv;
        const withdrawableAmount =
          (borrowed_val * PercentStandard) / Number(borrow_rate) / token_price;
        const depositedAmountWei = userData.depositedAmounts[cTokenIndex];
        const depositedAmount = convert_from_wei_value(
          destToken,
          depositedAmountWei
        );
        const MaxWithdrawAmount = Math.min(withdrawableAmount, depositedAmount);

        MaxAmount = MaxWithdrawAmount;
      } else if (type === "Repay") {
        //Max Repay amount
        const loan_zSOLAmountWei = userLoanedAmount;
        const loan_zSOLAmount = convert_from_wei_value_with_decimal(
          loan_zSOLAmountWei,
          zSOL_DECIMAL
        );
        if (loan_zSOLAmount > zSOLBalance) {
          MaxAmount = zSOLBalance;
        } else {
          MaxAmount = loan_zSOLAmount;
        }
      }
      if (MaxAmount >= 0) {
        return MaxAmount;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  } catch (error) {
    return 0;
  }
};
