import styled from "styled-components";

const PSMWrapper = styled.div`
  .PSM {
    .PSM_title {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .PSM_subtitle {
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        color: ${(props) => props.theme.BodySecondaryColor};
      }
    }

    .PSM_section {
      .PSM_card {
        width: 90%;

        .Pay_section,
        .Receive_section {
          .title {
            font-size: 1.05rem;
            font-weight: 500;
            color: ${(props) => props.theme.BodyMainColor};
          }
          .balance {
            p {
              font-size: 0.89rem;
              color: ${(props) => props.theme.BodySecondaryColor};
            }
          }

          .model_btn {
            button {
              p {
                font-size: 1.1rem;
              }
            }
          }

          .input_form {
            span {
              color: ${(props) => props.theme.BodyPrimaryBg};
              font-size: 0.8rem;
            }
          }

          .value {
            span {
              color: ${(props) => props.theme.BodySecondaryColor};
              font-size: 0.8rem;
            }
          }
        }

        .details {
          .slider {
            p {
              font-size: 1.1rem;
              font-weight: 600;
              padding-left: 20px;
              color: ${(props) => props.theme.BodySecondaryColor};
            }
            input {
              -webkit-appearance: none !important;
              width: 420px;
              height: 2px;
              background: ${(props) => props.theme.BodyMainColor};
              border: none;
              outline: none;
            }
            input::-webkit-slider-thumb {
              -webkit-appearance: none !important;
              width: 25px;
              height: 25px;
              background: ${(props) => props.theme.BodyTraceryBg};
              border: 5px solid ${(props) => props.theme.BodyTraceryBg};
              border-radius: 50%;
              cursor: pointer;
            }
            input::-webkit-slider-thumb:hover {
              background: ${(props) => props.theme.BodyTraceryBg};
            }
          }

          table {
            tbody tr td {
              color: ${(props) => props.theme.BodyMainColor};
              font-size: 0.95rem;
            }
          }
        }

        .feeRate {
          p {
            color: ${(props) => props.theme.BodySecondaryColor};
            font-size: 0.8rem;
          }
        }

        .switch_section {
          .switch_icon_section {
            height: 2.4rem;
            width: 2.4rem;
            padding: 0rem;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            background: ${(props) => props.theme.lightBg};
            border-color: ${(props) => props.theme.lightBgDarkShadow};
            box-shadow: ${(props) => props.theme.boxShadowSecondary};
          }
        }
      }
    }
  }
`;

export default PSMWrapper;
