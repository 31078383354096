import { TokenImgRegistry } from "./index";
import { MintAddress } from "constants/global";

const JITOSOL = {
  address: MintAddress.JITOSOL,
  chainId: 101,
  decimals: 9,
  logoURI: TokenImgRegistry.JITOSOL,
  name: "Jito Staked SOL",
  symbol: "JITOSOL",
};

const zSOL = {
  address: MintAddress.zSOL,
  chainId: 101,
  decimals: 9,
  logoURI: TokenImgRegistry.zSOL,
  name: "zSOL",
  symbol: "zSOL",
};

const mSOL = {
  address: MintAddress.mSOL,
  chainId: 101,
  decimals: 9,
  logoURI: TokenImgRegistry.mSOL,
  name: "Marinade staked SOL",
  symbol: "mSOL",
};

const BSOL = {
  address: MintAddress.BSOL,
  chainId: 101,
  decimals: 9,
  logoURI: TokenImgRegistry.BSOL,
  name: "Blaze Staked SOL",
  symbol: "BSOL",
};

export var PSMRegistry = [zSOL, mSOL, JITOSOL, BSOL];

export var PSMRegistryRelation = {
  zSOL: [JITOSOL, BSOL, mSOL],
  JITOSOL: [zSOL],
  BSOL: [zSOL],
  mSOL: [zSOL],
};

export const PSMChartList = [
  {
    id: 1,
    name: "totalSwapSize",
    dataKey: "totalSwapSize",
    fill: "url(#totalSwapSize)",
    stroke: "#0c0",
    checked: true,
  },
];
