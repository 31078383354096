import { TokenImgRegistry } from "./index";
import { MintAddress } from "constants/global";

export var DepositTokens = [
  {
    address: MintAddress.mSOL,
    chainId: 101,
    decimals: 9,
    logoURI: TokenImgRegistry.mSOL,
    name: "Marinade staked SOL",
    symbol: "mSOL",
  },
  {
    address: MintAddress.BSOL,
    chainId: 101,
    decimals: 9,
    logoURI: TokenImgRegistry.BSOL,
    name: "Blaze Staked SOL",
    symbol: "BSOL",
  },
  {
    address: MintAddress.JITOSOL,
    chainId: 101,
    decimals: 9,
    logoURI: TokenImgRegistry.JITOSOL,
    name: "Jito Staked SOL",
    symbol: "JITOSOL",
  },
];

export var BorrowTokens = [
  {
    address: MintAddress.zSOL,
    chainId: 101,
    decimals: 9,
    logoURI: TokenImgRegistry.zSOL,
    name: "zSOL",
    symbol: "zSOL",
  },
];

export var WithdrawTokens = [
  {
    address: MintAddress.mSOL,
    chainId: 101,
    decimals: 9,
    logoURI: TokenImgRegistry.mSOL,
    name: "Marinade staked SOL",
    symbol: "mSOL",
  },
  {
    address: MintAddress.BSOL,
    chainId: 101,
    decimals: 9,
    logoURI: TokenImgRegistry.BSOL,
    name: "Blaze Staked SOL",
    symbol: "BSOL",
  },
  {
    address: MintAddress.JITOSOL,
    chainId: 101,
    decimals: 9,
    logoURI: TokenImgRegistry.JITOSOL,
    name: "Jito Staked SOL",
    symbol: "JITOSOL",
  },
];

export var RepayTokens = [
  {
    address: MintAddress.zSOL,
    chainId: 101,
    decimals: 9,
    logoURI: TokenImgRegistry.zSOL,
    name: "zSOL",
    symbol: "zSOL",
  },
];

export const TreasuryChartList = [
  {
    id: 1,
    name: "totalSupply",
    dataKey: "totalSupply",
    fill: "url(#totalSupply)",
    stroke: "#0c0",
    checked: true,
  },
  {
    id: 2,
    name: "totalBorrowed",
    dataKey: "totalBorrowed",
    fill: "url(#totalBorrowed)",
    stroke: "red",
    checked: true,
  },
];

export const xTreasuryChartList = [
  {
    id: 1,
    name: "totalSupply",
    dataKey: "totalSupply",
    fill: "url(#totalSupply)",
    stroke: "#7620e0ff",
    checked: true,
  },
  {
    id: 2,
    name: "totalBorrowed",
    dataKey: "totalBorrowed",
    fill: "url(#totalBorrowed)",
    stroke: "red",
    checked: true,
  },
];
