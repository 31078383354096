import * as anchor from "@project-serum/anchor";
import axios from "axios";
import { loadSwitchboardProgram } from "@switchboard-xyz/switchboard-v2";
import { Keypair } from "@solana/web3.js";
import api from "api";
import { collateral_infos_colors } from "assets/registry";
import {
  getProgram,
  getConnection,
  getNetwork,
  getTokenValue,
} from "../contract";
import {
  cTokenInfoAccounts,
  switchboardZsolAccount,
  config,
  SEED_PDA,
  getCollateralTokenSymbol,
  STATE_PUB,
  MSOL_DENOMINATOR,
  SEED_TRV_PDA,
} from "constants/global";
import { fetch_treasury_info } from "utils/treasury/get_treasury_info";

const { PublicKey } = anchor.web3;

/// Get Total Collateral Supply
const getTotalCollateralSupply = async (
  configData,
  cTokenInfos,
  totalCount,
  trvc_msol_amount,
  msol_rate,
  network,
  connection
) => {
  try {
    const switchboardProgram = await loadSwitchboardProgram(
      network,
      connection,
      Keypair.fromSeed(new Uint8Array(32).fill(1))
    );

    const deposited_amounts = configData.depositedAmounts;

    let collateral_infos = [];

    let total_value = Number(0);

    for (let i = 0; i < totalCount; i++) {
      const cTokenInfo = cTokenInfos[i];
      const idx = cTokenInfo.infoIndex;
      const is_active = cTokenInfo.isActive;

      if (is_active === false) continue;

      const symbol = getCollateralTokenSymbol(cTokenInfo.name);

      if (symbol === undefined) {
        throw new Error("Undefined token");
      }
      const switchboard_acc_pub = cTokenInfo.switchboardAccPub;
      const decimal = cTokenInfo.tokenDecimal;

      const collateral_amount =
        (symbol === "mSOL" ? Number(trvc_msol_amount) : 0) +
        Number(deposited_amounts[idx]);

      let token_value = await getTokenValue(
        switchboardProgram,
        collateral_amount,
        decimal,
        switchboard_acc_pub
      );

      if (symbol === "mSOL") {
        token_value = (token_value * msol_rate) / MSOL_DENOMINATOR;
      }

      const amountNumber = Number(deposited_amounts[idx].toString());
      const decimalPow = Math.pow(10, decimal);
      const token_amount = amountNumber / decimalPow;

      let collateral_info = {
        idx,
        symbol,
        value: token_value,
        amount: token_amount,
      };
      collateral_infos.push(collateral_info);
      total_value = total_value + token_value;
    }

    return {
      total_value,
      collateral_infos,
    };
  } catch (err) {
    return {
      total_value: 0,
      collateral_infos: [],
    };
  }
};

// Get TotalBorrowed Value
const getTotalBorrowedValue = async (configData, network, connection) => {
  try {
    const program = await loadSwitchboardProgram(
      network,
      connection,
      Keypair.fromSeed(new Uint8Array(32).fill(1))
    );

    const borrowed_amount = configData.borrowedZsolAmount;
    const getZSolPrice = await getTokenValue(
      program,
      borrowed_amount,
      9,
      switchboardZsolAccount
    );
    return getZSolPrice;
  } catch (err) {
    return 0;
  }
};

export const fetch_cbs_infos = async (wallet) => {
  try {
    const network = getNetwork();
    const connection = getConnection();
    const program = getProgram(wallet, "lpIdl");
    const { TotalSupply, LiquidStakingInfos } = await fetch_treasury_info(
      wallet
    );

    const marinadeProgram = getProgram(wallet, "marinade_idl");

    const configData = await program.account.config.fetch(config);

    const is_deposit_paused = configData.isDepositPaused;
    const is_withdraw_paused = configData.isWithdrawPaused;
    const is_repay_paused = configData.isRepayPaused;
    const is_borrow_paused = configData.isBorrowPaused;
    const deposit_fee = configData.depositFee;

    const marinadeStateData = await marinadeProgram.account.state.fetch(
      STATE_PUB
    );

    const msol_sol_rate = Number(marinadeStateData.msolPrice.toString());

    // ------------------- TotalSupply ---------------
    const cToken_info_accounts_data =
      await program.account.cTokenInfoAccounts.fetch(cTokenInfoAccounts);
    const cTokenInfos = cToken_info_accounts_data.ctokenInfos;
    const totalCount = cToken_info_accounts_data.totalCount;

    const trvcPDA = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_TRV_PDA)],
      program.programId
    );

    const trvcAccountData = await program.account.typelessRepaymentVault.fetch(
      trvcPDA[0]
    );

    const msol_amount_wei = trvcAccountData.msolAmount;

    const total_supply_info = await getTotalCollateralSupply(
      configData,
      cTokenInfos,
      totalCount,
      msol_amount_wei,
      msol_sol_rate,
      network,
      connection
    );

    const total_supply_value = total_supply_info.total_value + TotalSupply;
    const each_collateral_infos = total_supply_info.collateral_infos;

    // ------------------- Total Borrowed ---------------
    const total_borrowed_value = await getTotalBorrowedValue(
      configData,
      network,
      connection
    );

    const zSOLAmount = configData.borrowedZsolAmount;

    let NET_LTV;
    let TVL;

    if (total_supply_value.toString() !== "0") {
      const PercentStandard = Number(100);
      NET_LTV = (total_borrowed_value * PercentStandard) / total_supply_value;
    } else {
      NET_LTV = 0;
    }

    if (total_supply_value.toString() !== "0") {
      TVL = total_supply_value - total_borrowed_value;
    } else {
      TVL = 0;
    }

    const collateral_infos_sort = each_collateral_infos.sort(function (a, b) {
      return b.value - a.value;
    });

    const collateral_infos_list = collateral_infos_sort.map((items) => {
      var collateral;

      for (let i = 0; i < collateral_infos_colors.length; i++) {
        let { fill, symbol } = collateral_infos_colors[i];
        if (items.symbol === symbol) {
          collateral = {
            ...items,
            fill,
          };
        }
      }

      return collateral;
    });

    const collateral_infos = [];

    collateral_infos_list.forEach((item) => {
      LiquidStakingInfos.forEach((liquidate) => {
        const { name, value } = liquidate;
        if (item.symbol === name) {
          const calValue = item.value + value;
          collateral_infos.push({
            amount: item.amount,
            fill: item.fill,
            idx: item.idx,
            symbol: item.symbol,
            value: calValue,
          });
        }
      });
    });

    const borrowed_collateral_infos = [
      {
        idx: 1,
        symbol: "zSOL",
        amount: zSOLAmount / Math.pow(10, 9),
        value: total_borrowed_value,
        fill: "#0c0",
      },
    ];

    return {
      TotalSupply: total_supply_value,
      collateral_infos: collateral_infos,
      borrowed_collateral_infos,
      TotalBorrowed: total_borrowed_value,
      NET_LTV,
      TVL,
      is_deposit_paused,
      is_withdraw_paused,
      is_repay_paused,
      is_borrow_paused,
      deposit_fee,
    };
  } catch (error) {
    console.log(error);
    return {
      TotalSupply: 0,
      collateral_infos: [],
      borrowed_collateral_infos: [],
      TotalBorrowed: 0,
      NET_LTV: 0,
      TVL: 0,
      is_deposit_paused: true,
      is_withdraw_paused: true,
      is_repay_paused: true,
      is_borrow_paused: true,
      deposit_fee: 0,
    };
  }
};

export const get_stability_fee = async (wallet) => {
  try {
    const program = getProgram(wallet, "lpIdl");
    // eslint-disable-next-line no-unused-vars
    const [stability_fee, _bump] = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from("stability_fee")],
      program.programId
    );

    const stabilityFeeData = await program.account.stabilityFee.fetch(
      stability_fee
    );
    const lastActionTs = stabilityFeeData.lastActionTs;
    const nextEpochDate = await getNextEpochDate(lastActionTs);

    const stabilityFee = (stabilityFeeData.dailyMultiplier - 1) * 36500;

    let msolApy = 0;
    const res_mSOL_Apy = await axios.get(api.mSOL_Apy);

    if (res_mSOL_Apy.status === 200) {
      msolApy = res_mSOL_Apy.data.value * 100;
    }

    const maxMsolApy = 20 * (msolApy - stabilityFee);

    return {
      nextEpochDate,
      stabilityFee,
      maxMsolApy,
    };
  } catch (error) {
    return {
      nextEpochDate: "0hr : 0m",
      stabilityFee: 0,
      maxMsolApy: 0,
    };
  }
};

const getNextEpochDate = async (lastActionTs) => {
  try {
    const currentTs = Math.floor(Date.now() / 1000);
    const duration = ((lastActionTs.toNumber() - currentTs) % 86400) + 86400;
    const hours = Math.floor(duration / 3600);
    const mins = Math.floor((duration - hours * 3600) / 60);

    return `${hours}hr : ${mins}m`;
  } catch (error) {
    return "0hr : 0m";
  }
};
