import React, { useState, useCallback, useEffect, useMemo } from "react";
import StakeWrapper from "styles/PSM.style";
import { TokenImgRegistry } from "assets/registry";
import TokenModel from "models/TokenModel";
import PsmDataModel from "models/PsmDataModel";
import { PSMRegistry, PSMRegistryRelation } from "assets/registry/PsmRegistry";
import { useCrypto } from "contexts/CryptoContext";
import { burn_zSOL, mint_zSOL } from "lp-program/psm";
import { blockInvalidChar, getDecimal } from "helper";
import WalletButton from "components/globalComponents/WalletButton";
import { useContractSnackbar } from "contexts/ContractSnackbarContext";
import Card from "Layout/Card";
import Button from "Layout/Button";
import Image from "Layout/Image";
import Input from "Layout/Form/Input";
import { useGlobal } from "contexts/GlobalContext";
import { useTheme } from "contexts/ThemeContext";

const PSM = () => {
  const { Theme } = useTheme();
  const { OpenContractSnackbar, ContractSnackbarType } = useContractSnackbar();
  const {
    PriceList,
    BalanceList,
    BalanceHandler,
    PriceHandler,
    wallet,
    publicKey,
  } = useCrypto();
  const { treasuryInfo, handleTreasuryInfo, handlePsmChart } = useGlobal();
  const [isPayModel, setIsPayModel] = useState(false);
  const [isReceiveModel, setIsReceiveModel] = useState(false);
  const [message, setMessage] = useState("Claim mSOL");
  const [amount, setAmount] = useState("");
  const [PSM_Rate, setPSM_Rate] = useState("");
  const [Required, setRequired] = useState(false);
  const [RateLoading, setRateLoading] = useState(false);
  const [MaxLoading, setMaxLoading] = useState(false);
  const [isPsmModel, setIsPsmModel] = useState(false);
  const [PsmDays, setPsmDays] = useState(7);
  const [PsmChart, setPsmChart] = useState([]);
  const [loadingChart, setLoadingChart] = useState(false);

  const [PaySelected, setPaySelected] = useState({
    logoURI: TokenImgRegistry.zSOL,
    symbol: "zSOL",
    balance: 0,
    price: 0,
  });

  const [ReceiveSelect, setReceiveSelect] = useState({
    logoURI: TokenImgRegistry.mSOL,
    symbol: "mSOL",
    balance: 0,
    price: 0,
  });

  const handlePsmRate = async () => {
    setRateLoading(true);
    const PayUSDvalue = amount * PaySelected.price;
    const psm_rate = PayUSDvalue / ReceiveSelect.price;
    if (psm_rate) {
      setPSM_Rate(getDecimal(psm_rate, 9));
      setRateLoading(false);
    } else {
      setPSM_Rate("");
      setRateLoading(false);
    }
  };

  const getSubmitText = () => {
    let mess;
    const { symbol } = ReceiveSelect;
    if (symbol === "mSOL" || symbol === "BSOL" || symbol === "JITOSOL") {
      mess = `Claim ${symbol}`;
    } else {
      mess = `Mint ${symbol}`;
    }
    return mess;
  };

  const handleMaxAmount = () => {
    setMaxLoading(true);
    var mess = getSubmitText();
    setAmount(getDecimal(PaySelected.balance, 9));
    setMaxLoading(false);
    setMessage(mess);
    setRequired(true);
  };

  useMemo(() => {
    var list = PSMRegistryRelation[PaySelected.symbol];
    setReceiveSelect({
      logoURI: TokenImgRegistry[list[0].symbol],
      symbol: list[0].symbol,
      balance: BalanceHandler[list[0].symbol],
      price: PriceHandler[list[0].symbol],
    });
    if (publicKey && amount > 0) {
      handlePsmRate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PaySelected.symbol]);

  useMemo(() => {
    var list = PSMRegistryRelation[ReceiveSelect.symbol];
    setPaySelected({
      logoURI: TokenImgRegistry[list[0].symbol],
      symbol: list[0].symbol,
      balance: BalanceHandler[list[0].symbol],
      price: PriceHandler[list[0].symbol],
    });
    setMessage(getSubmitText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ReceiveSelect.symbol]);

  useCallback(() => {
    setPaySelected({
      ...PaySelected,
      balance: BalanceHandler[PaySelected.symbol],
    });
    setReceiveSelect({
      ...ReceiveSelect,
      balance: BalanceHandler[ReceiveSelect.symbol],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BalanceHandler]);

  useCallback(() => {
    setPaySelected({
      ...PaySelected,
      price: PriceHandler[PaySelected.symbol],
    });
    setReceiveSelect({
      ...ReceiveSelect,
      price: PriceHandler[ReceiveSelect.symbol],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PriceHandler]);

  const handleAmount = async (e) => {
    setAmount(e.target.value);

    if (e.target.value) {
      if (e.target.value <= PaySelected.balance) {
        var mess = getSubmitText();
        setMessage(mess);
        setRequired(true);
      } else {
        setMessage("Insufficient Balance");
        setRequired(false);
      }
    } else {
      setMessage("Enter an amount");
      setRequired(false);
    }
  };

  const HandleSwitch = () => {
    setAmount(getDecimal(PSM_Rate, 9));
    setPSM_Rate(getDecimal(amount, 9));
    setPaySelected({
      logoURI: ReceiveSelect.logoURI,
      symbol: ReceiveSelect.symbol,
      balance: ReceiveSelect.balance,
      price: PriceHandler[ReceiveSelect.symbol],
    });
    setReceiveSelect({
      logoURI: PaySelected.logoURI,
      symbol: PaySelected.symbol,
      balance: PaySelected.balance,
      price: PriceHandler[PaySelected.symbol],
    });
  };

  const handleProgram = async () => {
    if (amount > 0) {
      if (Required && publicKey) {
        setMaxLoading(true);

        if (Number(amount) <= PaySelected.balance) {
          setMaxLoading(false);

          if (
            (PaySelected.symbol === "mSOL" ||
              PaySelected.symbol === "BSOL" ||
              PaySelected.symbol === "JITOSOL") &&
            ReceiveSelect.symbol === "zSOL"
          ) {
            await mint_zSOL(
              wallet,
              PaySelected.symbol,
              ReceiveSelect.symbol,
              amount,
              setMessage,
              setAmount,
              setRequired,
              OpenContractSnackbar,
              PaySelected.price
            );
          } else if (
            PaySelected.symbol === "zSOL" &&
            (ReceiveSelect.symbol === "mSOL" ||
              ReceiveSelect.symbol === "BSOL" ||
              ReceiveSelect.symbol === "JITOSOL")
          ) {
            await burn_zSOL(
              wallet,
              ReceiveSelect.symbol,
              amount,
              setMessage,
              setAmount,
              setRequired,
              OpenContractSnackbar,
              PaySelected.price
            );
          }
        } else {
          setMaxLoading(false);
          setMessage("Exceed max input amount");
          setRequired(false);
        }
      }
    } else {
      setMessage("Enter an amount");
      setRequired(false);
    }
  };

  useEffect(() => {
    if (!(amount > 0) && publicKey) {
      setPSM_Rate("");
      return;
    }
    const debounceTime = setTimeout(() => {
      handlePsmRate();
    }, 300);

    return () => clearTimeout(debounceTime);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  useEffect(() => {
    if (ContractSnackbarType === "Success") {
      handleTreasuryInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ContractSnackbarType]);

  useEffect(() => {
    handlePsmChart(PsmDays, setLoadingChart, setPsmChart);
    return () => {
      setPsmChart([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PsmDays]);

  return (
    <>
      <StakeWrapper>
        <div className="container PSM mb-4 mt-lg-4 mt-md-4 mt-2">
          <div className="row">
            <div className="col-12 d-flex justify-content-center flex-column">
              <div className="PSM_title text-center">
                <h1>PSM</h1>
              </div>
              <div className="PSM_subtitle text-center">
                <p>Oracle Price Swap</p>
              </div>
            </div>
            {/* <div className="col-12 d-flex justify-content-center mt-3">
              <div>
                <Button
                  active={5}
                  br="7px"
                  p="0.5rem 4rem"
                  id="btn"
                  size="1.1rem"
                  onClick={() => setIsPsmModel(true)}
                  Theme={Theme}
                >
                  Data
                </Button>
              </div>
            </div> */}
          </div>
          <div className="row mt-4 d-flex justify-content-center">
            <div className="col-lg-5 col-md-5 col-12 PSM_section">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 d-flex justify-content-center">
                  <Card
                    active={1}
                    p="1.5rem 1rem 1rem 1rem"
                    br="18px"
                    className="PSM_card"
                    Theme={Theme}
                  >
                    <div className="Pay_section">
                      <div className="row">
                        <div className="col-3 d-flex align-items-center">
                          <div className="title">Pay</div>
                        </div>
                        <div className="col-9 d-flex justify-content-end align-items-center flex-row">
                          <div className="balance">
                            <p>Bal: {getDecimal(PaySelected.balance, 5)}</p>
                          </div>
                          <div className="max_btn ml-2">
                            <Button
                              active={3}
                              br="5px"
                              p="0.1rem 0.4rem"
                              id="btn"
                              size="0.85rem"
                              Theme={Theme}
                              disabled={
                                !publicKey ? true : MaxLoading ? true : false
                              }
                              className={
                                !publicKey
                                  ? "not-allowed"
                                  : MaxLoading
                                  ? "not-allowed"
                                  : null
                              }
                              onClick={handleMaxAmount}
                            >
                              Max
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-5 d-flex align-items-center">
                          <div className="model_btn">
                            <Button
                              active={2}
                              br="10px"
                              p="0.7rem 1rem"
                              id="btn"
                              className="d-flex align-items-center"
                              onClick={() => setIsPayModel(true)}
                              Theme={Theme}
                            >
                              <Image
                                src={PaySelected.logoURI}
                                alt={PaySelected.symbol}
                                h="2rem"
                                w="2rem"
                              />
                              <p className="mx-2">{PaySelected.symbol}</p>
                              <i className="zmdi zmdi-chevron-down" />
                            </Button>
                          </div>
                        </div>
                        <div className="col-7 d-flex justify-content-end align-items-center">
                          <div className="input_form">
                            <Input
                              name="amount"
                              id="amount"
                              type="number"
                              placeholder="0.0"
                              active={1}
                              Theme={Theme}
                              value={amount}
                              onChange={handleAmount}
                              onKeyDown={blockInvalidChar}
                              disabled={
                                !publicKey ? true : MaxLoading ? true : false
                              }
                              className={
                                !publicKey
                                  ? "not-allowed"
                                  : MaxLoading
                                  ? "not-allowed"
                                  : null
                              }
                              p="0.8rem 1rem"
                              br="10px"
                              size="1.2rem"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="switch_section mt-4">
                      <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                          <div
                            className="switch_icon_section"
                            onClick={() => HandleSwitch()}
                          >
                            <Image
                              src={
                                Theme === "DARK"
                                  ? "/images/icons/swap.png"
                                  : "/images/icons/xswap.png"
                              }
                              alt="swap"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* PSM receive section  */}
                    <div className="Receive_section mt-3">
                      <div className="row">
                        <div className="col-3 d-flex align-items-center">
                          <div className="title">Receive</div>
                        </div>
                        <div className="col-9 d-flex justify-content-end align-items-center flex-row">
                          <div className="balance">
                            <p>Bal: {getDecimal(ReceiveSelect.balance, 5)}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-5 d-flex align-items-center">
                          <div className="model_btn">
                            <Button
                              active={2}
                              br="10px"
                              p="0.7rem 1rem"
                              id="btn"
                              className="d-flex align-items-center"
                              onClick={() => setIsReceiveModel(true)}
                              Theme={Theme}
                            >
                              <Image
                                src={ReceiveSelect.logoURI}
                                alt={ReceiveSelect.symbol}
                                h="2rem"
                                w="2rem"
                              />
                              <p className="mx-2">{ReceiveSelect.symbol}</p>
                              <i className="zmdi zmdi-chevron-down" />
                            </Button>
                          </div>
                        </div>
                        <div className="col-7 d-flex justify-content-end align-items-center">
                          <div className="input_form">
                            <Input
                              name="amount"
                              id="amount"
                              className="not-allowed"
                              placeholder="0.0"
                              type="number"
                              value={
                                !PSM_Rate ? PSM_Rate : getDecimal(PSM_Rate, 9)
                              }
                              pattern="[0-9]*"
                              active={1}
                              p="0.6rem 1rem"
                              disabled={true}
                              br="10px"
                              Theme={Theme}
                              size="1.2rem"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {publicKey && (
                      <div className="row mt-2">
                        <div className="col-12 d-flex justify-content-end">
                          <div className="feeRate">
                            <p>
                              Fee:{" "}
                              {PaySelected.symbol === "zSOL"
                                ? treasuryInfo?.stability_fee_rate
                                : 0}
                              %
                            </p>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="PSM_btn_section mt-4">
                      <div className="row  d-flex justify-content-center">
                        <div className="col-12">
                          {!publicKey ? (
                            <WalletButton
                              br="50px"
                              fw="400"
                              active={1}
                              Theme={Theme}
                            />
                          ) : (
                            <>
                              {(treasuryInfo.to_zsol_paused &&
                                PaySelected.symbol === "zSOL") ||
                              (treasuryInfo.to_Xsol_paused &&
                                (PaySelected.symbol === "mSOL" ||
                                  PaySelected.symbol === "BSOL" ||
                                  PaySelected.symbol === "JITOSOL")) ? (
                                <Button
                                  active={1}
                                  br="50px"
                                  p="0.6rem 1rem"
                                  size="1.1rem"
                                  disabled={true}
                                  className="not-allowed"
                                  Theme={Theme}
                                >
                                  {PaySelected.symbol === "zSOL"
                                    ? "Mint zSOL has been stopped"
                                    : `Claim ${ReceiveSelect.symbol} has been stopped`}
                                </Button>
                              ) : (
                                <Button
                                  active={1}
                                  br="50px"
                                  p="0.6rem 1rem"
                                  id="btn"
                                  size="1.1rem"
                                  Theme={Theme}
                                  disabled={
                                    !publicKey
                                      ? true
                                      : RateLoading
                                      ? true
                                      : MaxLoading
                                      ? true
                                      : false
                                  }
                                  className={
                                    !publicKey
                                      ? "not-allowed"
                                      : RateLoading
                                      ? "not-allowed"
                                      : MaxLoading
                                      ? "not-allowed"
                                      : null
                                  }
                                  onClick={() => handleProgram()}
                                >
                                  {message}
                                </Button>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StakeWrapper>
      {isPayModel && (
        <TokenModel
          isOpen={isPayModel}
          isClose={() => setIsPayModel(false)}
          List={PSMRegistry.filter((list) => {
            return list.symbol !== PaySelected.symbol;
          })}
          setSelected={setPaySelected}
          PriceList={PriceList}
          BalanceList={BalanceList}
          Theme={Theme}
        />
      )}
      {isReceiveModel && (
        <TokenModel
          isOpen={setIsReceiveModel}
          isClose={() => setIsReceiveModel(false)}
          List={PSMRegistryRelation[PaySelected.symbol]}
          setSelected={setReceiveSelect}
          PriceList={PriceList}
          BalanceList={BalanceList}
          Theme={Theme}
        />
      )}
      {isPsmModel && (
        <PsmDataModel
          {...{ setPsmDays, PsmDays, loadingChart }}
          isOpen={isPsmModel}
          isClose={() => setIsPsmModel(false)}
          List={PsmChart}
          Theme={Theme}
        />
      )}
    </>
  );
};

export default PSM;
