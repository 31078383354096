import React, { useContext, createContext, useState, useEffect } from "react";

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [Theme, setTheme] = useState("");

  const handleTheme = (mode) => {
    setTheme(mode);
    localStorage.setItem("APP_THEME", mode);
  };

  useEffect(() => {
    const getTheme = localStorage.getItem("APP_THEME");

    if (getTheme) {
      setTheme(getTheme);
    } else {
      setTheme("DARK");
      localStorage.setItem("APP_THEME", "DARK");
    }

    return () => {
      setTheme("");
    };
  }, []);

  return (
    <ThemeContext.Provider value={{ Theme, handleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
