import React, { useEffect, memo, useState, useMemo } from "react";
import LiquidateT2Wrapper from "./LiquidateT2.style";
import Card from "Layout/Card";
import Button from "Layout/Button";
import Image from "Layout/Image";
import Input from "Layout/Form/Input";
import { TokenImgRegistry } from "assets/registry";
import { blockInvalidChar, getDecimal } from "helper";
import { get_swap_liquidation, get_max_liquidation } from "utils/Liquidate";
import { liquidate_step2 } from "lp-program/liquidate";

const LiquidateT2Model = ({
  isOpen,
  isClose,
  wallet,
  address,
  OpenContractSnackbar,
  BalanceHandler,
  Theme,
}) => {
  const [amount, setAmount] = useState("");
  const [message, setMessage] = useState("Liquidate");
  const [Rate, setRate] = useState("");
  const [Required, setRequired] = useState(false);
  const [RateLoading, setRateLoading] = useState(false);
  const [MaxLoading, setMaxLoading] = useState(false);
  const [MaxValue, setMaxValue] = useState("");

  const [PaySelected, setPaySelected] = useState({
    logoURI: TokenImgRegistry.mSOL,
    symbol: "mSOL",
    balance: 0,
  });

  const [ReceiveSelect] = useState({
    logoURI: TokenImgRegistry.USDC,
    symbol: "USDC",
  });

  useMemo(() => {
    setPaySelected({
      ...PaySelected,
      balance: BalanceHandler[PaySelected.symbol],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BalanceHandler]);

  useMemo(() => {
    setPaySelected({
      ...PaySelected,
      balance: BalanceHandler[PaySelected.symbol],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BalanceHandler]);

  useMemo(async () => {
    const max = await get_max_liquidation(wallet, address);
    setMaxValue(max);
  }, [address, wallet]);

  const handleAmount = async (e) => {
    setAmount(e.target.value);
    if (e.target.value) {
      if (e.target.value <= PaySelected.balance) {
        setMessage("Liquidate");
        setRequired(true);
      } else {
        setMessage("Insufficient Balance");
        setRequired(false);
      }
    } else {
      setMessage("Enter an amount");
      setRequired(false);
    }
  };

  const handleMaxAmount = async () => {
    setMaxLoading(true);
    setAmount(getDecimal(MaxValue, 9));
    setMaxLoading(false);
    setMessage("Liquidate");
    setRequired(true);
  };

  const handleRate = async () => {
    setRateLoading(true);
    const output_amount = await get_swap_liquidation(wallet, address, amount);
    if (output_amount) {
      setRate(getDecimal(output_amount, 9));
      setRateLoading(false);
    } else {
      setRate("");
      setRateLoading(false);
    }
  };

  const handleProgram = async () => {
    if (amount > 0) {
      if (Required) {
        setMaxLoading(true);
        const max = await get_max_liquidation(wallet, address);

        if (amount <= max) {
          setMaxLoading(false);
          liquidate_step2(
            address,
            wallet,
            OpenContractSnackbar,
            amount,
            setAmount,
            isClose,
            setMessage
          );
        } else {
          setMaxLoading(false);
          setMessage("Exceed max input amount");
          setRequired(false);
        }
      }
    } else {
      setMessage("Enter an amount");
      setRequired(false);
    }
  };

  useEffect(() => {
    if (!(amount > 0)) {
      setRate("");
      return;
    }
    handleRate();
    return () => {
      setRate("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  useEffect(() => {
    if (isOpen) {
      document.querySelector(".popup").classList.add("active");
    }
  }, [isOpen]);

  const CloseModel = () => {
    document.querySelector(".popup").classList.remove("active");
    setTimeout(() => {
      isClose();
    }, 400);
  };

  return (
    <LiquidateT2Wrapper width="380px" Theme={Theme}>
      <div className="popup">
        <div className="popup-container">
          <div className="container-fluid LiquidateT2Model">
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <Card active={1} p="1rem 1rem" br="18px" Theme={Theme}>
                  <div className="row d-flex align-items-center LiquidateT2Model_top_Section py-2">
                    <div className="col-lg-8 col-md-8 col-10 d-flex align-items-center">
                      <div className="title">
                        <p>Liquidate</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-2 close d-flex justify-content-end">
                      <i className="zmdi zmdi-close" onClick={CloseModel} />
                    </div>
                  </div>
                  <div className="Pay_section">
                    <div className="row mt-3">
                      <div className="col-lg-3 col-md-3 col-2 d-flex align-items-center">
                        <div className="title">Pay</div>
                      </div>

                      <div className="col-lg-9 col-md-9 col-10 d-flex justify-content-end align-items-center flex-row">
                        <div className="balance">
                          <p>Max Amount: {getDecimal(MaxValue, 5)}</p>
                        </div>
                        <div className="max_btn ml-2">
                          <Button
                            active={3}
                            br="5px"
                            p="0px 0.5rem"
                            id="btn"
                            onClick={handleMaxAmount}
                            size="0.85rem"
                            disabled={MaxLoading ? true : false}
                            className={MaxLoading ? "not-allowed" : null}
                            Theme={Theme}
                          >
                            Max
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-lg-5 col-md-5 col-4 d-flex align-items-center">
                        <div className="model_btn">
                          <Button
                            active={4}
                            br="10px"
                            p="0.7rem 0rem"
                            id="btn"
                            className="d-flex align-items-center"
                            Theme={Theme}
                          >
                            <Image
                              src={PaySelected.logoURI}
                              alt={PaySelected.symbol}
                              h="2rem"
                              w="2rem"
                            />
                            <p className="ml-2">{PaySelected.symbol}</p>
                          </Button>
                        </div>
                      </div>
                      <div className="col-lg-7 col-md-7 col-8 d-flex justify-content-end align-items-center">
                        <div className="input_form">
                          <Input
                            name="amount"
                            id="amount"
                            type="number"
                            placeholder="0.0"
                            value={amount}
                            onChange={handleAmount}
                            active={1}
                            disabled={MaxLoading ? true : false}
                            className={MaxLoading ? "not-allowed" : null}
                            onKeyDown={blockInvalidChar}
                            p="0.8rem 1rem"
                            br="10px"
                            size="1.2rem"
                            Theme={Theme}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-12 d-flex justify-content-end">
                      <div className="values">
                        <p> Bal: {getDecimal(PaySelected.balance, 5)} </p>
                      </div>
                    </div>
                  </div>

                  <div className="switch_section mt-4">
                    <div className="row">
                      <div className="col-12 d-flex justify-content-center">
                        <div className="switch_icon_section">
                          <Image
                            src={
                              Theme === "DARK"
                                ? "/images/icons/down.png"
                                : "/images/icons/xdown.png"
                            }
                            alt="swap"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* PSM receive section  */}
                  <div className="Receive_section mt-3">
                    <div className="row">
                      <div className="col-12 d-flex align-items-center">
                        <div className="title">Receive</div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-lg-5 col-md-5 col-4 d-flex align-items-center">
                        <div className="model_btn">
                          <Button
                            active={4}
                            br="10px"
                            p="0.7rem 0rem"
                            id="btn"
                            className="d-flex align-items-center"
                            Theme={Theme}
                          >
                            <Image
                              src={ReceiveSelect.logoURI}
                              alt={ReceiveSelect.symbol}
                              h="2rem"
                              w="2rem"
                            />
                            <p className="ml-2">{ReceiveSelect.symbol}</p>
                          </Button>
                        </div>
                      </div>
                      <div className="col-lg-7 col-md-7 col-8 d-flex justify-content-end align-items-center">
                        <div className="input_form">
                          <Input
                            name="amount"
                            id="amount"
                            className="not-allowed"
                            placeholder="0.0"
                            type="number"
                            pattern="[0-9]*"
                            active={1}
                            p="0.6rem 1rem"
                            disabled={true}
                            br="10px"
                            value={!Rate ? Rate : getDecimal(Rate, 9)}
                            size="1.2rem"
                            Theme={Theme}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="PSM_btn_section mt-4">
                    <div className="row">
                      <div className="col-12">
                        <Button
                          active={1}
                          br="50px"
                          p="0.6rem 1rem"
                          id="btn"
                          disabled={
                            RateLoading ? true : MaxLoading ? true : false
                          }
                          className={
                            RateLoading
                              ? "not-allowed"
                              : MaxLoading
                              ? "not-allowed"
                              : null
                          }
                          size="1.1rem"
                          onClick={() => handleProgram()}
                          Theme={Theme}
                        >
                          {message}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LiquidateT2Wrapper>
  );
};

export default memo(LiquidateT2Model);
