/* eslint-disable no-unused-vars */
import * as anchor from "@project-serum/anchor";
import {
  getProgram,
  getATAPublicKey,
  convert_to_wei_value,
  convert_to_wei_value_with_decimal,
  epoch_stability_fee_instructions,
} from "utils/contract";
import {
  SEED_TRV_PDA,
  SEED_ZSOL_MINT_AUTHORITY_PDA,
  zSOL_MINT,
  switchboardSolAccount,
  switchboardZsolAccount,
  switchboardbsolAccount,
  switchboardjitosolAccount,
  cTokenInfoAccounts,
  getMint,
  STATE_PUB,
  SEED_PDA,
  zSOL_DECIMAL,
  config,
} from "constants/global";
import {
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
} from "@solana/spl-token";

const { PublicKey, SystemProgram, SYSVAR_RENT_PUBKEY } = anchor.web3;

// deposit function for admin treasury
// ==============================================
export const deposit = async (
  wallet,
  symbol,
  amount,
  setMessage,
  setRequired,
  setAmount,
  OpenContractSnackbar
) => {
  try {
    OpenContractSnackbar(true, "Processing", `Deposit ${amount} ${symbol}`);

    const program = getProgram(wallet, "lpIdl");

    const user_wallet = wallet.publicKey;

    const token_mint = getMint(symbol);

    const PDA = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_TRV_PDA)],
      program.programId
    );

    const userCollateralAta = await getATAPublicKey(token_mint, user_wallet);
    const trvcCollateralAta = await getATAPublicKey(token_mint, PDA[0]);

    const [stability_fee, _bump] = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from("stability_fee")],
      program.programId
    );

    const tx = new anchor.web3.Transaction();

    const txInstructions = await epoch_stability_fee_instructions(
      program,
      user_wallet,
      stability_fee
    );
    txInstructions.forEach((txItem) => {
      tx.add(txItem);
    });

    tx.add(
      await program.methods
        .depositOnTrvc(convert_to_wei_value(token_mint, amount))
        .accounts({
          userAuthority: user_wallet,
          trvcAccount: PDA[0],
          config,
          ctokenInfoAccounts: cTokenInfoAccounts,
          stabilityFee: stability_fee,
          tokenMint: token_mint,
          userAta: userCollateralAta,
          trvcAta: trvcCollateralAta,
          tokenProgram: TOKEN_PROGRAM_ID,
        })
        .instruction()
    );

    const provider = anchor.getProvider();
    const conTx = await provider.sendAndConfirm(tx).catch((error) => {
      console.log(error);
    });

    if (conTx) {
      OpenContractSnackbar(true, "Success", "Transaction successful", conTx);

      setMessage("Enter an amount");
      setRequired(false);
      setAmount("");
    } else {
      OpenContractSnackbar(true, "Error", `Deposit failed. Please try again.`);
    }
  } catch (error) {
    console.log(error);
    OpenContractSnackbar(true, "Error", `Deposit failed. Please try again.`);
  }
};

// borrow function for admin treasury
// ==============================================
export const borrow_zSOL = async (
  wallet,
  symbol,
  amount,
  setMessage,
  setRequired,
  setAmount,
  OpenContractSnackbar
) => {
  try {
    OpenContractSnackbar(true, "Processing", `Borrow ${amount} ${symbol}`);

    const program = getProgram(wallet, "lpIdl");

    const user_wallet = wallet.publicKey;

    const PDA = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_TRV_PDA)],
      program.programId
    );

    const ZSOL_MINT_PDA = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_ZSOL_MINT_AUTHORITY_PDA)],
      program.programId
    );

    const userZsolAta = await getATAPublicKey(zSOL_MINT, user_wallet);

    const [stability_fee, _bump] = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from("stability_fee")],
      program.programId
    );

    const tx = new anchor.web3.Transaction();

    const txInstructions = await epoch_stability_fee_instructions(
      program,
      user_wallet,
      stability_fee
    );
    txInstructions.forEach((txItem) => {
      tx.add(txItem);
    });

    tx.add(
      await program.methods
        .borrowZsolFromTrvc(
          convert_to_wei_value_with_decimal(amount, zSOL_DECIMAL)
        )
        .accounts({
          trvcAccount: PDA[0],
          zsolMintAuthority: ZSOL_MINT_PDA[0],
          ctokenInfoAccounts: cTokenInfoAccounts,
          zsolMint: zSOL_MINT,
          config,
          userAta: userZsolAta,
          userAuthority: user_wallet,
          switchboardSol: switchboardSolAccount,
          switchboardZsol: switchboardZsolAccount,
          switchboardBsol: switchboardbsolAccount,
          switchboardJitosol: switchboardjitosolAccount,
          stabilityFee: stability_fee,
          marinadeState: STATE_PUB,
          systemProgram: SystemProgram.programId,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          tokenProgram: TOKEN_PROGRAM_ID,
          rent: SYSVAR_RENT_PUBKEY,
        })
        .instruction()
    );
    const provider = anchor.getProvider();
    const tnx = await provider.sendAndConfirm(tx);

    if (tnx) {
      OpenContractSnackbar(true, "Success", "Transaction successful", tnx);
      setMessage("Enter an amount");
      setRequired(false);
      setAmount("");
    } else {
      OpenContractSnackbar(true, "Error", `Borrow failed. Please try again.`);
    }
  } catch (error) {
    console.log(error);
    OpenContractSnackbar(true, "Error", `Borrow failed. Please try again.`);
  }
};

// withdraw function for admin treasury
// ==============================================
export const withdraw_collaterals = async (
  wallet,
  symbol,
  amount,
  setMessage,
  setRequired,
  setAmount,
  OpenContractSnackbar
) => {
  try {
    OpenContractSnackbar(true, "Processing", `Withdraw ${amount} ${symbol}`);

    const program = getProgram(wallet, "lpIdl");

    const user_wallet = wallet.publicKey;

    const token_mint = getMint(symbol);

    const PDA = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_TRV_PDA)],
      program.programId
    );

    const userCollateralAta = await getATAPublicKey(token_mint, user_wallet);
    const trvcCollateralAta = await getATAPublicKey(token_mint, PDA[0]);

    const [stability_fee, _bump] = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from("stability_fee")],
      program.programId
    );
    const tx = new anchor.web3.Transaction();

    const txInstructions = await epoch_stability_fee_instructions(
      program,
      user_wallet,
      stability_fee
    );
    txInstructions.forEach((txItem) => {
      tx.add(txItem);
    });

    tx.add(
      await program.methods
        .withdrawFromTrvc(convert_to_wei_value(token_mint, amount))
        .accounts({
          userAuthority: user_wallet,
          trvcAccount: PDA[0],
          config,
          ctokenInfoAccounts: cTokenInfoAccounts,
          tokenMint: token_mint,
          userAta: userCollateralAta,
          trvcAta: trvcCollateralAta,
          switchboardXsol: switchboardSolAccount,
          switchboardBsol: switchboardbsolAccount,
          switchboardJitosol: switchboardjitosolAccount,
          switchboardSol: switchboardSolAccount,
          marinadeState: STATE_PUB,
          stabilityFee: stability_fee,
          systemProgram: SystemProgram.programId,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          tokenProgram: TOKEN_PROGRAM_ID,
          rent: SYSVAR_RENT_PUBKEY,
        })
        .instruction()
    );
    const provider = anchor.getProvider();
    const tnx = await provider.sendAndConfirm(tx);

    if (tnx) {
      OpenContractSnackbar(true, "Success", "Transaction successful", tnx);

      setMessage("Enter an amount");
      setRequired(false);
      setAmount("");
    } else {
      OpenContractSnackbar(true, "Error", `Withdraw failed. Please try again.`);
    }
  } catch (error) {
    console.log(error);
    OpenContractSnackbar(true, "Error", `Withdraw failed. Please try again.`);
  }
};

// withdraw function for admin treasury
// ==============================================
export const repay = async (
  wallet,
  symbol,
  amount,
  setMessage,
  setRequired,
  setAmount,
  OpenContractSnackbar
) => {
  try {
    OpenContractSnackbar(true, "Processing", `Repay ${amount} ${symbol}`);

    const program = getProgram(wallet, "lpIdl");

    const user_wallet = wallet.publicKey;

    const PDA = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_TRV_PDA)],
      program.programId
    );

    const userZsolAta = await getATAPublicKey(zSOL_MINT, user_wallet);

    const [stability_fee, _bump] = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from("stability_fee")],
      program.programId
    );
    const tx = new anchor.web3.Transaction();

    const txInstructions = await epoch_stability_fee_instructions(
      program,
      user_wallet,
      stability_fee
    );
    txInstructions.forEach((txItem) => {
      tx.add(txItem);
    });
    tx.add(
      await program.methods
        .repayZsolFromTrvc(
          convert_to_wei_value_with_decimal(amount, zSOL_DECIMAL)
        )
        .accounts({
          trvcAccount: PDA[0],
          config,
          zsolMint: zSOL_MINT,
          userAta: userZsolAta,
          stabilityFee: stability_fee,
          userAuthority: user_wallet,
          tokenProgram: TOKEN_PROGRAM_ID,
        })
        .instruction()
    );
    const provider = anchor.getProvider();
    const tnx = await provider.sendAndConfirm(tx);

    if (tnx) {
      OpenContractSnackbar(true, "Success", "Transaction successful", tnx);

      setMessage("Enter an amount");
      setRequired(false);
      setAmount("");
    } else {
      OpenContractSnackbar(true, "Error", `Repay failed. Please try again.`);
    }
  } catch (error) {
    console.log(error);
    OpenContractSnackbar(true, "Error", `Repay failed. Please try again.`);
  }
};
