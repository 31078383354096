import {
  switchboardSolAccount,
  switchboardUsdcAccount,
  switchboardbsolAccount,
  switchboardjitosolAccount,
} from "constants/global";

export const TokenImgRegistry = {
  SOL: "https://lptokenbucket.s3.amazonaws.com/df32c49a4f133662fd78dc3449b0946d",
  mSOL: "https://lptokenbucket.s3.amazonaws.com/dff4db9f6e4d5e45c898d810cd6b381b",
  MSOL: "https://lptokenbucket.s3.amazonaws.com/dff4db9f6e4d5e45c898d810cd6b381b",
  stSOL:
    "https://lptokenbucket.s3.amazonaws.com/c8e60e5099ff1a97c766ca39dac5fe71",
  STSOL:
    "https://lptokenbucket.s3.amazonaws.com/c8e60e5099ff1a97c766ca39dac5fe71",
  UXD: "https://lptokenbucket.s3.amazonaws.com/8760a253c75f91ce069c1094350092a2",
  SAMO: "https://lptokenbucket.s3.amazonaws.com/1257088c4e5d2dff5585d1ef1e02bb2f",
  zSOL: "https://lptokenbucket.s3.amazonaws.com/30bd9d88075d07b6a14086c63e9ac124",
  LPFi: "https://lptokenbucket.s3.amazonaws.com/ce97477a6c673ea64233e88e06724341",
  USDC: "https://lptokenbucket.s3.amazonaws.com/c65abd7ddaeb4bd9dac7af64fe64a98b",
  xLPFi:
    "https://lptokenbucket.s3.amazonaws.com/eb58114ddefe431ec466a25f814ce10c",
  JITOSOL:
    "https://lptokenbucket.s3.amazonaws.com/jito.png",
  BSOL: "https://lptokenbucket.s3.amazonaws.com/7982602726bdf76d26d059d132b8222a",
};

export const RpcRegistry = [
  {
    id: 1,
    network: "Quiknode",
  },
];

export const NavbarRegistry = [
  {
    id: 1,
    name: "Borrow",
    pathName: "/",
    href: "/",
  },
  {
    id: 2,
    name: "PSM",
    pathName: "/psm",
    href: "/psm",
  },
  {
    id: 3,
    name: "LP Incentives",
    pathName: "/lp-incentives",
    href: "/lp-incentives",
  },
  {
    id: 4,
    name: "Treasury",
    pathName: "/treasury",
    href: "/treasury",
  },
  // {
  //   id: 5,
  //   name: "Liquidate",
  //   pathName: "/liquidate",
  //   href: "/liquidate",
  // },
  // {
  //   id: 6,
  //   name: "Referrals",
  //   pathName: "/referrals",
  //   href: "https://lpfinance.referral.qwestive.io/referral/XHvvfCupfjA5zFyGxcKa",
  // },
];

export const FooterRegistry = [
  {
    id: 1,
    name: "twitter",
    icon: "/images/media/twitter.png",
    url: "https://twitter.com/LPFinance_",
  },
  {
    id: 2,
    name: "telegram",
    icon: "/images/media/telegram.png",
    url: "https://t.me/LP_Defi_Official_group",
  },
  {
    id: 3,
    name: "medium",
    icon: "/images/media/medium.png",
    url: "https://medium.com/@LP_Finance",
  },
  {
    id: 4,
    name: "linkedin",
    icon: "/images/media/linkedin.png",
    url: "https://www.linkedin.com/company/lpdefi/",
  },
  {
    id: 5,
    name: "github",
    icon: "/images/media/github.png",
    url: "https://github.com/LP-Finance-Inc",
  },
  {
    id: 6,
    name: "discord",
    icon: "/images/media/discord.png",
    url: "https://discord.gg/ug7mstrHNW",
  },
];

export const xFooterRegistry = [
  {
    id: 1,
    name: "twitter",
    icon: "/images/media/xtwitter.png",
    url: "https://twitter.com/LPFinance_",
  },
  {
    id: 2,
    name: "telegram",
    icon: "/images/media/xtelegram.png",
    url: "https://t.me/LP_Defi_Official_group",
  },
  {
    id: 3,
    name: "medium",
    icon: "/images/media/xmedium.png",
    url: "https://medium.com/@LP_Finance",
  },
  {
    id: 4,
    name: "linkedin",
    icon: "/images/media/xlinkedin.png",
    url: "https://www.linkedin.com/company/lpdefi/",
  },
  {
    id: 5,
    name: "github",
    icon: "/images/media/xgithub.png",
    url: "https://github.com/LP-Finance-Inc",
  },
  {
    id: 6,
    name: "discord",
    icon: "/images/media/xdiscord.png",
    url: "https://discord.gg/ug7mstrHNW",
  },
];

export const TokenPriceRegistry = [
  {
    symbol: "SOL",
    switchboardAccount: switchboardSolAccount,
  },
  {
    symbol: "zSOL",
    switchboardAccount: switchboardSolAccount,
  },
  {
    symbol: "mSOL",
    switchboardAccount: "",
  },
  {
    symbol: "USDC",
    switchboardAccount: switchboardUsdcAccount,
  },
  {
    symbol: "JITOSOL",
    switchboardAccount: switchboardjitosolAccount,
  },
  {
    symbol: "BSOL",
    switchboardAccount: switchboardbsolAccount,
  },
  {
    symbol: "SOL-zSOL",
    switchboardAccount: switchboardSolAccount,
  },
];

export const TokenBalRegistry = [
  "SOL",
  "mSOL",
  "zSOL",
  "USDC",
  "JITOSOL",
  "BSOL",
  "SOL-zSOL",
];

export const collateral_infos_colors = [
  {
    symbol: "SOL",
    fill: "#ab4ff6",
  },
  {
    symbol: "mSOL",
    fill: "#a8f6d8",
  },
  {
    symbol: "USDC",
    fill: "#324ff7",
  },
  {
    symbol: "BSOL",
    fill: "#32d3f7",
  },
  {
    symbol: "JITOSOL",
    fill: "#3bf92f",
  },
];

export const DayList = [
  { day: 7, title: "7D" },
  { day: 30, title: "1M" },
  { day: 90, title: "3M" },
  { day: 180, title: "6M" },
];
