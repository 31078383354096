/* eslint-disable no-unused-vars */
import * as anchor from "@project-serum/anchor";
import axios from "axios";
import api from "api";
import {
  getProgram,
  getATAPublicKey,
  convert_to_wei_value,
  convert_to_wei_value_with_decimal,
  epoch_stability_fee_instructions,
} from "utils/contract";
import {
  SEED_PDA,
  SEED_TRV_PDA,
  SEED_ZSOL_MINT_AUTHORITY_PDA,
  zSOL_MINT,
  config,
  STATE_PUB,
  cTokenInfoAccounts,
  getMint,
  zSOL_DECIMAL,
  getSwitchboardAccount,
  switchboardSolAccount,
  switchboardZsolAccount,
} from "constants/global";
import {
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import { addPriorityFee } from "./priorityfee";

const { PublicKey, SystemProgram, SYSVAR_RENT_PUBKEY } = anchor.web3;

// burn_zSOL function for PSM
// ==============================================
export const burn_zSOL = async (
  wallet,
  tokenB,
  amount,
  setMessage,
  setAmount,
  setRequired,
  OpenContractSnackbar,
  price
) => {
  let mess;
  if (tokenB === "mSOL" || tokenB === "BSOL" || tokenB === "JITOSOL") {
    mess = `claim`;
  } else {
    mess = `mint`;
  }
  try {
    OpenContractSnackbar(true, "Processing", `Start ${mess} ${tokenB}...`);

    const program = getProgram(wallet, "lpIdl");

    const user_wallet = wallet.publicKey;

    const token_dest = getMint(tokenB);

    const configData = await program.account.config.fetch(config);
    const getFeeAccount = configData.feeAccount;

    const PDA = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_TRV_PDA)],
      program.programId
    );

    const switchboardDest = getSwitchboardAccount(tokenB);
    const userCollateralAta = await getATAPublicKey(token_dest, user_wallet);
    const userZsolAta = await getATAPublicKey(zSOL_MINT, user_wallet);
    const trvcCollateralAta = await getATAPublicKey(token_dest, PDA[0]);
    const feeAta = await getATAPublicKey(token_dest, getFeeAccount);

    const [stability_fee, _bump] = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from("stability_fee")],
      program.programId
    );

    const tx = new anchor.web3.Transaction();

    const txInstructions = await epoch_stability_fee_instructions(
      program,
      user_wallet,
      stability_fee
    );
    txInstructions.forEach((txItem) => {
      tx.add(txItem);
    });
    tx.add(addPriorityFee);
    tx.add(
      await program.methods
        .burnZsol(convert_to_wei_value_with_decimal(amount, zSOL_DECIMAL))
        .accounts({
          userAuthority: user_wallet,
          trvcAccount: PDA[0],
          zsolMint: zSOL_MINT,
          userZsolAta,
          stabilityFee: stability_fee,
          marinadeState: STATE_PUB,
          ctokenInfoAccounts: cTokenInfoAccounts,
          collateralToken: token_dest,
          userCollateralAta: userCollateralAta,
          trvcCollateralAta,
          config,
          feeAccount: feeAta,
          switchboardDest: switchboardDest,
          switchboardSol: switchboardSolAccount,
          systemProgram: SystemProgram.programId,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          tokenProgram: TOKEN_PROGRAM_ID,
          rent: SYSVAR_RENT_PUBKEY,
        })
        .instruction()
    );
    let messSucc;
    if (tokenB === "mSOL" || tokenB === "BSOL" || tokenB === "JITOSOL") {
      messSucc = `claimed`;
    } else {
      messSucc = `minted`;
    }

    const provider = anchor.getProvider();
    const conTx = await provider.sendAndConfirm(tx);

    if (conTx) {
      OpenContractSnackbar(true, "Success", "Transaction successful", conTx);

      setMessage("Enter an amount");
      setRequired(false);
      setAmount("");

      var swapSize = amount * price;

      await axios.post(api.storeSwapSize, {
        size: swapSize,
      });
    } else {
      OpenContractSnackbar(
        true,
        "Error",
        `Failed to ${mess} ${tokenB}. Please try again.`
      );
    }
  } catch (error) {
    console.log(error);
    OpenContractSnackbar(
      true,
      "Error",
      `Failed to ${mess} ${tokenB}. Please try again.`
    );
  }
};

// mint zSOL function for PSM
// ==============================================
export const mint_zSOL = async (
  wallet,
  tokenA,
  tokenB,
  amount,
  setMessage,
  setAmount,
  setRequired,
  OpenContractSnackbar,
  price
) => {
  try {
    OpenContractSnackbar(true, "Processing", `Start mint ${tokenB}...`);

    const program = getProgram(wallet, "lpIdl");

    const user_wallet = wallet.publicKey;

    const token_dest = getMint(tokenA);

    const PDA = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_TRV_PDA)],
      program.programId
    );

    const zSOL_MINT_PDA = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_ZSOL_MINT_AUTHORITY_PDA)],
      program.programId
    );

    const switchboardSrc = getSwitchboardAccount(tokenA);
    const userCollateralAta = await getATAPublicKey(token_dest, user_wallet);
    const userZsolAta = await getATAPublicKey(zSOL_MINT, user_wallet);
    const trvcCollateralAta = await getATAPublicKey(token_dest, PDA[0]);

    const [stability_fee, _bump] = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from("stability_fee")],
      program.programId
    );

    const tx = new anchor.web3.Transaction();

    const txInstructions = await epoch_stability_fee_instructions(
      program,
      user_wallet,
      stability_fee
    );
    txInstructions.forEach((txItem) => {
      tx.add(txItem);
    });
    tx.add(addPriorityFee);
    tx.add(
      await program.methods
        .mintZsol(convert_to_wei_value(token_dest, amount))
        .accounts({
          trvcAccount: PDA[0],
          zsolMintAuthority: zSOL_MINT_PDA[0],
          ctokenInfoAccounts: cTokenInfoAccounts,
          collateralToken: token_dest,
          userCollateralAta: userCollateralAta,
          zsolMint: zSOL_MINT,
          stabilityFee: stability_fee,
          userZsolAta,
          config,
          marinadeState: STATE_PUB,
          trvcCollateralAta,
          userAuthority: user_wallet,
          switchboardSol: switchboardSolAccount,
          switchboardZsol: switchboardZsolAccount,
          switchboardSrc: switchboardSrc,
          systemProgram: SystemProgram.programId,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          tokenProgram: TOKEN_PROGRAM_ID,
          rent: SYSVAR_RENT_PUBKEY,
        })
        .instruction()
    );

    const provider = anchor.getProvider();
    const conTx = await provider.sendAndConfirm(tx);

    if (conTx) {
      OpenContractSnackbar(true, "Success", "Transaction successful", conTx);

      setMessage("Enter an amount");
      setRequired(false);
      setAmount("");

      var swapSize = amount * price;

      await axios.post(api.storeSwapSize, {
        size: swapSize,
      });
    } else {
      OpenContractSnackbar(
        true,
        "Error",
        `Failed to mint ${tokenB}. Please try again.`
      );
    }
  } catch (error) {
    console.log(error);
    OpenContractSnackbar(
      true,
      "Error",
      `Failed to mint ${tokenB}. Please try again.`
    );
  }
};
