import React, { memo, useState, useMemo, useEffect } from "react";
import Input from "Layout/Form/Input";
import Button from "Layout/Button";
import Image from "Layout/Image";
import { DepositTokens } from "assets/registry/BorrowRegistry";
import TokenModel from "models/TokenModel";
import { TokenImgRegistry } from "assets/registry";
import { deposit_cbs } from "lp-program/borrow";
import { blockInvalidChar } from "helper";
import WalletButton from "components/globalComponents/WalletButton";
import { getDecimal } from "helper";

const Deposit = ({
  publicKey,
  PriceList,
  BalanceList,
  BalanceHandler,
  wallet,
  Theme,
  OpenContractSnackbar,
  PriceHandler,
  collateral_type,
  is_deposit_paused,
  deposit_fee,
}) => {
  const [selected, setSelected] = useState({
    logoURI: TokenImgRegistry.SOL,
    symbol: "SOL",
    balance: 0,
    price: 0,
  });

  const [isModel, setIsModel] = useState(false);
  const [message, setMessage] = useState("Deposit");
  const [amount, setAmount] = useState("");
  const [Required, setRequired] = useState(false);
  const [MaxLoading, setMaxLoading] = useState(false);

  useMemo(() => {
    setSelected({
      ...selected,
      balance: BalanceHandler[selected.symbol],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BalanceHandler]);

  useMemo(() => {
    setSelected({
      ...selected,
      price: PriceHandler[selected.symbol],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PriceHandler]);

  const handleAmount = (e) => {
    setAmount(e.target.value);

    if (e.target.value) {
      if (e.target.value <= selected.balance) {
        if (e.target.value >= getDecimal(10 / selected.price, 6)) {
          setMessage("Deposit");
          setRequired(true);
        } else {
          setMessage("Required minimum amount");
          setRequired(false);
        }
      } else {
        setMessage("Insufficient Balance");
        setRequired(false);
      }
    } else {
      setMessage("Enter an amount");
      setRequired(false);
    }
  };

  const CalculateMax = async () => {
    setMaxLoading(true);
    setAmount(getDecimal(selected.balance, 6));
    setMaxLoading(false);
    setMessage("Deposit");
    setRequired(true);
  };

  const handleProgram = async () => {
    if (amount > 0) {
      if (Required && publicKey) {
        if (!collateral_type) {
          await deposit_cbs(
            wallet,
            selected.symbol,
            amount,
            setMessage,
            setRequired,
            setAmount,
            OpenContractSnackbar
          );
        } else if (collateral_type === selected.symbol) {
          await deposit_cbs(
            wallet,
            selected.symbol,
            amount,
            setMessage,
            setRequired,
            setAmount,
            OpenContractSnackbar
          );
        } else {
          setRequired(false);
          setMessage("You can't deposit");
        }
      }
    } else {
      setMessage("Enter an amount");
      setRequired(false);
    }
  };

  useEffect(() => {
    setMessage("Deposit");
    setAmount("");
    setRequired(false);
  }, [selected]);

  return (
    <>
      <div className="row deposit d-flex justify-content-center">
        <div className="col-lg-11 col-md-10 col-12 my-3">
          <div className="deposit_card">
            <div className="row pt-1">
              <div className="col-7 d-flex align-items-center">
                <div className="input_form d-flex align-items-center flex-row">
                  <Input
                    name="amount"
                    id="amount"
                    type="number"
                    placeholder="0.0"
                    value={amount}
                    onChange={(e) => handleAmount(e)}
                    onKeyDown={blockInvalidChar}
                    active={2}
                    disabled={!publicKey ? true : MaxLoading ? true : false}
                    className={
                      !publicKey
                        ? "not-allowed"
                        : MaxLoading
                        ? "not-allowed"
                        : null
                    }
                    p="0.7rem 0rem 0.7rem 3.5rem"
                    br="10px"
                    Theme={Theme}
                  />

                  <div className="max_btn d-flex align-items-center">
                    <Button
                      active={3}
                      p="0.2rem 0.5rem"
                      br="4px"
                      size="0.8rem"
                      disabled={!publicKey ? true : MaxLoading ? true : false}
                      className={
                        !publicKey
                          ? "not-allowed"
                          : MaxLoading
                          ? "not-allowed"
                          : null
                      }
                      onClick={CalculateMax}
                      Theme={Theme}
                    >
                      Max
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-5 d-flex justify-content-end align-items-center">
                <div className="model_btn">
                  <Button
                    active={2}
                    p="0.6rem 1rem"
                    br="10px"
                    onClick={() => setIsModel(true)}
                    Theme={Theme}
                  >
                    {selected.logoURI && (
                      <Image
                        src={selected.logoURI}
                        alt={selected.symbol}
                        h="2rem"
                        w="2rem"
                      />
                    )}

                    <p className="mx-1">{selected.symbol}</p>
                    <i className="zmdi zmdi-chevron-down pl-1" />
                  </Button>
                </div>
              </div>
            </div>
            <div className="row min_amount">
              <div className="col-12">
                {publicKey && (
                  <>
                    <p>
                      Minimum amount-{" "}
                      {selected.price !== 0
                        ? getDecimal(10 / selected.price, 6)
                        : 0}
                    </p>
                    <p>Deposit Fee- {deposit_fee}%</p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 details mt-2">
          <div className="row d-flex justify-content-center">
            <div className="col-12 d-flex justify-content-center mt-3">
              {!publicKey ? (
                <WalletButton br="10px" fw="400" active={1} Theme={Theme} />
              ) : (
                <>
                  {is_deposit_paused ? (
                    <div className="btn_section">
                      <Button
                        active={1}
                        p="0.6rem 2rem"
                        br="10px"
                        disabled={true}
                        className="not-allowed"
                        Theme={Theme}
                      >
                        Deposit has been stopped
                      </Button>
                    </div>
                  ) : (
                    <div className="btn_section">
                      <Button
                        active={1}
                        p="0.6rem 2rem"
                        br="10px"
                        disabled={!publicKey ? true : MaxLoading ? true : false}
                        className={
                          !publicKey
                            ? "not-allowed"
                            : MaxLoading
                            ? "not-allowed"
                            : null
                        }
                        onClick={() => handleProgram()}
                        Theme={Theme}
                      >
                        {message}
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {isModel && (
        <TokenModel
          isOpen={isModel}
          isClose={() => setIsModel(false)}
          List={DepositTokens}
          setSelected={setSelected}
          PriceList={PriceList}
          BalanceList={BalanceList}
          Theme={Theme}
        />
      )}
    </>
  );
};

export default memo(Deposit);
