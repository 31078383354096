const DownIcon = ({ height, width, onClick, active }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 48 48"
    onClick={onClick}
  >
    <path
      fill="none"
      stroke={active}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10"
      d="M36 18L24 30L12 18"
    />
  </svg>
);

export default DownIcon;
