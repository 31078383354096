import React from "react";

export const Button = ({
  className,
  disabled,
  startIcon,
  onClick,
  tabIndex,
  endIcon,
  Theme,
  children,
}) => {
  return React.createElement(
    "button",
    {
      className:
        Theme === "DARK"
          ? `wallet-adapter-button  ${className || ""}`
          : `wallet-adapter-button xwallet-adapter-button ${className || ""}`,
      disabled: disabled,
      onClick: onClick,
      tabIndex: tabIndex || 0,
      type: "button",
    },
    startIcon &&
      React.createElement(
        "i",
        { className: "wallet-adapter-button-start-icon" },
        startIcon
      ),
    children,
    endIcon &&
      React.createElement(
        "i",
        { className: "wallet-adapter-button-end-icon" },
        endIcon
      )
  );
};
