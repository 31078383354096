import * as anchor from "@project-serum/anchor";
import { getMint, NLP_SEED_PDA } from "constants/global";
import {
  getProgram,
  getConnection,
  convert_from_wei_value_with_decimal,
} from "utils/contract";

const { PublicKey } = anchor.web3;

// get global LP Incentives info
export const get_config_info = async (wallet) => {
  try {
    const program = getProgram(wallet, "lpIn_Idl");

    const nlp_mint = getMint("nlp");

    // eslint-disable-next-line no-unused-vars
    const [config, _bump] = await PublicKey.findProgramAddress(
      [Buffer.from(NLP_SEED_PDA), Buffer.from(nlp_mint.toBuffer())],
      program.programId
    );

    const configData = await program.account.config.fetch(config);

    const is_paused = configData.isPaused;
    const get_total_staked_amount = configData.totalStakedAmount.toString();
    const total_staked_amount = convert_from_wei_value_with_decimal(
      get_total_staked_amount,
      9
    );

    return {
      total_staked_amount,
      is_paused,
    };
  } catch (error) {
    return {
      total_staked_amount: 0,
      is_paused: true,
    };
  }
};

// get global LP Incentives account info
export const get_staker_account_info = async (wallet) => {
  try {
    const user_wallet = wallet.publicKey;

    if (user_wallet) {
      const connection = getConnection(wallet);

      const program = getProgram(wallet, "lpIn_Idl");

      const stakerAccountPDA = await PublicKey.findProgramAddress(
        [Buffer.from(NLP_SEED_PDA), Buffer.from(user_wallet.toBuffer())],
        program.programId
      );

      const stakerAccountInfo = await connection.getAccountInfo(
        stakerAccountPDA[0]
      );
      const nlp_mint = getMint("nlp");

      // Config
      const configPDA = await PublicKey.findProgramAddress(
        [Buffer.from(NLP_SEED_PDA), Buffer.from(nlp_mint.toBuffer())],
        program.programId
      );
      if (stakerAccountInfo === null || stakerAccountInfo.data.length === 0) {
        return {
          staked_amount: 0,
          RewardList: [],
        };
      }

      const stakerData = await program.account.stakerAccount.fetch(
        stakerAccountPDA[0]
      );
      const configData = await program.account.config.fetch(configPDA[0]);

      const global_reward_rate = Number(configData.zsolRewardRate);
      const user_reward_rate = Number(stakerData.zsolRewardRate);

      const staked_amount = convert_from_wei_value_with_decimal(
        stakerData.stakedAmount,
        9
      );
      const currentRewardAmount = convert_from_wei_value_with_decimal(
        stakerData.zsolRewardAmount,
        9
      );
      const newRewardAmount =
        (global_reward_rate - user_reward_rate) * staked_amount;

      const rewardAmount = currentRewardAmount + newRewardAmount;

      const RewardList = [
        {
          id: 1,
          amount: rewardAmount,
          token: "zSOL",
        },
      ];
      return {
        staked_amount,
        RewardList,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      staked_amount: 0,
      RewardList: [],
    };
  }
};
