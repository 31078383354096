import { loadSwitchboardProgram } from "@switchboard-xyz/switchboard-v2";
import { Keypair, PublicKey } from "@solana/web3.js";
import {
  getProgram,
  getConnection,
  getNetwork,
  convert_from_wei_value_with_decimal,
  getBorrowedAmount,
  getSwitchboardPrice,
} from "../contract";
import {
  SEED_PDA,
  switchboardSolAccount,
  STATE_PUB,
  MSOL_DENOMINATOR,
} from "constants/global";

export const get_swap_liquidation = async (wallet, address, amount) => {
  try {
    const program = getProgram(wallet, "lpIdl");
    const marinadeProgram = getProgram(wallet, "marinade_idl");

    const network = getNetwork();
    const connection = getConnection();
    const cbsUserAddress = new PublicKey(address);

    // eslint-disable-next-line no-unused-vars
    const [stability_fee, _bump] = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from("stability_fee")],
      program.programId
    );

    const cbsUserAccountPDA = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from(cbsUserAddress.toBuffer())],
      program.programId
    );

    const switchboardProgram = await loadSwitchboardProgram(
      network,
      connection,
      Keypair.fromSeed(new Uint8Array(32).fill(1))
    );

    const stabilityFeeData = await program.account.stabilityFee.fetch(
      stability_fee
    );
    const cbsUserData = await program.account.userAccount.fetch(
      cbsUserAccountPDA[0]
    );
    const { depositedAmounts } = cbsUserData;
    let ctokenAmount = 0;
    const usdcDecimal = 6;
    depositedAmounts.forEach((item) => {
      if (Number(item) > 0) {
        ctokenAmount = convert_from_wei_value_with_decimal(item, usdcDecimal);
      }
    });
    const loanedAmount = getBorrowedAmount(cbsUserData, stabilityFeeData);
    const solPrice = await getSwitchboardPrice(
      switchboardProgram,
      switchboardSolAccount
    );

    const loanedValue =
      convert_from_wei_value_with_decimal(loanedAmount, 9) * solPrice;

    const marinadeStateData = await marinadeProgram.account.state.fetch(
      STATE_PUB
    );
    const msol_sol_rate = Number(marinadeStateData.msolPrice.toString());
    const msol_price = (msol_sol_rate * solPrice) / MSOL_DENOMINATOR;

    const inputMsolValue = msol_price * amount;

    let outputCtokenAmount = 0;
    if (inputMsolValue > loanedValue) {
      outputCtokenAmount = ctokenAmount;
    } else {
      outputCtokenAmount = (inputMsolValue / loanedValue) * ctokenAmount;
    }

    return outputCtokenAmount;
  } catch (error) {
    return 0;
  }
};

export const get_max_liquidation = async (wallet, address) => {
  try {
    const program = getProgram(wallet, "lpIdl");

    const marinadeProgram = getProgram(wallet, "marinade_idl");

    const network = getNetwork();
    const connection = getConnection();

    const cbsUserAddress = new PublicKey(address);

    const marinadeStateData = await marinadeProgram.account.state.fetch(
      STATE_PUB
    );

    // eslint-disable-next-line no-unused-vars
    const [stability_fee, _bump] = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from("stability_fee")],
      program.programId
    );

    const cbsUserAccountPDA = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from(cbsUserAddress.toBuffer())],
      program.programId
    );

    const switchboardProgram = await loadSwitchboardProgram(
      network,
      connection,
      Keypair.fromSeed(new Uint8Array(32).fill(1))
    );

    const stabilityFeeData = await program.account.stabilityFee.fetch(
      stability_fee
    );

    const cbsUserData = await program.account.userAccount.fetch(
      cbsUserAccountPDA[0]
    );

    const solPrice = await getSwitchboardPrice(
      switchboardProgram,
      switchboardSolAccount
    );

    const loanedAmount = getBorrowedAmount(cbsUserData, stabilityFeeData);
    const loanedValue =
      convert_from_wei_value_with_decimal(loanedAmount, 9) * solPrice;
    const msol_sol_rate = Number(marinadeStateData.msolPrice.toString());
    const msol_price = (msol_sol_rate * solPrice) / MSOL_DENOMINATOR;
    const maxRequiredAmount = loanedValue / msol_price;

    return maxRequiredAmount;
  } catch (error) {
    return 0;
  }
};
