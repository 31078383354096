import React, { memo, useState } from "react";
import { Link } from "react-router-dom";

import Button from "Layout/Button";
import { numFormatter, calc, getDecimal } from "helper";
import DataLoader from "components/globalComponents/Loaders/DataLoader";
import WalletButton from "components/globalComponents/WalletButton";
import { TokenImgRegistry } from "assets/registry";
import { liquidate_step1 } from "lp-program/liquidate";
import UpIcon from "icons/up-icon";
import DownIcon from "icons/down-icon";

const HeadersList = [
  {
    id: 1,
    header: "Debt",
    key: "debt",
  },
  {
    id: 2,
    header: "Collateral",
    key: "collateral",
  },
  {
    id: 3,
    header: "Address",
    key: "address",
  },
  {
    id: 4,
    header: "LR",
    key: "liquidatable_max_ltv",
  },
  {
    id: 5,
    header: "LTV",
    key: "ltv",
  },
  {
    id: 6,
    header: "Liquidate",
    key: "liquidate",
  },
];

const Table = ({
  docs,
  loading,
  publicKey,
  wallet,
  OpenContractSnackbar,
  setIsLiquidateT2,
  setCbsAddress,
  Theme,
  collateral_type,
  handleOrder,
  handleFilterKey,
  order,
  filterKey,
}) => {
  const [Loading, setLoading] = useState(false);

  const handle_liquidatable = (address) => {
    liquidate_step1(
      address,
      wallet,
      OpenContractSnackbar,
      setLoading,
      collateral_type
    );
  };

  return (
    <div className="row mt-4 pt-1 Liquidate_table_section pb-3">
      {loading ? (
        <div className="col-12 d-flex justify-content-center mt-4">
          <DataLoader size="2rem" Theme={Theme} />
        </div>
      ) : (
        <>
          {docs?.length > 0 ? (
            <div className="col-12">
              <div className="table_card">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      {HeadersList.map((head, ind) => {
                        return (
                          <th scope="col" key={head.id}>
                            <span className="icons-group">
                              <p>{head.header}</p>
                              {(ind === 0 || ind === 1 || ind === 4) && (
                                <span>
                                  <UpIcon
                                    height={10}
                                    width={10}
                                    active={
                                      filterKey === head.key
                                        ? order === "DESC"
                                          ? Theme === "DARK"
                                            ? "white"
                                            : "black"
                                          : Theme === "DARK"
                                          ? "#0f0"
                                          : "#7620e0ff"
                                        : Theme === "DARK"
                                        ? "#0f0"
                                        : "#7620e0ff"
                                    }
                                    onClick={() => {
                                      handleOrder("DESC");
                                      handleFilterKey(head.key);
                                    }}
                                  />
                                  <DownIcon
                                    height={10}
                                    width={10}
                                    active={
                                      filterKey === head.key
                                        ? order === "ASC"
                                          ? Theme === "DARK"
                                            ? "white"
                                            : "black"
                                          : Theme === "DARK"
                                          ? "#0f0"
                                          : "#7620e0ff"
                                        : Theme === "DARK"
                                        ? "#0f0"
                                        : "#7620e0ff"
                                    }
                                    onClick={() => {
                                      handleOrder("ASC");
                                      handleFilterKey(head.key);
                                    }}
                                  />
                                </span>
                              )}
                            </span>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>

                  <tbody>
                    {docs.map((list, ind) => {
                      return (
                        <tr key={ind}>
                          <td>
                            <span>
                              ${numFormatter(list.debt)}
                              <i className="zmdi zmdi-info-outline ml-2">
                                <label>
                                  <ul>
                                    {list?.borrowedInfos?.map((item) => {
                                      return (
                                        <li key={item.idx}>
                                          <span>
                                            <span>
                                              {getDecimal(item.amount, 5)}
                                              <span className="ml-1">
                                                {item.name}
                                              </span>
                                            </span>
                                            <img
                                              src={TokenImgRegistry[item.name]}
                                              alt="Loading..."
                                              className="ml-2"
                                            />
                                          </span>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </label>
                              </i>
                            </span>
                          </td>
                          <td>
                            <span>
                              ${numFormatter(list.collateral)}
                              <i className="zmdi zmdi-info-outline ml-2">
                                <label>
                                  <ul>
                                    {list?.collateralInfos?.map((item) => {
                                      return (
                                        <li key={item.idx}>
                                          <span>
                                            <img
                                              src={TokenImgRegistry[item.name]}
                                              alt="Loading..."
                                              className="ml-2"
                                            />
                                            <span className="ml-2">
                                              {getDecimal(item.amount, 5)}
                                              <span className="ml-1">
                                                {item.name}
                                              </span>
                                            </span>
                                          </span>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </label>
                              </i>
                            </span>
                          </td>
                          <td>
                            <span>
                              <Link to={`/?wallet=${list.address}`}>
                                {list.address}
                              </Link>
                            </span>
                          </td>
                          <td>
                            <span>{list.liquidatable_max_ltv}%</span>
                          </td>
                          <td>
                            <span>{calc(list.ltv)}%</span>
                          </td>
                          <td>
                            {!publicKey ? (
                              <WalletButton
                                br="10px"
                                fw="400"
                                active={1}
                                Theme={Theme}
                              />
                            ) : (
                              <Button
                                active={5}
                                className={Loading ? "not-allowed" : null}
                                br="4px"
                                p="0.3rem 0.2rem"
                                Theme={Theme}
                                disabled={Loading ? true : false}
                                onClick={() => {
                                  if (list.ltv >= list.liquidatable_max_ltv) {
                                    if (
                                      list.collateral_type === "mSOL" ||
                                      list.collateral_type === "SOL" ||
                                      list.collateral_type === "BSOL" ||
                                      list.collateral_type === "JITOSOL"
                                    ) {
                                      handle_liquidatable(list.address);
                                    } else if (
                                      list.collateral_type === "USDC"
                                    ) {
                                      setIsLiquidateT2(true);
                                      setCbsAddress(list.address);
                                    }
                                  } else {
                                    return null;
                                  }
                                }}
                              >
                                {list.ltv >= list.liquidatable_max_ltv ? (
                                  <>
                                    {list.collateral_type === "mSOL" ||
                                    list.collateral_type === "SOL"
                                      ? "Liquidate T1"
                                      : "Liquidate T2"}
                                  </>
                                ) : (
                                  "Not Liquidatable"
                                )}
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="col-12 d-flex justify-content-center mt-4">
              <div className="message">
                <p>No Positions in Liquidation Risk</p>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default memo(Table);
