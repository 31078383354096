import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { NavbarRegistry } from "assets/registry";
import HeaderWrapper from "./Header.style";
import { RpcRegistry } from "assets/registry";
import Button from "Layout/Button";
import Image from "Layout/Image";
import WalletButton from "../WalletButton";
import CliModel from "models/CliModel";
import Input from "Layout/Form/Input";

const Header = ({
  handleTheme,
  Theme,
  Cluster,
  changeCluster,
  customCluster,
  setCustomCluster,
  CustomCluster,
  ContractSnackbarType,
  PriceHandler,
  BalanceHandler,
  storePrice,
  storeBal,
  wallet,
  handleCbsInfo,
  handleCbsUserInfo,
  handleTreasuryInfo,
  handle_nlp_user_info,
  handle_nlp_Info,
  handle_stability_fee,
  handle_token_infos,
  CommandMess,
  OpenCommand,
  CommandType,
  closeCommand,
}) => {
  const location = useLocation();
  const [dropdown, setDropdown] = useState(false);
  const [cli, setCli] = useState(false);

  const wrapperRef = useRef(null);

  const openNav = () => {
    document.getElementById("mySidenav").style.width = "250px";
  };

  const closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
  };

  const handleRefreshData = () => {
    storeBal();
  };

  const refCluster = () => {
    storePrice();
    storeBal();
    handleCbsInfo();
    handleCbsUserInfo();
    handleTreasuryInfo();
    handle_nlp_user_info();
    handle_nlp_Info();
    handle_stability_fee();
    handle_token_infos();
  };

  useEffect(() => {
    if (ContractSnackbarType === "Success") {
      handleRefreshData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ContractSnackbarType]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setDropdown(false);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [wrapperRef]);
  return (
    <>
      {cli && (
        <CliModel
          isOpen={cli}
          isClose={() => setCli(false)}
          OpenCommand={OpenCommand}
          CommandMess={CommandMess}
          wallet={wallet}
          PriceHandler={PriceHandler}
          BalanceHandler={BalanceHandler}
          CommandType={CommandType}
          closeCommand={closeCommand}
          Theme={Theme}
        />
      )}
      <HeaderWrapper Theme={Theme}>
        <div id="mySidenav" className="sideNav mb-4">
          <div className="container-fluid">
            <div className="row d-flex align-items-center mt-2">
              <div className="col-8 d-flex justify-content-center">
                <img
                  src={
                    Theme === "DARK" ? "/images/Logo.png" : "/images/xLogo.png"
                  }
                  alt="Loading..."
                  className="small_logo"
                />
              </div>
              <div className="col-4 d-flex justify-content-end">
                <p className="closeBtn mr-2" onClick={closeNav}>
                  <i className="zmdi zmdi-close close_icon" />
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-12 d-flex justify-content-start">
                <ul className="mt-5 ml-3 pl-1">
                  {NavbarRegistry.map((nav) => {
                    return (
                      <li key={nav.id}>
                        {nav.id !== 6 ? (
                          <Link to={nav.href} onClick={closeNav}>
                            {nav.name}
                          </Link>
                        ) : (
                          <a
                            href={nav.href}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {nav.name}
                          </a>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            <div className="Wallet mt-2">
              <WalletButton active={1} br="50px" p="0rem 2rem" Theme={Theme} />
            </div>
          </div>
        </div>

        <div className="container navbar_component">
          <div className="row">
            <div className="col-12">
              <nav className="navbar navbar-light">
                <i className="zmdi zmdi-menu navbar-icon" onClick={openNav} />

                <Link to="/" className="navbar-brand">
                  <img
                    src={
                      Theme === "DARK"
                        ? "/images/Logo.png"
                        : "/images/xLogo.png"
                    }
                    alt="Logo..."
                  />
                </Link>

                <ul className="navbar-nav left_ui_block ml-auto d-flex justify-content-center  align-items-center flex-row">
                  {NavbarRegistry.map((nav) => {
                    return (
                      <li className="nav-item" key={nav.id}>
                        {nav.id !== 6 ? (
                          <Link
                            to={nav.href}
                            className={
                              location.pathname === nav.href
                                ? "nav-link active"
                                : "nav-link"
                            }
                          >
                            {nav.name}
                          </Link>
                        ) : (
                          <a
                            href={nav.href}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={
                              location.pathname === nav.href
                                ? "nav-link active"
                                : "nav-link"
                            }
                          >
                            {nav.name}
                          </a>
                        )}
                      </li>
                    );
                  })}

                  <li className="nav-item">
                    <div className="terminal">
                      <Image
                        src={
                          Theme === "DARK"
                            ? "/images/icons/terminal.png"
                            : "/images/icons/xterminal.png"
                        }
                        alt="terminal"
                        className="mx-3"
                        h="1.5rem"
                        onClick={() => setCli(true)}
                      />
                    </div>
                  </li>

                  <li className="nav-item">
                    <div className="Wallet_section">
                      <WalletButton active={1} br="50px" Theme={Theme} />
                    </div>
                  </li>
                </ul>

                <ul className="navbar-nav setting_section d-flex justify-content-center  align-items-center flex-row">
                  <li className="nav-item">
                    <div className="terminal">
                      <Image
                        src={
                          Theme === "DARK"
                            ? "/images/icons/terminal.png"
                            : "/images/icons/xterminal.png"
                        }
                        alt="terminal"
                        className="mr-3"
                        h="1.5rem"
                        onClick={() => setCli(true)}
                      />
                    </div>
                  </li>
                  <li className="nav-item">
                    <div
                      className={dropdown ? "btn-group show" : "btn-group"}
                      ref={wrapperRef}
                    >
                      <Button
                        type="button"
                        className="dropdown_btn"
                        data-display="static"
                        aria-expanded="false"
                        onClick={() => setDropdown((prev) => !prev)}
                      >
                        <i className="zmdi zmdi-settings setting"></i>
                      </Button>
                      <div
                        className={
                          dropdown
                            ? "dropdown-menu dropdown-menu-right show"
                            : "dropdown-menu dropdown-menu-right"
                        }
                      >
                        <div className="dropdown-item">
                          <div className="title">
                            <p>Settings</p>
                          </div>
                          <div className="title mt-3">
                            <span>Networks -</span>
                          </div>
                          {RpcRegistry.map((list) => {
                            return (
                              <div
                                className="networks_card mt-2"
                                key={list.id}
                                onClick={() => {
                                  changeCluster(list.network);
                                  refCluster();
                                }}
                              >
                                <div className="name">
                                  <p>{list.network}</p>
                                </div>
                                {list.network === Cluster && (
                                  <div className="checked">
                                    <i className="zmdi zmdi-check" />
                                  </div>
                                )}
                              </div>
                            );
                          })}

                          <div className="input_section mt-3">
                            <Input
                              type="text"
                              active={2}
                              p="0.3rem 4rem 0.3rem 0.6rem"
                              br="10px"
                              placeholder="https://"
                              value={CustomCluster}
                              onChange={(e) => setCustomCluster(e.target.value)}
                              autoFocus
                              Theme={Theme}
                            />
                            <Button
                              active={3}
                              p="0rem 0.3rem"
                              br="0px 10px 10px 0px"
                              size="0.8rem"
                              onClick={() => {
                                if (CustomCluster) {
                                  if (/^(http|https)/i.test(CustomCluster)) {
                                    customCluster("Custom");
                                    refCluster();
                                  }
                                }
                              }}
                              Theme={Theme}
                            >
                              ADD
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item ml-lg-4 ml-md-3 ml-3">
                    <div className="theme_section">
                      {Theme === "DARK" ? (
                        <Image
                          src="/images/icons/light.png"
                          alt="terminal"
                          h="1.5rem"
                          onClick={() => handleTheme("LIGHT")}
                        />
                      ) : (
                        <Image
                          src="/images/icons/dark.png"
                          alt="terminal"
                          onClick={() => handleTheme("DARK")}
                        />
                      )}
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </HeaderWrapper>
    </>
  );
};

export default Header;
