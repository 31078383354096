/* eslint-disable no-unused-vars */
import * as anchor from "@project-serum/anchor";
import { getProgram, getATAPublicKey } from "utils/contract";
import {
  SEED_PDA,
  SEED_SOL,
  SEED_TRV_PDA,
  STATE_PUB,
  config,
  mSOL_MINT,
  USDC_MINT,
  LIQ_POOL_SOL_LEG_PDA,
  LIQ_POOL_MSOL_LEG,
  LIQ_POOL_MSOL_LEG_AUTHORITY,
  MARINADE_PROGRAM,
  RESERVE_PDA,
  mSOL_MINT_AUTHORITY,
  switchboardSolAccount,
  getOracleAccount,
  cTokenInfoAccounts,
  getMint,
  getSwitchboardAccount,
} from "constants/global";
import {
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import {
  convert_to_wei_value_with_decimal,
  epoch_stability_fee_instructions,
} from "utils/contract";

const { PublicKey, SystemProgram, SYSVAR_RENT_PUBKEY } = anchor.web3;

export const liquidate_step1 = async (
  address,
  wallet,
  OpenContractSnackbar,
  setLoading,
  collateral_type
) => {
  try {
    OpenContractSnackbar(true, "Processing", `Start liquidate`);
    setLoading(true);

    const program = getProgram(wallet, "lpIdl");

    const liquidator = wallet.publicKey;
    const cbsAddress = new PublicKey(address);

    const xsolMint = getMint(collateral_type);
    const switchboardXsol = getSwitchboardAccount(collateral_type);

    const userAccountPDA = await PublicKey.findProgramAddressSync(
      [Buffer.from(SEED_PDA), Buffer.from(cbsAddress.toBuffer())],
      program.programId
    );

    const PDA = await PublicKey.findProgramAddressSync(
      [Buffer.from(SEED_PDA)],
      program.programId
    );

    const TRVC_PDA = await PublicKey.findProgramAddressSync(
      [Buffer.from(SEED_TRV_PDA)],
      program.programId
    );

    const cbsXsolAta = await getATAPublicKey(xsolMint, PDA[0]);

    const trvcMsolAta = await getATAPublicKey(xsolMint, TRVC_PDA[0]);

    const mintTo = trvcMsolAta;

    const SOL_PDA = await PublicKey.findProgramAddressSync(
      [Buffer.from(SEED_SOL)],
      program.programId
    );

    const [stability_fee, _bump] = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from("stability_fee")],
      program.programId
    );

    const transferFrom = SOL_PDA[0];

    const tx = new anchor.web3.Transaction();

    const txInstructions = await epoch_stability_fee_instructions(
      program,
      liquidator,
      stability_fee
    );
    txInstructions.forEach((txItem) => {
      tx.add(txItem);
    });

    tx.add(
      await program.methods
        .runLiquidateStep1()
        .accounts({
          userAccount: userAccountPDA[0],
          ctokenInfoAccounts: cTokenInfoAccounts,
          trvcAccount: TRVC_PDA[0],
          cbsXsolAta,
          config,
          cbsPda: PDA[0],
          switchboardSol: switchboardSolAccount,
          stabilityFee: stability_fee,
          state: STATE_PUB,
          xsolMint,
          switchboardXsol,
          liqPoolSolLegPda: LIQ_POOL_SOL_LEG_PDA,
          liqPoolMsolLeg: LIQ_POOL_MSOL_LEG,
          liqPoolMsolLegAuthority: LIQ_POOL_MSOL_LEG_AUTHORITY,
          reservePda: RESERVE_PDA,
          transferFrom,
          mintTo,
          msolMintAuthority: mSOL_MINT_AUTHORITY,
          marinadeProgram: MARINADE_PROGRAM,
          systemProgram: SystemProgram.programId,
          tokenProgram: TOKEN_PROGRAM_ID,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          rent: SYSVAR_RENT_PUBKEY,
        })
        .instruction()
    );

    const provider = anchor.getProvider();
    const tnx = await provider.sendAndConfirm(tx);
    setLoading(false);
    if (tnx) {
      OpenContractSnackbar(true, "Success", "Transaction successful", tnx);
    } else {
      OpenContractSnackbar(
        true,
        "Error",
        `Liquidate failed. Please try again.`
      );
    }
  } catch (error) {
    console.log(error);
    setLoading(false);
    OpenContractSnackbar(true, "Error", `Liquidate failed. Please try again.`);
  }
};

export const liquidate_step2 = async (
  address,
  wallet,
  OpenContractSnackbar,
  amount,
  setAmount,
  isClose,
  setMessage
) => {
  try {
    OpenContractSnackbar(true, "Processing", `Start liquidate`);

    const program = getProgram(wallet, "lpIdl");

    const liquidator = wallet.publicKey;
    const cbsAddress = new PublicKey(address);

    const token_dest = USDC_MINT;

    const transferMSOLAmountWei = convert_to_wei_value_with_decimal(amount, 9);

    const userAccountPDA = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from(cbsAddress.toBuffer())],
      program.programId
    );

    const PDA = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA)],
      program.programId
    );

    const TRVC_PDA = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_TRV_PDA)],
      program.programId
    );

    const switchboardCtoken = getOracleAccount(token_dest);
    const userMsolAta = await getATAPublicKey(mSOL_MINT, liquidator);
    const userCtokenAta = await getATAPublicKey(token_dest, liquidator);
    const cbsCtokenAta = await getATAPublicKey(token_dest, PDA[0]);
    const trvcMsolAta = await getATAPublicKey(mSOL_MINT, TRVC_PDA[0]);

    const [stability_fee, _bump] = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from("stability_fee")],
      program.programId
    );
    const tx = new anchor.web3.Transaction();
    const txInstructions = await epoch_stability_fee_instructions(
      program,
      liquidator,
      stability_fee
    );
    txInstructions.forEach((txItem) => {
      tx.add(txItem);
    });

    tx.add(
      await program.methods
        .runLiquidateStep2(transferMSOLAmountWei)
        .accounts({
          liquidator: liquidator,
          userAccount: userAccountPDA[0],
          trvcAccount: TRVC_PDA[0],
          msolMint: mSOL_MINT,
          userMsolAta,
          trvcMsolAta,
          marinadeState: STATE_PUB,
          ctokenMint: token_dest,
          userCtokenAta,
          cbsCtokenAta,
          stabilityFee: stability_fee,
          switchboardCtoken,
          switchboardSol: switchboardSolAccount,
          config,
          cbsPda: PDA[0],
          ctokenInfoAccounts: cTokenInfoAccounts,
          tokenProgram: TOKEN_PROGRAM_ID,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId,
          rent: SYSVAR_RENT_PUBKEY,
        })
        .instruction()
    );

    const provider = anchor.getProvider();
    const tnx = await provider.sendAndConfirm(tx);

    if (tnx) {
      OpenContractSnackbar(true, "Success", "Transaction successful", tnx);
      setAmount("");
      setMessage("Enter an amount");
      isClose();
    } else {
      OpenContractSnackbar(
        true,
        "Error",
        `Liquidate failed. Please try again.`
      );
    }
  } catch (error) {
    console.log(error);
    OpenContractSnackbar(true, "Error", `Liquidate failed. Please try again.`);
  }
};
