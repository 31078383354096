import React, { useEffect, useMemo, useState, memo } from "react";
import { WalletModalProvider } from "lib/WalletAdapter";
import { useCluster } from "contexts/ClusterContext";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import {
  BackpackWalletAdapter,
  GlowWalletAdapter,
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  Coin98WalletAdapter,
  ExodusWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { useTheme } from "contexts/ThemeContext";

require("assets/css/wallet.css");

const WalletWrapper = ({ children }) => {
  const { Cluster } = useCluster();
  const [network, setNetwork] = useState("");
  const { Theme } = useTheme();

  const [endpoint] = useMemo(() => {
    let ClusterEnv;

    if (Cluster === "Quiknode") {
      ClusterEnv = process.env.REACT_APP_QUIKNODE_CLUSTER;
    } else if (Cluster === "Custom") {
      var getCustomRpc = localStorage.getItem("web3.customeRpc");
      ClusterEnv = getCustomRpc;
    }

    return [ClusterEnv];
  }, [Cluster]);

  useEffect(() => {
    if (Cluster === "Quiknode" || Cluster === "Custom") {
      setNetwork(WalletAdapterNetwork.Mainnet);
    } else if (Cluster === "Testnet") {
      setNetwork(WalletAdapterNetwork.Testnet);
    }

    return () => {
      setNetwork("");
    };
  }, [Cluster]);

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new BackpackWalletAdapter(),
      new GlowWalletAdapter(),
      new SolflareWalletAdapter({ network }),
      new SolletWalletAdapter({ network }),
      new SolletExtensionWalletAdapter({ network }),
      new ExodusWalletAdapter(),
      new Coin98WalletAdapter(),
    ],
    [network]
  );

  return (
    <>
      <ConnectionProvider
        endpoint={endpoint ? endpoint : process.env.REACT_APP_QUIKNODE_CLUSTER}
      >
        <WalletProvider wallets={wallets}>
          <WalletModalProvider {...{ Theme }}>{children}</WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </>
  );
};

export default memo(WalletWrapper);
