import styled from "styled-components";

const LiquidateWrapper = styled.div`
  .Liquidate {
    .Liquidate_title {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .filter_sectiion {
      possition: relative;

      .checkbox_filter {
        position: absolute;
        right: 0;

        label {
          color: ${(props) => props.theme.BodyMainColor};
          margin: 0 10px;
        }

        input[type="checkbox"] {
          position: relative;
          accent-color: ${(props) => props.theme.BodyTextColor};
          height: 1rem;
          width: 1rem;
          background: transparent;
          ${(props) =>
            props.Theme === "DARK"
              ? `
                color: black;
            `
              : `
                color: white;
            `}
          cursor: pointer;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          border: none;
          left: 0px;
          top: 0px;
        }

        input[type="checkbox"]:before {
          content: "\f26b";
          font-family: Material-Design-Iconic-Font;
          font-size: 0.8rem;
          background-color: ${(props) => props.theme.lightBgLightShadow};
          box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
          text-rendering: auto;
          border-radius: 3px;
          color: transparent;
          display: inline-block;
          height: 1.2rem;
          width: 1.2rem;
        }

        input[type="checkbox"]:checked:before {
          content: "\f26b";
          font-weight: 600;
          text-align: center;
          background-color: ${(props) => props.theme.lightBgLightShadow};
          color: ${(props) => props.theme.BodyMainColor};
          padding: 0rem 0.2rem 0rem 0.2rem;
        }
      }
    }

    .Liquidate_table_section {
      .table_card {
        background: ${(props) => props.theme.lightBg};
        border-color: ${(props) => props.theme.lightBgDarkShadow};
        box-shadow: ${(props) => props.theme.boxShadowMain};
        border-radius: 18px;
        max-height: auto;
        padding: 0rem 0.5rem;
        overflow-y: scroll;
        overflow-x: scroll;

        &::-webkit-scrollbar {
          height: 7px;
          width: 7px;
          background: inherit;
        }
        &::-webkit-scrollbar-corner {
          background: inherit;
        }

        &::-webkit-scrollbar-track {
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          ${(props) =>
            props.Theme === "DARK"
              ? `
              background: rgba(255, 255, 255, 0.2);
            `
              : `
              background: rgba(0, 0, 0, 0.1);
            `}
          backdrop-filter: blur(5px);
          border-radius: 10px;
          -webkit-border-radius: 10px;
        }

        table {
          width: 100%;
          margin-bottom: 0rem;

          th {
            vertical-align: middle !important;
            border-top: none !important;
          }

          td {
            vertical-align: middle !important;
          }

          thead {
            tr {
              th {
                border-bottom: 3px solid ${(props) => props.theme.BodyPrimaryBg} !important;
                padding: 1.5rem 1.5rem;
                color: ${(props) => props.theme.BodyTraceryBg};
                font-size: 0.95rem;

                .icons-group {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  column-gap: 10px;

                  span {
                    display: flex;
                    flex-direction: column;
                    cursor: pointer;
                  }
                }
              }
            }
          }
          tbody {
            border-radius: 10px;
            tr {
              td {
                padding: 1rem 1.5rem;
                font-size: 0.98rem;
                border-bottom: 1px solid ${(props) => props.theme.BodyPrimaryBg};

                span {
                  color: ${(props) => props.theme.BodyMainColor};
                  font-size: 0.9rem;
                  display: flex;
                  align-items: center;

                  a {
                    color: ${(props) => props.theme.BodyMainColor};
                  }

                  i {
                    cursor: pointer;
                    font-size: 1.1rem;
                    position: relative;
                    display: inline-block;
                  }

                  i label {
                    visibility: hidden;
                    min-width: auto;
                    background: ${(props) => props.theme.lightBg};
                    border-color: ${(props) => props.theme.lightBgDarkShadow};
                    box-shadow: ${(props) => props.theme.boxShadowMain};
                    border-radius: 5px;
                    padding: 0.2rem 0.5rem;
                    position: absolute;
                    z-index: 1;
                    top: -8px;
                    font-family: "SF Mono Medium";
                    left: 150%;
                    opacity: 0;
                    transition: opacity 0.5s;

                    ul {
                      list-style-type: none;
                      text-align: center;

                      li {
                        display: flex;
                        align-items: center;
                        margin: 10px 0;

                        img {
                          widht: auto;
                          height: 1.7rem;
                        }
                      }
                    }
                  }

                  i label::after {
                    content: "";
                    position: absolute;
                    top: 13px;
                    right: 100%;
                    border-width: 5px;
                    border-style: solid;
                    border-color: transparent
                      ${(props) => props.theme.BodySecondaryColor} transparent
                      transparent;
                  }
                  i:hover label {
                    visibility: visible;
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
      .message {
        p {
          color: ${(props) => props.theme.BodyMainColor};
        }
      }
    }

    .pagination {
      display: flex;
      justify-content: center;

      ul {
        display: flex;
        background: ${(props) => props.theme.lightBg};
        box-shadow: ${(props) => props.theme.boxShadowMain};
        padding: 8px;
        border-radius: 50px;

        li {
          list-style: none;
          line-height: 20px;
          display: flex;
          align-items: center;
          justify-content: Center;
          font-weight: 500;
          cursor: pointer;
          margin: 0 2px;

          span {
            color: ${(props) => props.theme.BodyMainColor};
          }

          a {
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            border-radius: 50%;
            height: 35px;
            width: 35px;
            transition: all 0.3s;
            color: ${(props) => props.theme.BodyTraceryBg};
          }
        }

        .dots {
          height: 20px;
          width: 20px;
          cursor: default;
        }

        .prev,
        .next {
          height: 35px;
          width: 100px;
          i {
            margin-left: 6px;
            color: ${(props) => props.theme.BodyMainColor};
          }
        }

        .prev {
          border-radius: 25px 5px 5px 25px;
          i {
            margin-right: 6px;
          }
        }

        .next {
          border-radius: 5px 25px 25px 5px;
        }

        .active {
          border-radius: 50%;
          border: 1px solid ${(props) => props.theme.BodyTraceryBg};
          background: ${(props) => props.theme.lightBg};
        }

        li a:hover,
        .btn:hover {
          background: ${(props) => props.theme.lightBg};
          color: ${(props) => props.theme.BodyTraceryBg};
          border: 1px solid ${(props) => props.theme.BodyTraceryBg};

          span {
            color: ${(props) => props.theme.BodyTraceryBg};
          }
        }
      }
    }
  }

  @media only screen and (max-width: 769px) {
    .Liquidate {
      .filter_sectiion {
        .checkbox_filter {
          position: inherit;
        }
      }
    }
  }
`;

export default LiquidateWrapper;
