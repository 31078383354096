import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Borrow from "components/Borrow";
import Liquidate from "components/Liquidate";
import Treasury from "components/Treasury";
import PSM from "components/PSM";
import LPIncentives from "components/LPIncentives";
import Layout from "components/globalComponents/Layout";
import WalletWrapper from "lib/WalletWrapper";
import Error from "components/globalComponents/Error";
import ScreenLoader from "components/globalComponents/Loaders/ScreenLoader";
import { CryptoProvider } from "contexts/CryptoContext";
import MainModel from "models/MainModel";
import { GlobalProvider } from "contexts/GlobalContext";
import { useTheme } from "contexts/ThemeContext";
import { QwestiveSDKProvider } from "contexts/IQwestiveContext";

const App = () => {
  const { Theme } = useTheme();
  const [Loading, setLoading] = useState(true);
  const [model, setModel] = useState(false);

  useEffect(() => {
    setLoading(true);
    let LoadingTimeOut = setTimeout(() => {
      setLoading(false);
      // setModel(true);
    }, 1500);

    return () => {
      clearTimeout(LoadingTimeOut);
    };
  }, []);

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    var img = `${Theme === "DARK" ? "/favicon.ico" : "/images/xLPFi.png"}`;
    link.href = img;
  }, [Theme]);

  if (Loading) {
    return <ScreenLoader Theme={Theme} />;
  }

  return (
    <>
      <WalletWrapper>
        <CryptoProvider>
          <GlobalProvider>
            <QwestiveSDKProvider>
              <Layout>
                <Routes>
                  <Route exact path="/" element={<Borrow />} />
                  <Route exact path="/treasury" element={<Treasury />} />
                  <Route exact path="/psm" element={<PSM />} />
                  <Route
                    exact
                    path="/lp-incentives"
                    element={<LPIncentives />}
                  />
                  <Route exact path="/liquidate" element={<Liquidate />} />
                  <Route exact path="*" element={<Error />} />
                </Routes>
              </Layout>
            </QwestiveSDKProvider>
          </GlobalProvider>
        </CryptoProvider>
      </WalletWrapper>
      {model ? (
        <MainModel
          isOpen={model}
          isClose={() => setModel(false)}
          Theme={Theme}
        />
      ) : null}
    </>
  );
};

export default App;
