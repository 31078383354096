import React, { useContext, createContext, useState, useEffect } from "react";

export const IQwestiveContext = createContext();

const useQwestiveSDK = () => {
  const [isScriptLoading, setisScriptLoading] = useState(true);
  const [qwestiveMethods, setQwestiveMethods] = useState({
    isLoading: true,
  });

  useEffect(() => {
    window?.QwestiveTracker?.loadQwestiveTracker(() => {
      setisScriptLoading(false);
    });
  }, []);

  useEffect(() => {
    if (isScriptLoading) return;
    const loadMethods = () => {
      try {
        const apiMethods = window?.QwestiveTracker?.init({
          apiKey: process.env.REACT_APP_QWESTIVE_API_KEY,
          projectId: process.env.REACT_APP_QWESTIVE_PROJECT_ID,
        });

        setQwestiveMethods({ ...apiMethods, isLoading: false });
      } catch (e) {
        console.error("Error occurred while loading API methods");
        setQwestiveMethods({ isLoading: false });
      }
    };

    loadMethods();
  }, [isScriptLoading]);

  return {
    isLoading: qwestiveMethods?.isLoading,
    setAlias: ({ id }) => {
      if (!qwestiveMethods?.setAlias) return;
      qwestiveMethods?.setAlias({ id });
    },
    setReferral: ({ id }) => {
      if (!qwestiveMethods?.setReferral) return;
      qwestiveMethods?.setReferral({ id });
    },
  };
};

export const QwestiveSDKProvider = ({ children }) => {
  const scriptContext = useQwestiveSDK();

  return (
    <IQwestiveContext.Provider value={scriptContext}>
      {children}
    </IQwestiveContext.Provider>
  );
};

export const useQwestive = () => useContext(IQwestiveContext);
