import * as anchor from "@project-serum/anchor";
import {
  getProgram,
  getATAPublicKey,
  getConnection,
  convert_to_wei_value_with_decimal,
  convert_from_wei_value_with_decimal,
  getPendingRewardAmount,
  lp_epoch_stability_fee_instructions,
} from "utils/contract";
import {
  getMint,
  zSOL_MINT,
  nLP_MINT,
  NLP_SEED_PDA,
  SEED_PDA,
} from "constants/global";
import {
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import { addPriorityFee } from "./priorityfee";

const { PublicKey, SystemProgram, SYSVAR_RENT_PUBKEY } = anchor.web3;

export const nlp_deposit = async (
  wallet,
  amount,
  setMessage,
  setRequired,
  setAmount,
  OpenContractSnackbar
) => {
  try {
    OpenContractSnackbar(true, "Processing", "Start deposit");

    const connection = getConnection();

    const program = getProgram(wallet, "lpIn_Idl");

    const lpfinanceProgram = getProgram(wallet, "lpIdl");

    const user_wallet = wallet.publicKey;

    const nlp_mint = getMint("nlp");

    const stakerAccountPDA = await PublicKey.findProgramAddress(
      [Buffer.from(NLP_SEED_PDA), Buffer.from(user_wallet.toBuffer())],
      program.programId
    );

    const PDA = await PublicKey.findProgramAddress(
      [Buffer.from(NLP_SEED_PDA)],
      program.programId
    );

    // eslint-disable-next-line no-unused-vars
    const [config, _bump] = await PublicKey.findProgramAddress(
      [Buffer.from(NLP_SEED_PDA), Buffer.from(nlp_mint.toBuffer())],
      program.programId
    );

    const tx = new anchor.web3.Transaction();

    const stakerAccountInfo = await connection.getAccountInfo(
      stakerAccountPDA[0]
    );
    if (stakerAccountInfo === null || stakerAccountInfo.data.length === 0) {
      OpenContractSnackbar(true, "Processing", `Stake account creation.`);

      tx.add(
        await program.methods
          .createStakerAccount()
          .accounts({
            stakerAccount: stakerAccountPDA[0],
            config: config,
            userAuthority: user_wallet,
            systemProgram: SystemProgram.programId,
            rent: SYSVAR_RENT_PUBKEY,
          })
          .instruction()
      );
    }

    // eslint-disable-next-line no-unused-vars
    const [stability_fee, _sbump] = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from("stability_fee")],
      lpfinanceProgram.programId
    );

    const txInstructions = await lp_epoch_stability_fee_instructions(
      program,
      lpfinanceProgram,
      config,
      user_wallet,
      stability_fee
    );
    txInstructions.forEach((txItem) => {
      tx.add(txItem);
    });

    if (stakerAccountInfo && stakerAccountInfo.data.length > 0) {
      const stakerAccountData = await program.account.stakerAccount.fetch(
        stakerAccountPDA[0]
      );
      const stakerNLP = stakerAccountData.nlpMint;

      if (stakerNLP.equals(nlp_mint) === false) {
        OpenContractSnackbar(
          true,
          "Processing",
          `"You are using old nlp staking. Please withdraw old nlp and renew with new nlp staking.`
        );

        // eslint-disable-next-line no-unused-vars
        const [oldConfig, _bump] = await PublicKey.findProgramAddress(
          [Buffer.from(NLP_SEED_PDA), Buffer.from(stakerNLP.toBuffer())],
          program.programId
        );

        const nlp_mint = stakerNLP;
        const userAta = await getATAPublicKey(nlp_mint, user_wallet);
        const poolAta = await getATAPublicKey(nlp_mint, PDA[0]);
        tx.add(addPriorityFee);
        tx.add(
          await program.methods
            .withdrawOldNlp()
            .accounts({
              userAuthority: user_wallet,
              stakerAccount: stakerAccountPDA[0],
              config: oldConfig,
              nlpMint: nlp_mint,
              userNlpAta: userAta,
              poolNlpAta: poolAta,
              poolAuthority: PDA[0],
              systemProgram: SystemProgram.programId,
              tokenProgram: TOKEN_PROGRAM_ID,
              associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
              rent: SYSVAR_RENT_PUBKEY,
            })
            .instruction()
        );
      }
    }

    const userNlpAta = await getATAPublicKey(nlp_mint, user_wallet);
    const poolNlpAta = await getATAPublicKey(nlp_mint, PDA[0]);
    const nlpconfigData = await program.account.config.fetch(config);
    const feeAta = await getATAPublicKey(nlp_mint, nlpconfigData.feeAccount);
    tx.add(addPriorityFee);
    tx.add(
      await program.methods
        .stakeNlp(convert_to_wei_value_with_decimal(amount, 9))
        .accounts({
          stakerAccount: stakerAccountPDA[0],
          config,
          userAuthority: user_wallet,
          userNlpAta,
          feeAta,
          poolNlpAta,
          tokenProgram: TOKEN_PROGRAM_ID,
        })
        .instruction()
    );

    const provider = anchor.getProvider();
    const conTx = await provider.sendAndConfirm(tx);

    if (conTx) {
      OpenContractSnackbar(true, "Success", "Transaction successful", conTx);
      setMessage("Enter an amount");
      setRequired(false);
      setAmount("");
    } else {
      OpenContractSnackbar(true, "Error", `Deposit failed. Please try again.`);
    }
  } catch (error) {
    console.log(error);
    OpenContractSnackbar(true, "Error", `Deposit failed. Please try again.`);
  }
};

export const nlp_withdraw = async (
  wallet,
  amount,
  setMessage,
  setRequired,
  setAmount,
  OpenContractSnackbar
) => {
  try {
    OpenContractSnackbar(true, "Processing", "Start withdraw");

    const connection = getConnection(wallet);

    const program = getProgram(wallet, "lpIn_Idl");

    const lpfinanceProgram = getProgram(wallet, "lpIdl");

    const user_wallet = wallet.publicKey;

    const nlp_mint = getMint("nlp");

    // eslint-disable-next-line no-unused-vars
    const [config, _bump] = await PublicKey.findProgramAddress(
      [Buffer.from(NLP_SEED_PDA), Buffer.from(nlp_mint.toBuffer())],
      program.programId
    );

    const stakerAccountPDA = await PublicKey.findProgramAddress(
      [Buffer.from(NLP_SEED_PDA), Buffer.from(user_wallet.toBuffer())],
      program.programId
    );

    const PDA = await PublicKey.findProgramAddress(
      [Buffer.from(NLP_SEED_PDA)],
      program.programId
    );

    const stakerAccountInfo = await connection.getAccountInfo(
      stakerAccountPDA[0]
    );

    const tx = new anchor.web3.Transaction();

    if (stakerAccountInfo === null || stakerAccountInfo.data.length === 0) {
      OpenContractSnackbar(true, "Info", "Your account does not exist.");
      return;
    }

    // eslint-disable-next-line no-unused-vars
    const [stability_fee, _sbump] = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from("stability_fee")],
      lpfinanceProgram.programId
    );

    const txInstructions = await lp_epoch_stability_fee_instructions(
      program,
      lpfinanceProgram,
      config,
      user_wallet,
      stability_fee
    );
    txInstructions.forEach((txItem) => {
      tx.add(txItem);
    });

    if (stakerAccountInfo && stakerAccountInfo.data.length > 0) {
      const stakerAccountData = await program.account.stakerAccount.fetch(
        stakerAccountPDA[0]
      );

      const stakerNLP = stakerAccountData.nlpMint;

      if (stakerNLP.equals(nLP_MINT) === false) {
        OpenContractSnackbar(
          true,
          "Processing",
          `"You are using old nlp staking. Please withdraw old nlp and renew with new nlp staking.`
        );

        // eslint-disable-next-line no-unused-vars
        const [oldConfig, _bump] = await PublicKey.findProgramAddress(
          [Buffer.from(NLP_SEED_PDA), Buffer.from(stakerNLP.toBuffer())],
          program.programId
        );

        const nlp_mint = stakerNLP;
        const userAta = await getATAPublicKey(nlp_mint, user_wallet);
        const poolAta = await getATAPublicKey(nlp_mint, PDA[0]);
        tx.add(addPriorityFee);
        // withdraw old nlp
        tx.add(
          await program.methods
            .withdrawOldNlp()
            .accounts({
              userAuthority: user_wallet,
              stakerAccount: stakerAccountPDA[0],
              config: oldConfig,
              nlpMint: nlp_mint,
              userNlpAta: userAta,
              poolNlpAta: poolAta,
              poolAuthority: PDA[0],
              systemProgram: SystemProgram.programId,
              tokenProgram: TOKEN_PROGRAM_ID,
              associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
              rent: SYSVAR_RENT_PUBKEY,
            })
            .instruction()
        );
      }
    }

    const userNlpAta = await getATAPublicKey(nlp_mint, user_wallet);
    const poolNlpAta = await getATAPublicKey(nlp_mint, PDA[0]);
    tx.add(addPriorityFee);
    tx.add(
      await program.methods
        .unstakeNlp(convert_to_wei_value_with_decimal(amount, 9))
        .accounts({
          userAuthority: user_wallet,
          stakerAccount: stakerAccountPDA[0],
          config,
          nlpMint: nlp_mint,
          userNlpAta,
          poolNlpAta,
          poolAuthority: PDA[0],
          systemProgram: SystemProgram.programId,
          tokenProgram: TOKEN_PROGRAM_ID,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          rent: SYSVAR_RENT_PUBKEY,
        })
        .instruction()
    );

    const provider = anchor.getProvider();
    const conTx = await provider.sendAndConfirm(tx);

    if (conTx) {
      OpenContractSnackbar(true, "Success", "Transaction successful", conTx);
      setMessage("Enter an amount");
      setRequired(false);
      setAmount("");
    } else {
      OpenContractSnackbar(true, "Error", `Withdraw failed. Please try again.`);
    }
  } catch (error) {
    console.log(error);
    OpenContractSnackbar(true, "Error", `Withdraw failed. Please try again.`);
  }
};

export const withdraw_reward = async (wallet, OpenContractSnackbar) => {
  try {
    OpenContractSnackbar(true, "Processing", "Start Claim");

    const connection = getConnection(wallet);

    const program = getProgram(wallet, "lpIn_Idl");
    const lpfinanceProgram = getProgram(wallet, "lpIdl");

    const user_wallet = wallet.publicKey;

    // eslint-disable-next-line no-unused-vars
    const [config, _bump] = await PublicKey.findProgramAddress(
      [Buffer.from(NLP_SEED_PDA), Buffer.from(nLP_MINT.toBuffer())],
      program.programId
    );

    const stakerAccountPDA = await PublicKey.findProgramAddress(
      [Buffer.from(NLP_SEED_PDA), Buffer.from(user_wallet.toBuffer())],
      program.programId
    );

    const PDA = await PublicKey.findProgramAddress(
      [Buffer.from(NLP_SEED_PDA)],
      program.programId
    );

    const userZsolAta = await getATAPublicKey(zSOL_MINT, user_wallet);
    const poolZsolAta = await getATAPublicKey(zSOL_MINT, PDA[0]);

    const stakerAccountInfo = await connection.getAccountInfo(
      stakerAccountPDA[0]
    );

    if (stakerAccountInfo === null || stakerAccountInfo.data.length === 0) {
      OpenContractSnackbar(true, "Error", `Your account does not exist.`);
      return;
    }

    const stakerAccountData = await program.account.stakerAccount.fetch(
      stakerAccountPDA[0]
    );

    const nlpconfigData = await program.account.config.fetch(config);
    const configZsolRewardRate = nlpconfigData.zsolRewardRate;
    const stakerZsolRewardRate = stakerAccountData.zsolRewardRate;

    const stakedAmount = convert_from_wei_value_with_decimal(
      stakerAccountData.stakedAmount,
      9
    );
    const stakerZsolRewardAmount = convert_from_wei_value_with_decimal(
      stakerAccountData.zsolRewardAmount,
      9
    );

    const pendingRewardAmount = getPendingRewardAmount(
      configZsolRewardRate,
      stakerZsolRewardRate,
      stakedAmount
    );

    if (stakerZsolRewardAmount + pendingRewardAmount <= 0) {
      OpenContractSnackbar(true, "Info", `There is no reward amount.`);
      return;
    }

    // eslint-disable-next-line no-unused-vars
    const [stability_fee, _sbump] = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from("stability_fee")],
      lpfinanceProgram.programId
    );

    const tx = new anchor.web3.Transaction();

    const txInstructions = await lp_epoch_stability_fee_instructions(
      program,
      lpfinanceProgram,
      config,
      user_wallet,
      stability_fee
    );
    txInstructions.forEach((txItem) => {
      tx.add(txItem);
    });
    tx.add(addPriorityFee);
    tx.add(
      await program.methods
        .withdrawReward()
        .accounts({
          userAuthority: user_wallet,
          stakerAccount: stakerAccountPDA[0],
          zsolMint: zSOL_MINT,
          config,
          userZsolAta,
          poolZsolAta,
          poolAuthority: PDA[0],
          systemProgram: SystemProgram.programId,
          tokenProgram: TOKEN_PROGRAM_ID,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          rent: SYSVAR_RENT_PUBKEY,
        })
        .instruction()
    );

    const provider = anchor.getProvider();
    const tnx = await provider.sendAndConfirm(tx);

    if (tnx) {
      OpenContractSnackbar(true, "Success", "Transaction successful", tnx);
    } else {
      OpenContractSnackbar(true, "Error", `Claim failed. Please try again.`);
    }
  } catch (error) {
    console.log(error);
    OpenContractSnackbar(true, "Error", `Claim failed. Please try again.`);
  }
};
