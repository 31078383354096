import React, { useState, useEffect, useCallback } from "react";
import LiquidateWrapper from "styles/Liquidate.style";
import Table from "./Table";
import Input from "Layout/Form/Input";
import { useGlobal } from "contexts/GlobalContext";
import ReactPaginate from "react-paginate";
import { useContractSnackbar } from "contexts/ContractSnackbarContext";
import LiquidateT2Model from "models/LiquidateT2Model";
import { useCrypto } from "contexts/CryptoContext";
import { useTheme } from "contexts/ThemeContext";

const Liquidate = () => {
  const { Theme } = useTheme();
  const { OpenContractSnackbar } = useContractSnackbar();
  const { BalanceHandler, wallet, publicKey, cbsUserInfo } = useCrypto();
  const { handle_liquidate, liquidate_info } = useGlobal();
  const [liquidatePageNum, setLiquidatePageNum] = useState(1);
  const [cbsAddress, setCbsAddress] = useState("");
  const [liquidateSearch, setliquidateSearch] = useState("");
  const [listPerPage] = useState(6);
  const [order, setOrder] = useState("DESC");
  const [filterKey, setFilterKey] = useState("ltv");
  const [loading, setLoading] = useState(false);
  const [isLiquidateT2, setIsLiquidateT2] = useState(false);

  const selectOnlyThis = (value, id) => {
    const Input = document.getElementById(id);
    if (Input.checked) {
      Input.checked = true;
    } else {
      Input.checked = false;
    }
    var myCheckbox = document.getElementsByName("myCheckbox");
    Array.prototype.forEach.call(myCheckbox, function (el) {
      if (el.id !== id) {
        el.checked = false;
      }
    });
    if (Input.checked) {
      setliquidateSearch(value);
    } else {
      setliquidateSearch("");
    }
  };

  const handleOrder = useCallback((value) => {
    setOrder(value);
  }, []);

  const handleFilterKey = useCallback((key) => {
    setFilterKey(key);
  }, []);

  useEffect(() => {
    let Interval = setInterval(async () => {
      handle_liquidate(
        liquidatePageNum,
        listPerPage,
        liquidateSearch,
        order,
        filterKey,
        setLoading
      );
    }, 60000 * 30);

    return () => {
      clearInterval(Interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const createDebouncing = setTimeout(() => {
      handle_liquidate(
        liquidatePageNum,
        listPerPage,
        liquidateSearch,
        order,
        filterKey,
        setLoading
      );
    }, 300);
    return () => clearTimeout(createDebouncing);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liquidatePageNum, listPerPage, liquidateSearch, order, filterKey]);

  return (
    <>
      <LiquidateWrapper Theme={Theme}>
        <div className="container Liquidate">
          <div className="row mt-4">
            <div className="col-12 d-flex justify-content-center">
              <div className="Liquidate_title text-center">
                <h1>Liquidate</h1>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12 filter_sectiion">
              <div className="row d-flex justify-content-center">
                <div className="col-lg-6 col-md-8 col-12">
                  <div className="text-center">
                    <Input
                      name="search"
                      placeholder="Search address"
                      active={2}
                      p="0.5rem 1rem"
                      value={liquidateSearch}
                      onChange={(e) => {
                        document.getElementById("t1").checked = false;
                        document.getElementById("t2").checked = false;
                        setliquidateSearch(e.target.value);
                      }}
                      autoComplete="off"
                      br="8px"
                      Theme={Theme}
                    />
                  </div>
                </div>
                <div className="checkbox_filter mr-lg-5 mr-md-3 mr-0 pt-lg-1 mt-md-1 mt-3">
                  <input
                    type="checkbox"
                    name="myCheckbox"
                    id="t1"
                    value="sol"
                    onChange={(e) => selectOnlyThis(e.target.value, "t1")}
                  />
                  <label htmlFor="T1">T1</label>
                  <input
                    type="checkbox"
                    name="myCheckbox"
                    id="t2"
                    value="usdc"
                    onChange={(e) => selectOnlyThis(e.target.value, "t2")}
                  />
                  <label htmlFor="T2">T2</label>
                </div>
              </div>
            </div>
          </div>
          <Table
            {...{
              docs: liquidate_info.docs,
              loading,
              publicKey,
              wallet,
              OpenContractSnackbar,
              setIsLiquidateT2,
              setCbsAddress,
              Theme,
              collateral_type: cbsUserInfo?.collateral_type,
              handleOrder,
              handleFilterKey,
              order,
              filterKey,
            }}
          />
          {liquidate_info?.docs.length > 0 && (
            <div className="row pagination my-3">
              <div className="col-12 d-flex justify-content-center">
                <ReactPaginate
                  previousLabel={
                    <div className="btn prev">
                      <i className="zmdi zmdi-chevron-left" />
                      <span>Prev</span>
                    </div>
                  }
                  nextLabel={
                    <div className="btn next">
                      <span>Next</span>
                      <i className="zmdi zmdi-chevron-right" />
                    </div>
                  }
                  pageCount={liquidate_info.totalPages}
                  breakLabel={"..."}
                  pageRangeDisplayed={liquidatePageNum}
                  marginPagesDisplayed={1}
                  onPageChange={(e) => setLiquidatePageNum(e.selected + 1)}
                  containerClassName={"btn"}
                  previousLinkClassName={"prev"}
                  nextLinkClassName={"next"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          )}
        </div>
      </LiquidateWrapper>
      {isLiquidateT2 && (
        <LiquidateT2Model
          {...{
            OpenContractSnackbar,
            wallet,
            address: cbsAddress,
            BalanceHandler,
          }}
          isOpen={isLiquidateT2}
          isClose={() => setIsLiquidateT2(false)}
          Theme={Theme}
        />
      )}
    </>
  );
};

export default Liquidate;
