/* eslint-disable no-unused-vars */
import * as anchor from "@project-serum/anchor";
import {
  getProgram,
  getATAPublicKey,
  getConnection,
  convert_to_wei_value,
  convert_to_wei_value_with_decimal,
  epoch_stability_fee_instructions,
} from "utils/contract";
import {
  SEED_PDA,
  SEED_SOL,
  SEED_TRV_PDA,
  zSOL_MINT,
  STATE_PUB,
  SEED_ZSOL_MINT_AUTHORITY_PDA,
  config,
  switchboardSolAccount,
  switchboardZsolAccount,
  switchboardUsdcAccount,
  cTokenInfoAccounts,
  getMint,
  getSwitchboardAccount,
  zSOL_DECIMAL,
} from "constants/global";
import {
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import { addPriorityFee } from "./priorityfee";

const { PublicKey, SystemProgram, SYSVAR_RENT_PUBKEY } = anchor.web3;

// deposit function for csb
// ==============================================
export const deposit_cbs = async (
  wallet,
  symbol,
  amount,
  setMessage,
  setRequired,
  setAmount,
  OpenContractSnackbar
) => {
  try {
    OpenContractSnackbar(true, "Processing", `Deposit ${amount} ${symbol}`);

    const connection = getConnection();

    const program = getProgram(wallet, "lpIdl");

    const user_wallet = wallet.publicKey;

    const tokenMint = getMint(symbol);

    const switchboardAccount = getSwitchboardAccount(symbol);

    const configData = await program.account.config.fetch(config);

    const feeAccount = configData.feeAccount;

    const userAccountPDA = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from(user_wallet.toBuffer())],
      program.programId
    );

    let PDA;

    if (symbol === "SOL") {
      PDA = await PublicKey.findProgramAddress(
        [Buffer.from(SEED_SOL)],
        program.programId
      );
    } else {
      PDA = await PublicKey.findProgramAddress(
        [Buffer.from(SEED_PDA)],
        program.programId
      );
    }

    let userAta;
    let feeAta;
    let cbsAta;
    if (symbol !== "SOL") {
      userAta = await getATAPublicKey(tokenMint, user_wallet);
      feeAta = await getATAPublicKey(tokenMint, feeAccount);
      cbsAta = await getATAPublicKey(tokenMint, PDA[0]);
    }

    const userAccountInfo = await connection.getAccountInfo(userAccountPDA[0]);
    const tx = new anchor.web3.Transaction();

    const [stability_fee, _bump] = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from("stability_fee")],
      program.programId
    );

    if (userAccountInfo === null || userAccountInfo.data.length === 0) {
      tx.add(addPriorityFee);
      tx.add(
        await program.methods
          .initUserAccount()
          .accounts({
            userAccount: userAccountPDA[0],
            userAuthority: user_wallet,
            feeAccount: feeAccount,
            stabilityFee: stability_fee,
            config,
            systemProgram: SystemProgram.programId,
            rent: SYSVAR_RENT_PUBKEY,
          })
          .instruction()
      );
    }

    const txInstructions = await epoch_stability_fee_instructions(
      program,
      user_wallet,
      stability_fee
    );

    txInstructions.forEach((txItem) => {
      tx.add(txItem);
    });

    if (symbol !== "SOL" && symbol !== "mSOL") {
      tx.add(addPriorityFee);
      tx.add(
        await program.methods
          .deposit(convert_to_wei_value(tokenMint, amount))
          .accounts({
            tokenInstr: {
              token: tokenMint,
              cbsAta,
              userAta,
              feeAta,
            },
            commonDeposit: {
              userAuthority: user_wallet,
              userAccount: userAccountPDA[0],
              config,
              switchboardAcc: switchboardAccount,
              stabilityFee: stability_fee,
              ctokenInfoAccounts: cTokenInfoAccounts,
              systemProgram: SystemProgram.programId,
              tokenProgram: TOKEN_PROGRAM_ID,
              rent: SYSVAR_RENT_PUBKEY,
            },
          })
          .instruction()
      );
    } else if (symbol === "SOL") {
      tx.add(addPriorityFee);
      tx.add(
        await program.methods
          .depositSol(convert_to_wei_value_with_decimal(amount, 9))
          .accounts({
            commonDeposit: {
              userAuthority: user_wallet,
              config,
              userAccount: userAccountPDA[0],
              switchboardAcc: switchboardAccount,
              stabilityFee: stability_fee,
              ctokenInfoAccounts: cTokenInfoAccounts,
              systemProgram: SystemProgram.programId,
              tokenProgram: TOKEN_PROGRAM_ID,
              rent: SYSVAR_RENT_PUBKEY,
            },
            solAccount: PDA[0],
            feeAccount,
          })
          .instruction()
      );
    } else if (symbol === "mSOL") {
      tx.add(addPriorityFee);
      tx.add(
        await program.methods
          .depositMsol(convert_to_wei_value(tokenMint, amount))
          .accounts({
            tokenInstr: {
              token: tokenMint,
              cbsAta,
              userAta,
              feeAta,
            },
            commonDeposit: {
              userAuthority: user_wallet,
              userAccount: userAccountPDA[0],
              config,
              switchboardAcc: switchboardAccount,
              stabilityFee: stability_fee,
              ctokenInfoAccounts: cTokenInfoAccounts,
              systemProgram: SystemProgram.programId,
              tokenProgram: TOKEN_PROGRAM_ID,
              rent: SYSVAR_RENT_PUBKEY,
            },
            marinadeState: STATE_PUB,
          })
          .instruction()
      );
    }

    const provider = anchor.getProvider();
    const conTx = await provider.sendAndConfirm(tx);

    if (conTx) {
      OpenContractSnackbar(true, "Success", "Transaction successful", conTx);

      setMessage("Enter an amount");
      setRequired(false);
      setAmount("");
    } else {
      OpenContractSnackbar(true, "Error", `Deposit failed. Please try again.`);
    }
  } catch (error) {
    console.log(error);
    OpenContractSnackbar(true, "Error", `Deposit failed. Please try again.`);
  }
};

// borrow function for csb
// ==============================================
export const borrow_cbs = async (
  wallet,
  symbol,
  amount,
  setMessage,
  setRequired,
  setAmount,
  OpenContractSnackbar,
  is_max,
  collateral_type
) => {
  try {
    OpenContractSnackbar(true, "Processing", `Borrow ${amount} ${symbol}`);

    const program = getProgram(wallet, "lpIdl");

    const user_wallet = wallet.publicKey;

    const tokenMint = getMint(symbol);
    const cTokenMint = getMint(collateral_type);
    const switchboardAccount = getSwitchboardAccount(collateral_type);

    const userAccountPDA = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from(user_wallet.toBuffer())],
      program.programId
    );

    const zsolMintAuthorityPda = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_ZSOL_MINT_AUTHORITY_PDA)],
      program.programId
    );

    const userZsolAta = await getATAPublicKey(tokenMint, user_wallet);

    const [stability_fee, _bump] = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from("stability_fee")],
      program.programId
    );

    const tx = new anchor.web3.Transaction();

    const txInstructions = await epoch_stability_fee_instructions(
      program,
      user_wallet,
      stability_fee
    );
    txInstructions.forEach((txItem) => {
      tx.add(txItem);
    });
    tx.add(addPriorityFee);
    tx.add(
      await program.methods
        .borrow(convert_to_wei_value_with_decimal(amount, zSOL_DECIMAL), is_max)
        .accounts({
          userAuthority: user_wallet,
          userAccount: userAccountPDA[0],
          zsolMintAuthorityPda: zsolMintAuthorityPda[0],
          config,
          stabilityFee: stability_fee,
          zsolMint: tokenMint,
          userZsolAta,
          switchboardZsol: switchboardZsolAccount,
          switchboardSol: switchboardSolAccount,
          marinadeState: STATE_PUB,
          switchboardCollateral: switchboardAccount,
          collateralMint: cTokenMint,
          ctokenInfoAccounts: cTokenInfoAccounts,
          systemProgram: SystemProgram.programId,
          tokenProgram: TOKEN_PROGRAM_ID,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          rent: SYSVAR_RENT_PUBKEY,
        })
        .instruction()
    );

    const provider = anchor.getProvider();
    const conTx = await provider.sendAndConfirm(tx);

    OpenContractSnackbar(true, "Success", "Transaction successful", conTx);
    setMessage("Enter an amount");
    setRequired(false);
    setAmount("");
  } catch (error) {
    console.log(error);
    OpenContractSnackbar(true, "Error", `Borrow failed. Please try again.`);
  }
};

// withdraw function for csb
// ==============================================
export const withdraw_cbs = async (
  wallet,
  symbol,
  amount,
  setMessage,
  setRequired,
  setAmount,
  OpenContractSnackbar,
  is_max
) => {
  try {
    OpenContractSnackbar(true, "Processing", `Withdraw ${amount} ${symbol}`);

    const program = getProgram(wallet, "lpIdl");

    const user_wallet = wallet.publicKey;

    const tokenMint = getMint(symbol);

    const userAccountPDA = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from(user_wallet.toBuffer())],
      program.programId
    );

    const PDA = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA)],
      program.programId
    );

    let switchboardDest;
    let userCollateralAta;
    let cbsCollateralAta;

    if (symbol !== "SOL") {
      switchboardDest = getSwitchboardAccount(symbol);
      userCollateralAta = await getATAPublicKey(tokenMint, user_wallet);
      cbsCollateralAta = await getATAPublicKey(tokenMint, PDA[0]);
    }

    const [stability_fee, _bump] = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from("stability_fee")],
      program.programId
    );

    const tx = new anchor.web3.Transaction();

    const txInstructions = await epoch_stability_fee_instructions(
      program,
      user_wallet,
      stability_fee
    );
    txInstructions.forEach((txItem) => {
      tx.add(txItem);
    });

    if (symbol !== "SOL" && symbol !== "mSOL") {
      tx.add(addPriorityFee);
      tx.add(
        await program.methods
          .withdraw(convert_to_wei_value(tokenMint, amount), is_max)
          .accounts({
            commonWithdraw: {
              userAuthority: user_wallet,
              userAccount: userAccountPDA[0],
              programPda: PDA[0],
              config,
              switchboardSol: switchboardSolAccount,
              switchboardUsdc: switchboardUsdcAccount,
              marinadeState: STATE_PUB,
              stabilityFee: stability_fee,
              ctokenInfoAccounts: cTokenInfoAccounts,
            },
            tokenMint: tokenMint,
            userCollateralAta,
            cbsCollateralAta,
            switchboardDest: switchboardDest,
            systemProgram: SystemProgram.programId,
            tokenProgram: TOKEN_PROGRAM_ID,
            associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
            rent: SYSVAR_RENT_PUBKEY,
          })
          .instruction()
      );
    } else if (symbol === "SOL") {
      const SOL_PDA = await PublicKey.findProgramAddress(
        [Buffer.from(SEED_SOL)],
        program.programId
      );
      tx.add(addPriorityFee);
      tx.add(
        await program.methods
          .withdrawSol(convert_to_wei_value_with_decimal(amount, 9), is_max)
          .accounts({
            commonWithdraw: {
              userAuthority: user_wallet,
              userAccount: userAccountPDA[0],
              programPda: PDA[0],
              config,
              switchboardSol: switchboardSolAccount,
              switchboardUsdc: switchboardUsdcAccount,
              marinadeState: STATE_PUB,
              stabilityFee: stability_fee,
              ctokenInfoAccounts: cTokenInfoAccounts,
            },
            solAccount: SOL_PDA[0],
            switchboardSol: switchboardSolAccount,
            systemProgram: SystemProgram.programId,
          })
          .instruction()
      );
    } else if (symbol === "mSOL") {
      tx.add(addPriorityFee);
      tx.add(
        await program.methods
          .withdrawMsol(convert_to_wei_value(tokenMint, amount), is_max)
          .accounts({
            commonWithdraw: {
              userAuthority: user_wallet,
              userAccount: userAccountPDA[0],
              programPda: PDA[0],
              config,
              switchboardSol: switchboardSolAccount,
              switchboardUsdc: switchboardUsdcAccount,
              marinadeState: STATE_PUB,
              stabilityFee: stability_fee,
              ctokenInfoAccounts: cTokenInfoAccounts,
            },
            tokenMint: tokenMint,
            userCollateralAta,
            cbsCollateralAta,
            switchboardSol: switchboardSolAccount,
            systemProgram: SystemProgram.programId,
            tokenProgram: TOKEN_PROGRAM_ID,
            associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
            rent: SYSVAR_RENT_PUBKEY,
          })
          .instruction()
      );
    }

    if (tx) {
      const provider = anchor.getProvider();
      const conTx = await provider.sendAndConfirm(tx);

      OpenContractSnackbar(true, "Success", "Transaction successful", conTx);

      setMessage("Enter an amount");
      setRequired(false);
      setAmount("");
    } else {
      OpenContractSnackbar(true, "Error", `Withdraw failed. Please try again.`);
    }
  } catch (error) {
    console.log(error);
    OpenContractSnackbar(true, "Error", `Withdraw failed. Please try again.`);
  }
};

// repay function for csb
// ==============================================
export const repay_cbs = async (
  wallet,
  symbol,
  amount,
  setMessage,
  setRequired,
  setAmount,
  OpenContractSnackbar,
  is_max
) => {
  try {
    OpenContractSnackbar(true, "Processing", `Repay ${amount} ${symbol}`);

    const program = getProgram(wallet, "lpIdl");

    const user_wallet = wallet.publicKey;

    const tokenMint = getMint(symbol);

    const userAccountPDA = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from(user_wallet.toBuffer())],
      program.programId
    );

    const PDA = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_TRV_PDA)],
      program.programId
    );

    const [stability_fee, _bump] = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from("stability_fee")],
      program.programId
    );

    const tx = new anchor.web3.Transaction();

    const userZsolAta = await getATAPublicKey(tokenMint, user_wallet);

    const txInstructions = await epoch_stability_fee_instructions(
      program,
      user_wallet,
      stability_fee
    );
    txInstructions.forEach((txItem) => {
      tx.add(txItem);
    });
    tx.add(addPriorityFee);
    tx.add(
      await program.methods
        .repayZsol(
          convert_to_wei_value_with_decimal(amount, zSOL_DECIMAL),
          is_max
        )
        .accounts({
          userAuthority: user_wallet,
          userAccount: userAccountPDA[0],
          config,
          zsolMint: zSOL_MINT,
          stabilityFee: stability_fee,
          userZsolAta,
          systemProgram: SystemProgram.programId,
          tokenProgram: TOKEN_PROGRAM_ID,
          rent: SYSVAR_RENT_PUBKEY,
        })
        .instruction()
    );

    const provider = anchor.getProvider();
    const conTx = await provider.sendAndConfirm(tx);

    OpenContractSnackbar(true, "Success", "Transaction successful", conTx);
    setMessage("Enter an amount");
    setRequired(false);
    setAmount("");
  } catch (error) {
    console.log(error);
    OpenContractSnackbar(true, "Error", `Repayment failed. Please try again.`);
  }
};
