import React from "react";
import ButtonWrapper from "./Button.style";
import { useLocation } from "react-router-dom";

const Button = ({ children, ...props }) => {
  const location = useLocation();

  return (
    <ButtonWrapper {...props} path={location.pathname}>
      {children}
    </ButtonWrapper>
  );
};

export default Button;
