const BaseUrl = process.env.REACT_APP_BACKEND_ENDPOINT;

const api = {
  price: "https://price.jup.ag/v4/price",
  mSOL_Apy: "https://api.marinade.finance/msol/apy/2w",
  lido_apy: "https://sol-api-pub.lido.fi/v1/apy?since_launch",
  mSOLPrice: "https://api.marinade.finance/msol/price_usd",
  getTreasuryData: BaseUrl + "/global/getTreasuryData",
  getCbsOverviewData: BaseUrl + "/global/getCbsOverviewData",
  storeSwapSize: BaseUrl + "/global/storeSwapSize",
  getDaySwapSize: BaseUrl + "/global/getDaySwapSize",
  get_liquidate_users: BaseUrl + "/global/get_liquidate_users",
  get_lp_incentives_info: BaseUrl + "/global/get_lp_incentives_info",
};

export default api;
