import React, { useEffect, useState } from "react";
import Tabs from "./Tabs";
import LPIncentivesWrapper from "styles/LPIncentives.style";
import Table from "./Table";
import { useContractSnackbar } from "contexts/ContractSnackbarContext";
import { useGlobal } from "contexts/GlobalContext";
import { useCrypto } from "contexts/CryptoContext";
import NlpDataModel from "models/NlpDataModel";
import { useTheme } from "contexts/ThemeContext";

const LPIncentives = () => {
  const { Theme } = useTheme();
  const { OpenContractSnackbar, ContractSnackbarType } = useContractSnackbar();
  const {
    BalanceHandler,
    PriceHandler,
    PriceList,
    BalanceList,
    publicKey,
    wallet,
  } = useCrypto();
  const {
    nLPUserInfo,
    nLPInfo,
    handle_nlp_user_info,
    handle_nlp_Info,
    stabilityFeeInfo,
    treasuryInfo,
    cbsInfo,
    handleNlpChart,
  } = useGlobal();
  const [loadingChart, setLoadingChart] = useState(false);
  const [ChartData, setChartData] = useState([]);
  const [isModel, setIsModel] = useState(false);
  const [days, setDays] = useState(7);

  useEffect(() => {
    if (ContractSnackbarType === "Success") {
      handle_nlp_user_info();
      handle_nlp_Info();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ContractSnackbarType]);

  useEffect(() => {
    handleNlpChart(days, setLoadingChart, setChartData);
    return () => {
      setChartData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days]);

  return (
    <>
      {isModel && (
        <NlpDataModel
          isOpen={isModel}
          isClose={() => setIsModel(false)}
          List={ChartData}
          setDays={setDays}
          Days={days}
          Theme={Theme}
          loadingChart={loadingChart}
        />
      )}
      <LPIncentivesWrapper>
        <div className="container LPIncentives">
          <div className="row mt-4">
            <div className="col-12 d-flex justify-content-center flex-column">
              <div className="LPIncentives_title text-center">
                <h1>LP Incentives</h1>
              </div>
              <div className="LPIncentives_subtitle text-center d-flex flex-column">
                <p>Provide Liquidity to earn rewards</p>
                <p className="mt-1">
                  Stability Fee Epoch : {stabilityFeeInfo?.nextEpochDate}
                </p>
              </div>
            </div>
          </div>
          <Table
            {...{
              Theme,
              lpPrice: PriceHandler["SOL-zSOL"],
              stabilityFee: stabilityFeeInfo.stabilityFee,
              total_staked_amount: nLPInfo.total_staked_amount,
              totalBorrowedZsol: cbsInfo.TotalBorrowed,
              treasuryTotalBorrowed: treasuryInfo.TotalBorrowed,
              setIsModel,
            }}
          />
          <Tabs
            {...{
              publicKey,
              wallet,
              Theme,
              OpenContractSnackbar,
              nLPUserInfo,
              BalanceHandler,
              PriceList,
              BalanceList,
              PriceHandler,
              SOLPrice: PriceHandler["SOL"],
              zSOLPrice: PriceHandler["zSOL"],
              is_paused: nLPInfo.is_paused,
            }}
          />
        </div>
      </LPIncentivesWrapper>
    </>
  );
};

export default LPIncentives;
