import React, { useMemo } from "react";

import { TokenImgRegistry } from "assets/registry";
import Image from "Layout/Image";
import { numFormatter, calc } from "helper";
import Button from "Layout/Button";

const HeadersList = ["Pools", "Total Staked", "Rewards APY", "Data"];

const Table = ({
  total_staked_amount,
  lpPrice,
  stabilityFee,
  Theme,
  treasuryTotalBorrowed,
  totalBorrowedZsol,
  setIsModel,
}) => {
  const List = useMemo(() => {
    const total_staked_nlp_usd = total_staked_amount * lpPrice;

    const zsol_apr =
      (((treasuryTotalBorrowed + totalBorrowedZsol) * (stabilityFee / 100)) /
        total_staked_nlp_usd) *
      100;

    const RewardsAPY = ((1 + zsol_apr / 36500) ** 365 - 1) * 100;

    const LPIncentivesItems = [
      {
        id: 1,
        website: "https://app.saber.so/pools/zsol/deposit",
        Img1: TokenImgRegistry.SOL,
        Img2: TokenImgRegistry.zSOL,
        name1: "SOL",
        name2: "zSOL",
        TotalStaked: numFormatter(total_staked_nlp_usd),
        RewardsAPY: calc(RewardsAPY),
      },
    ];
    return LPIncentivesItems;
  }, [
    lpPrice,
    totalBorrowedZsol,
    total_staked_amount,
    stabilityFee,
    treasuryTotalBorrowed,
  ]);

  return (
    <div className="row mt-4 pt-1 LPIncentives_table_section pb-3">
      <div className="col-12">
        <div className="table_card">
          <table className="table table-hover">
            <thead>
              <tr>
                {HeadersList.map((head, ind) => {
                  return (
                    <th scope="col" key={ind}>
                      <p>{head}</p>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {List.map((list) => {
                return (
                  <tr key={list.id}>
                    <td>
                      <div className="details d-flex align-items-center">
                        <Image src={list.Img1} alt={list.name1} h="2rem" />
                        <Image
                          src={list.Img2}
                          alt={list.name2}
                          h="2rem"
                          w="2rem"
                          className="toggle"
                        />
                        <a
                          href={list.website}
                          className="d-flex align-items-center"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div className="pl-4">
                            {list.name1}-{list.name2}
                          </div>
                        </a>
                      </div>
                    </td>
                    <td>
                      <p>${list.TotalStaked}</p>
                    </td>
                    <td>
                      <p>{!list?.RewardsAPY ? 0 : list.RewardsAPY}%</p>
                    </td>
                    <td>
                      <Button
                        active={5}
                        br="4px"
                        p="0.5rem 0.2rem"
                        Theme={Theme}
                        onClick={() => setIsModel(true)}
                      >
                        Data
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Table;
