/* eslint-disable no-unused-vars */
import * as anchor from "@project-serum/anchor";
import { getMxAmount } from "utils/psm/get_psm_rate";
import axios from "axios";
import api from "api";
import {
  getProgram,
  getATAPublicKey,
  convert_to_wei_value,
  convert_to_wei_value_with_decimal,
  epoch_stability_fee_instructions,
} from "utils/contract";
import {
  SEED_TRV_PDA,
  SEED_ZSOL_MINT_AUTHORITY_PDA,
  zSOL_MINT,
  config,
  SEED_PDA,
  STATE_PUB,
  switchboardSolAccount,
  switchboardZsolAccount,
  cTokenInfoAccounts,
  getMint,
  zSOL_DECIMAL,
  getSwitchboardAccount,
  getSymbol,
} from "constants/global";
import {
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
} from "@solana/spl-token";

const { PublicKey, SystemProgram, SYSVAR_RENT_PUBKEY } = anchor.web3;

// burn_zSOL function for PSM
// ==============================================
export const burn_zSOL = async (
  wallet,
  array,
  OpenCommand,
  BalanceHandler,
  PriceHandler
) => {
  try {
    const user_wallet = wallet.publicKey;

    if (!user_wallet) {
      OpenCommand(false, "Error", "Connect wallet");
      return;
    }

    let tokenA = getSymbol(array[2]);
    let tokenB = getSymbol(array[4]);
    const amount = parseFloat(array[5]);

    if (amount === 0) {
      OpenCommand(false, "Error", "Enter an amount");
      return;
    } else if (amount > BalanceHandler[tokenA]) {
      OpenCommand(false, "Error", "Insufficient Balance");
      return;
    }

    const max = await getMxAmount(
      wallet,
      tokenA,
      BalanceHandler[tokenA]
    );

    if (amount > max) {
      OpenCommand(false, "Error", "Exceed max input amount");
      return;
    }

    let mess;
    if (tokenB === "mSOL") {
      mess = `claim`;
    } else {
      mess = `mint`;
    }

    OpenCommand(true, "Processing", `Start ${mess} ${tokenB}`);

    const program = getProgram(wallet, "lpIdl");

    const token_dest = getMint(tokenB);

    const configData = await program.account.config.fetch(config);
    const getFeeAccount = configData.feeAccount;

    const PDA = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_TRV_PDA)],
      program.programId
    );

    const switchboardDest = getSwitchboardAccount(tokenB);
    const userCollateralAta = await getATAPublicKey(token_dest, user_wallet);
    const userZsolAta = await getATAPublicKey(zSOL_MINT, user_wallet);
    const trvcCollateralAta = await getATAPublicKey(token_dest, PDA[0]);
    const feeAta = await getATAPublicKey(token_dest, getFeeAccount);

    const [stability_fee, _bump] = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from("stability_fee")],
      program.programId
    );

    const tx = new anchor.web3.Transaction();

    const txInstructions = await epoch_stability_fee_instructions(
      program,
      user_wallet,
      stability_fee
    );
    txInstructions.forEach((txItem) => {
      tx.add(txItem);
    });

    tx.add(
      await program.methods
        .burnZsol(convert_to_wei_value_with_decimal(amount, zSOL_DECIMAL))
        .accounts({
          userAuthority: user_wallet,
          trvcAccount: PDA[0],
          zsolMint: zSOL_MINT,
          userZsolAta,
          stabilityFee: stability_fee,
          marinadeState: STATE_PUB,
          ctokenInfoAccounts: cTokenInfoAccounts,
          collateralToken: token_dest,
          userCollateralAta: userCollateralAta,
          trvcCollateralAta,
          config,
          feeAccount: feeAta,
          switchboardDest: switchboardDest,
          systemProgram: SystemProgram.programId,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          tokenProgram: TOKEN_PROGRAM_ID,
          rent: SYSVAR_RENT_PUBKEY,
        })
        .instruction()
    );

    let messSucc;
    if (tokenB === "mSOL") {
      messSucc = `claimed`;
    } else {
      messSucc = `minted`;
    }

    const provider = anchor.getProvider();
    const conTx = await provider.sendAndConfirm(tx);

    if (conTx) {
      OpenCommand(true, "Processing", `${messSucc} ${amount} ${tokenB}`);
      OpenCommand(false, "Success", conTx);

      var swapSize = amount * PriceHandler[tokenA];

      await axios.post(api.storeSwapSize, {
        size: swapSize,
      });
    } else {
      OpenCommand(false, "Error", "Transaction Failed");
    }
  } catch (error) {
    OpenCommand(false, "Error", "Transaction Failed");
  }
};

// mint zSOL function for PSM
// ==============================================
export const mint_zSOL = async (
  wallet,
  array,
  OpenCommand,
  BalanceHandler,
  PriceHandler
) => {
  try {
    const user_wallet = wallet.publicKey;

    if (!user_wallet) {
      OpenCommand(false, "Error", "Connect wallet");
      return;
    }

    let tokenA = getSymbol(array[2]);
    let tokenB = getSymbol(array[4]);
    const amount = parseFloat(array[5]);

    if (amount === 0) {
      OpenCommand(false, "Error", "Enter an amount");
      return;
    } else if (amount > BalanceHandler[tokenA]) {
      OpenCommand(false, "Error", "Insufficient Balance");
      return;
    }

    const max = await getMxAmount(
      wallet,
      tokenA,
      BalanceHandler[tokenA]
    );

    if (amount > max) {
      OpenCommand(false, "Error", "Exceed max input amount");
      return;
    }

    OpenCommand(true, "Processing", `Start mint ${tokenB}`);

    const program = getProgram(wallet, "lpIdl");

    const token_dest = getMint(tokenA);

    const PDA = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_TRV_PDA)],
      program.programId
    );

    const zSOL_MINT_PDA = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_ZSOL_MINT_AUTHORITY_PDA)],
      program.programId
    );

    const switchboardSrc = getSwitchboardAccount(tokenA);
    const userCollateralAta = await getATAPublicKey(token_dest, user_wallet);
    const userZsolAta = await getATAPublicKey(zSOL_MINT, user_wallet);
    const trvcCollateralAta = await getATAPublicKey(token_dest, PDA[0]);

    const [stability_fee, _bump] = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from("stability_fee")],
      program.programId
    );

    const tx = new anchor.web3.Transaction();

    const txInstructions = await epoch_stability_fee_instructions(
      program,
      user_wallet,
      stability_fee
    );
    txInstructions.forEach((txItem) => {
      tx.add(txItem);
    });

    tx.add(
      await program.methods
        .mintZsol(convert_to_wei_value(token_dest, amount))
        .accounts({
          trvcAccount: PDA[0],
          zsolMintAuthority: zSOL_MINT_PDA[0],
          ctokenInfoAccounts: cTokenInfoAccounts,
          collateralToken: token_dest,
          userCollateralAta: userCollateralAta,
          zsolMint: zSOL_MINT,
          stabilityFee: stability_fee,
          userZsolAta,
          config,
          marinadeState: STATE_PUB,
          trvcCollateralAta,
          userAuthority: user_wallet,
          switchboardSol: switchboardSolAccount,
          switchboardZsol: switchboardZsolAccount,
          switchboardSrc: switchboardSrc,
          systemProgram: SystemProgram.programId,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          tokenProgram: TOKEN_PROGRAM_ID,
          rent: SYSVAR_RENT_PUBKEY,
        })
        .instruction()
    );

    const provider = anchor.getProvider();
    const conTx = await provider.sendAndConfirm(tx);

    if (conTx) {
      OpenCommand(true, "Processing", `Minted ${amount} ${tokenB}`);
      OpenCommand(false, "Success", conTx);

      var swapSize = amount * PriceHandler[tokenA];

      await axios.post(api.storeSwapSize, {
        size: swapSize,
      });
    } else {
      OpenCommand(false, "Error", "Transaction Failed");
    }
  } catch (error) {
    OpenCommand(false, "Error", "Transaction Failed");
  }
};
