import * as anchor from "@project-serum/anchor";
const { PublicKey } = anchor.web3;

export const convert_to_wei = (val) =>
  parseInt(parseFloat(val) * 1e9).toString();

export const convert_to_dy_wei = (val, con) =>
  parseInt(parseFloat(val) * con).toString();

// TypelessRepay
export const TYPE_LESS_DISCOUNT_RATE = 0.9995;
export const TRVC_SWAP_FEE = 0.5;
export const zSOL_DECIMAL = 9;
export const SOL_DECIMAL = 9;
export const STABILITY_FEE_FEE_RATE = 5;
export const STABILITY_FEE_APR = 1.5;
const epoch_ts = Date.UTC(2023, 3, 14);
export const LAST_EPOCH_TS = epoch_ts / 1000;
export const DEPOSIT_FEE = 0.3;
export const nLP_DEPOSIT_FEE = 0.5;

export const SEED_PDA = "lpfinancev2";
export const SEED_TOKEN = "lpfinance-token";
export const SEED_CTOKEN = "ctokens-v1";
export const SEED_SOL = "lpfinance-sol";
export const SEED_ZSOL_MINT_AUTHORITY_PDA = "zsol_mint";
export const SEED_TRV_PDA = "typeless_repayment_vault2";
export const SEED_LIQUIDATOR = "lpfinance-liquidator";
export const SEED_LPFi_BUYBACK = "lpfinance-buyback";
export const NLP_SEED_PDA = "nlp-staking";
export const SEED_CONFIG = "config-v1";
export const MSOL_DENOMINATOR = 4294967296;

export const DAILY_LPFI_REWARD_AMOUNT = 100;
export const MAX_HISTORY_LEN = 2000;

export const NLP_STAKING_PROGRAM = new PublicKey(
  "FzTfrps1jZstduuSJ3ePsMTTR4rYX1hp73AcJ22ip4Gu"
);

export const FEE_ADMIN = new PublicKey(
  "C9ZhKm84WLUQyYjjsSqXRMPsbWvzjguNPWErJfkkytn6"
);

export const ADMIN = new PublicKey(
  "CMEbDAwGWnurirWQXNn1p4jF8ndXU53p5t55YwXrjxiY"
);

export const TRVC_ADMIN = new PublicKey(
  "AZzscKGxcnS25oyvcLWoYWAQPE4uv4pycXR8ANq1HkmD"
);

export const config = new PublicKey(
  "AJWhGoFvKFbSbcSb6Zn1tWSaeYTYRLbkFrSs9HHhh5Jy"
);

export const cTokenInfoAccounts = new PublicKey(
  "9jy45i1AQ6h7Yeh274PrTLeDmkSRRFp4F9KP8pMew3Pa"
);

export const zSOL_MINT_AUTHORITY = new PublicKey(
  "8qTM9XXTrDG8gQooEUw6EUWhijijew4f1zdYAsMEGo6Z"
);

export const LIDO_ADDRESS = new PublicKey(
  "49Yi1TKkNyYjPAFdR9LBvoHcUjuPX4Df5T5yv39w2XTn"
);

export const LPFINANCE_AUTHORITY = new PublicKey(
  "CUN9ZZKrFKpDDTvoZoz7qVU16B418UPSuPHbkkS3MiGS"
);

// Mint config
// =================================
export const MintAddress = {
  SOL: "So11111111111111111111111111111111111111112",
  mSOL: "mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So",
  zSOL: "So111DzVTTNpDq81EbeyKZMi4SkhU9yekqB8xmMpqzA",
  nLP_MINT: "zSo2NgZQ5fv2bQ393CfCo6szdbeAc5RciwFkwBS659W",
  USDC: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
  JITOSOL: "J1toso1uCk3RLmjorhTtrVwY9HJ7X8V9yYac6Y7kGCPn",
  BSOL: "bSo13r4TkiE4KumL71LsHTPpL2euBYLFx6h9HP3piy1",
};

export const SOL_MINT = new PublicKey(
  "So11111111111111111111111111111111111111112"
);

export const mSOL_MINT = new PublicKey(
  "mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So"
);

export const zSOL_MINT = new PublicKey(
  "So111DzVTTNpDq81EbeyKZMi4SkhU9yekqB8xmMpqzA"
);

export const USDC_MINT = new PublicKey(
  "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"
);

export const JITOSOL_MINT = new PublicKey(
  "J1toso1uCk3RLmjorhTtrVwY9HJ7X8V9yYac6Y7kGCPn"
);

export const BSOL_MINT = new PublicKey(
  "bSo13r4TkiE4KumL71LsHTPpL2euBYLFx6h9HP3piy1"
);

export const nLP_MINT = new PublicKey(
  "zSo2NgZQ5fv2bQ393CfCo6szdbeAc5RciwFkwBS659W"
);

export const zSOL_ATA = new PublicKey(
  "7TKLLgrWswt6ZJ1pXhVR3FyufHGjJFqiEXxvWJfDcgRm"
);

export const SOL_ATA = new PublicKey(
  "CMRhskR2Eg6V42RTSdRTXGdozyPbWB8U9dVpijPWb9T4"
);

// SWITCHBOARD config
// =======================================================================

export const switchboardSolAccount = new PublicKey(
  "GvDMxPzN1sCj7L26YDK2HnMRXEQmQ2aemov8YBtPS7vR"
);
export const switchboardUsdcAccount = new PublicKey(
  "BjUgj6YCnFBZ49wF54ddBVA9qu8TeqkFtkbqmZcee8uW"
);

export const switchboardZsolAccount = new PublicKey(
  "GvDMxPzN1sCj7L26YDK2HnMRXEQmQ2aemov8YBtPS7vR"
);

export const switchboardbsolAccount = new PublicKey(
  "EeSBrqRNbPkWY25BQZZMSfBLeLnLpkZ3oMYnPn15yjQp"
);

export const switchboardjitosolAccount = new PublicKey(
  "D4HWDb4MFbp8u3wBkXy5Dk8sDiy3XrgchvxAd9vLn25d"
);

// Marinade finance config
// =======================================================
export const STATE_PUB = new PublicKey(
  "8szGkuLTAux9XMgZ2vtY39jVSowEcpBfFfD8hXSEqdGC"
);
export const LIQ_POOL_SOL_LEG_PDA = new PublicKey(
  "UefNb6z6yvArqe4cJHTXCqStRsKmWhGxnZzuHbikP5Q"
);
export const LIQ_POOL_MSOL_LEG = new PublicKey(
  "7GgPYjS5Dza89wV6FpZ23kUJRG5vbQ1GM25ezspYFSoE"
);

export const RESERVE_PDA = new PublicKey(
  "Du3Ysj1wKbxPKkuPPnvzQLQh8oMSVifs3jGZjJWXFmHN"
);

export const MARINADE_PROGRAM = new PublicKey(
  "MarBmsSgKXdrN1egZf5sqe1TMai9K1rChYNDJgjq7aD"
);

export const LIQ_POOL_MSOL_LEG_AUTHORITY = new PublicKey(
  "EyaSjUtSgo9aRD1f8LWXwdvkpDTmXAW54yoSHZRF14WL"
);

export const mSOL_MINT_AUTHORITY = new PublicKey(
  "3JLPCS1qM2zRw3Dp6V4hZnYHd4toMNPkNesXdX9tg6KM"
);

export const cTOKEN_INFOS = [
  {
    token: SOL_MINT,
    decimal: 9,
    borrowable_max_ltv: 90, // 90%
    liquidatable_max_ltv: 95, // 95%
    is_active: true,
    deposit_cap: new anchor.BN("0"),
    name: '{"sol":{}}', // { sol: {}}, // CollateralTokenName.SOL,
    switchboard_acc_pub: switchboardSolAccount,
    price_restriction: 0,
    symbol: "SOL",
  },
  // 1
  {
    token: mSOL_MINT,
    decimal: 9,
    borrowable_max_ltv: 95, // 90%
    liquidatable_max_ltv: 98, // 95%
    is_active: true,
    deposit_cap: new anchor.BN("0"),
    name: '{"msol":{}}', // { msol: {}}, //CollateralTokenName.MSOL,
    switchboard_acc_pub: switchboardSolAccount,
    price_restriction: 500,
    symbol: "mSOL",
  },
  // 2
  {
    token: USDC_MINT,
    decimal: 6,
    borrowable_max_ltv: 50, // 50%
    liquidatable_max_ltv: 65, // 65%
    is_active: true,
    deposit_cap: new anchor.BN("10000"),
    name: '{"usdc":{}}', //{ usdc: {}}, // CollateralTokenName.UXD,
    switchboard_acc_pub: switchboardUsdcAccount,
    price_restriction: 0,
    symbol: "USDC",
  },
  {
    token: zSOL_MINT,
    decimal: 9,
    borrowable_max_ltv: 90, // 90%
    liquidatable_max_ltv: 95, // 95%
    is_active: true,
    deposit_cap: new anchor.BN("10000"),
    name: '{"zsol":{}}', //{ usdc: {}}, // CollateralTokenName.UXD,
    switchboard_acc_pub: switchboardZsolAccount,
    price_restriction: 0,
    symbol: "zSOL",
  },
  {
    token: BSOL_MINT,
    decimal: 9,
    borrowable_max_ltv: 95, // 95%
    liquidatable_max_ltv: 98, // 98%
    is_active: true,
    deposit_cap: new anchor.BN("0"),
    name: '{"bsol":{}}', //{ bsol: {}}, // CollateralTokenName.BSOL,
    switchboard_acc_pub: switchboardbsolAccount,
    price_restriction: 0,
    symbol: "BSOL",
  },
  {
    token: JITOSOL_MINT,
    decimal: 9,
    borrowable_max_ltv: 95, // 95%
    liquidatable_max_ltv: 98, // 98%
    is_active: true,
    deposit_cap: new anchor.BN("0"),
    name: '{"jitosol":{}}', //{ jitosol: {}}, // CollateralTokenName.JITOSOL,
    switchboard_acc_pub: switchboardjitosolAccount,
    price_restriction: 0,
    symbol: "JITOSOL",
  },
];

export const getCTokenInfo = (token) => {
  for (let i = 0; i < cTOKEN_INFOS.length; i++) {
    const ctokenInfo = cTOKEN_INFOS[i];
    if (
      ctokenInfo.token.toString().toLocaleLowerCase() ===
      token.toString().toLocaleLowerCase()
    ) {
      return ctokenInfo;
    }
  }
  return undefined;
};

export const getCollateralTokenSymbol = (name) => {
  const name_str = JSON.stringify(name);
  for (let i = 0; i < cTOKEN_INFOS.length; i++) {
    const cTokenInfo = cTOKEN_INFOS[i];
    if (cTokenInfo.name === name_str) {
      return cTokenInfo.symbol;
    }
  }
  return undefined;
};

export const getOracleAccount = (token) => {
  for (let i = 0; i < cTOKEN_INFOS.length; i++) {
    const cTokenInfo = cTOKEN_INFOS[i];
    if (
      cTokenInfo.token.toString().toLocaleLowerCase() ===
      token.toString().toLocaleLowerCase()
    ) {
      return cTokenInfo.switchboard_acc_pub;
    }
  }
  return undefined;
};

export const getMint = (token) => {
  let mint;

  if (token === "SOL") {
    mint = SOL_MINT;
  } else if (token === "mSOL") {
    mint = mSOL_MINT;
  } else if (token === "zSOL") {
    mint = zSOL_MINT;
  } else if (token === "USDC") {
    mint = USDC_MINT;
  } else if (token === "JITOSOL") {
    mint = JITOSOL_MINT;
  } else if (token === "BSOL") {
    mint = BSOL_MINT;
  } else if (token === "nlp" || token === "SOL-zSOL") {
    mint = nLP_MINT;
  }

  return mint;
};

export const getSwitchboardAccount = (token) => {
  let Account;

  if (token === "SOL" || token === "mSOL") {
    Account = switchboardSolAccount;
  } else if (token === "USDC") {
    Account = switchboardUsdcAccount;
  } else if (token === "zSOL") {
    Account = switchboardZsolAccount;
  } else if (token === "JITOSOL") {
    Account = switchboardjitosolAccount;
  } else if (token === "BSOL") {
    Account = switchboardbsolAccount;
  }
  return Account;
};

export const getSymbol = (token) => {
  let symbol;

  if (token === "sol") {
    symbol = "SOL";
  } else if (token === "msol") {
    symbol = "mSOL";
  } else if (token === "zsol") {
    symbol = "zSOL";
  } else if (token === "usdc") {
    symbol = "USDC";
  } else if (token === "jitosol") {
    symbol = "JITOSOL";
  } else if (token === "bsol") {
    symbol = "BSOL";
  }
  return symbol;
};
