import { getProgram } from "../contract";
import { cTokenInfoAccounts } from "constants/global";

export const fetch_token_infos = async (wallet) => {
  try {
    const program = getProgram(wallet, "lpIdl");

    const ctoken_info_accounts_data =
      await program.account.cTokenInfoAccounts.fetch(cTokenInfoAccounts);

    let cTokenData = {
      SOL: "",
      mSOL: "",
      USDC: "",
      BSOL: "",
      JITOSOL: "",
    };

    const ctokenInfos = ctoken_info_accounts_data.ctokenInfos
      .filter((_elem, index) => index < ctoken_info_accounts_data.totalCount)
      .map((ctokenInfo) => {
        const { borrowableMaxLtv, liquidatableMaxLtv, name } = ctokenInfo;

        var symbol;

        if (name["usdc"]) {
          symbol = "USDC";
        } else if (name["sol"]) {
          symbol = "SOL";
        } else if (name["msol"]) {
          symbol = "mSOL";
        } else if (name["bsol"]) {
          symbol = "BSOL";
        } else if (name["jitosol"]) {
          symbol = "JITOSOL";
        }
        let Infos = {
          borrowableMaxLtv,
          liquidatableMaxLtv,
          symbol,
        };

        cTokenData[symbol] = Infos;

        return Infos;
      });

    return {
      cTokenData,
      ctokenInfos,
    };
  } catch (error) {
    console.log("error", error);
  }
};
