import styled from "styled-components";

const InputWrapper = styled.input`
  width: 100%;
  outline: none;
  border: none;
  background: transparent;
  padding: ${(props) => props.p};
  border-radius: ${(props) => props.br};
  -moz-appearance: textfield;
  font-size: ${(props) => props.size};
  display: flex;
  align-items: center;

  ${(props) =>
    props.active === 1 &&
    (props.Theme === "DARK"
      ? `
      text-align: right;
      direction: ltr;
      color: #0c0;
      box-shadow: inset -4px -4px 5px rgba(87, 87, 87, 0.2), inset 4px 4px 5px rgba(9, 9, 14, 0.5);
    `
      : `
      text-align: right;
      direction: ltr;
      color: black;
      box-shadow: inset -2px -2px 5px  #fff,inset 2px 2px 5px #bec8e4;
  `)}

  ${(props) =>
    props.active === 2 &&
    (props.Theme === "DARK"
      ? `
      text-align: left;
      direction: ltr;
      color: #0c0;
      box-shadow: inset -4px -4px 5px rgba(87, 87, 87, 0.2), inset 4px 4px 5px rgba(9, 9, 14, 0.5);
    `
      : `
      text-align: left;
      direction: ltr;
      color: black;
      box-shadow: inset -2px -2px 5px  #fff,inset 2px 2px 5px #bec8e4;
  `)}

  ${(props) =>
    props.active === 3 &&
    (props.Theme === "DARK"
      ? `
      color: #0c0;
      border: 1px solid rgba(255, 255, 255, 0.2);
     `
      : `
      border: 1px solid rgba(0, 0, 0, 0.2);
      color: black;
  `)}


  ${(props) =>
    props.active === 4 &&
    (props.Theme === "DARK"
      ? `
    color: white;
  `
      : `
    color: black;
  `)}


  &::placeholder {
    opacity: 0.6;
    ${(props) =>
      props.Theme === "DARK"
        ? `
      color: #0c0;
    `
        : `
      color: black;
    `}
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export default InputWrapper;
