import { TokenImgRegistry } from "./index";
import { MintAddress } from "constants/global";

export var DepositTokens = [
  {
    address: MintAddress.SOL,
    chainId: 101,
    decimals: 9,
    logoURI: TokenImgRegistry.SOL,
    name: "Wrapped SOL",
    symbol: "SOL",
  },
  {
    address: MintAddress.mSOL,
    chainId: 101,
    decimals: 9,
    logoURI: TokenImgRegistry.mSOL,
    name: "Marinade staked SOL",
    symbol: "mSOL",
  },
  {
    address: MintAddress.USDC,
    chainId: 101,
    decimals: 6,
    logoURI: TokenImgRegistry.USDC,
    name: "USD Coin",
    symbol: "USDC",
  },
  {
    address: MintAddress.JITOSOL,
    chainId: 101,
    decimals: 9,
    logoURI: TokenImgRegistry.JITOSOL,
    name: "Jito Staked SOL",
    symbol: "JITOSOL",
  },
  {
    address: MintAddress.BSOL,
    chainId: 101,
    decimals: 9,
    logoURI: TokenImgRegistry.BSOL,
    name: "Blaze Staked SOL",
    symbol: "BSOL",
  },
];

export var BorrowTokens = [
  {
    address: MintAddress.zSOL,
    chainId: 101,
    decimals: 9,
    logoURI: TokenImgRegistry.zSOL,
    name: "zSOL",
    symbol: "zSOL",
  },
];

export var RepayTokens = [
  {
    address: MintAddress.zSOL,
    chainId: 101,
    decimals: 9,
    logoURI: TokenImgRegistry.zSOL,
    name: "zSOL",
    symbol: "zSOL",
  },
];

export var WithdrawTokens = [
  {
    address: MintAddress.SOL,
    chainId: 101,
    decimals: 9,
    logoURI: TokenImgRegistry.SOL,
    name: "Wrapped SOL",
    symbol: "SOL",
  },
  {
    address: MintAddress.mSOL,
    chainId: 101,
    decimals: 9,
    logoURI: TokenImgRegistry.mSOL,
    name: "Marinade staked SOL",
    symbol: "mSOL",
  },
  {
    address: MintAddress.USDC,
    chainId: 101,
    decimals: 6,
    logoURI: TokenImgRegistry.USDC,
    name: "USD Coin",
    symbol: "USDC",
  },
  {
    address: MintAddress.JITOSOL,
    chainId: 101,
    decimals: 9,
    logoURI: TokenImgRegistry.JITOSOL,
    name: "Jito Staked SOL",
    symbol: "JITOSOL",
  },
  {
    address: MintAddress.BSOL,
    chainId: 101,
    decimals: 9,
    logoURI: TokenImgRegistry.BSOL,
    name: "Blaze Staked SOL",
    symbol: "BSOL",
  },
];

export const totalSupplyChartList = [
  {
    id: 1,
    name: "SOL",
    dataKey: "SOL",
    fill: "url(#SOL)",
    stroke: "#ab4ff6",
    checked: true,
  },
  {
    id: 2,
    name: "mSOL",
    dataKey: "mSOL",
    fill: "url(#mSOL)",
    stroke: "#a8f6d8",
    checked: true,
  },
  {
    id: 3,
    name: "USDC",
    dataKey: "USDC",
    fill: "url(#USDC)",
    stroke: "#324ff7",
    checked: true,
  },
  {
    id: 4,
    name: "BSOL",
    dataKey: "BSOL",
    fill: "url(#BSOL)",
    stroke: "#32d3f7",
    checked: true,
  },
  {
    id: 5,
    name: "JITOSOL",
    dataKey: "JITOSOL",
    fill: "url(#JITOSOL)",
    stroke: "#3bf92f",
    checked: true,
  },
];

export const totalBorrowedChartList = [
  {
    id: 1,
    name: "zSOL",
    dataKey: "zSOL",
    fill: "url(#zSOL)",
    stroke: "#0c0",
    checked: true,
  },
];

export const StabilityFeeChartList = [
  {
    id: 1,
    name: "stabilityFee",
    dataKey: "stabilityFee",
    fill: "url(#stabilityFee)",
    stroke: "#0c0",
    checked: true,
  },
];
