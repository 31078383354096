import { createGlobalStyle } from 'styled-components';

export const lightTheme = {
  lightBg: '#e4ebf5', // body bg color
  BodyPrimaryBg:
    'linear-gradient(85.12deg, rgba(185, 185, 241, 0.2) 0%, rgba(84, 132, 234, 0.2) 56.29%, rgba(58, 142, 137, 0.2) 99.99%)', // for hover button
  BodySecondaryBg: '#080808', // text color,
  BodyTraceryBg: '#7620e0ff', // main text color
  BodyMainColor: '#333333', // text color
  BodySecondaryColor: '#3d3d3d', // light text color
  BodyTextColor: 'white', // text color
  BodyMainLine: 'rgb(28, 28, 255)', // light line color
  lightBgDarkShadow: '#bec8e4', // color for card shadow
  lightBgLightShadow: '#fff', // color for card shadow
  boxShadowMain: '3px 3px 6px #bec8e4,-3px -3px 6px #fff',
  boxShadowSecondary: 'inset -2px -2px 5px #fff, inset 2px 2px 5px #bec8e4',
  boxShadowTracery: '2px 2px 4px #bec8e4,-2px -2px 4px #fff',
};

export const darkTheme = {
  lightBg: '#161724', // body bg color ,#222
  BodyPrimaryBg: 'rgba(255, 255, 255, 0.2)', // for text, hover , bg, button
  BodySecondaryBg: '#080808', //card, button bg color,
  BodyTraceryBg: '#0f0',
  BodyMainColor: '#0c0', // text color
  BodySecondaryColor: '#0c0',
  BodyTextColor: 'white', // text color
  BodyMainLine: '#3c3c3c',
  lightBgDarkShadow: 'rgba(9, 9, 14, 0.5)', // color for card shadow
  lightBgLightShadow: 'rgba(87, 87, 87, 0.2)', // color for card shadow
  boxShadowMain:
    '7px 7px 10px rgba(9, 9, 14, 0.5), -7px -7px 10px rgba(87, 87, 87, 0.2)',
  boxShadowSecondary:
    'inset -4px -4px 5px rgba(87, 87, 87, 0.2), inset 4px 4px 5px rgba(9, 9, 14, 0.5)',
  boxShadowTracery:
    '4px 4px 6px rgba(9, 9, 14, 0.5), -4px -4px 6px rgba(87, 87, 87, 0.2)',
};

export const GlobalStyle = createGlobalStyle`

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;  
}

html {
  scroll-behavior: smooth;
  -ms-text-size-adjust:100%;
  -webkit-text-size-adjust:100%;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}

body {
  margin:0;
  font-family:'SF Mono Medium';
  background:${(props) => props.theme.lightBg};
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {   
  font-weight:500;
  color: ${(props) => props.theme.BodyMainColor};
  font-size:1.8rem;
}

p {
  margin-bottom: 0 !important;
}

ul {
  margin-bottom: inherit !important;
}

.container {
  max-width: 1352px !important;
  padding-left:100px;
  padding-right:100px;
}


.not-allowed {
  cursor: not-allowed! important;
}
 

@media only screen and (max-width: 1273px) { 
  .container {
    padding-left:30px;
    padding-right:30px;
  }  
}

@media only screen and (max-width: 900px) { 
  .container {
    padding-left:15px;
    padding-right:15px;
  } 
}

@media only screen and (max-width: 600px) {
  .container {
    padding-left:15px;
    padding-right:15px;
  }
}
`;
