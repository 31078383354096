import React, { useEffect } from "react";
import Footer from "../Footer";
import Header from "../Header";
import styled from "styled-components";
import { useTheme } from "contexts/ThemeContext";
import { useQwestive } from "contexts/IQwestiveContext";
import { useCrypto } from "contexts/CryptoContext";
import { useGlobal } from "contexts/GlobalContext";
import { useContractSnackbar } from "contexts/ContractSnackbarContext";
import { useCommand } from "contexts/CommandContext";
import { useCluster } from "contexts/ClusterContext";

const FooterWrapper = styled.div`
  min-height: calc(100vh - 259px);
`;

const Layout = ({ children }) => {
  const { Theme, handleTheme } = useTheme();
  const { ContractSnackbarType } = useContractSnackbar();
  const {
    Cluster,
    changeCluster,
    customCluster,
    setCustomCluster,
    CustomCluster,
  } = useCluster();
  const {
    PriceHandler,
    BalanceHandler,
    storePrice,
    storeBal,
    wallet,
    publicKey,
  } = useCrypto();
  const {
    handleCbsInfo,
    handleCbsUserInfo,
    handleTreasuryInfo,
    handle_nlp_user_info,
    handle_nlp_Info,
    handle_stability_fee,
    handle_token_infos,
  } = useGlobal();
  const { CommandMess, OpenCommand, CommandType, closeCommand } = useCommand();
  const { isLoading, setAlias } = useQwestive();

  useEffect(() => {
    if (publicKey && !isLoading) {
      const Key = publicKey.toString();
      setAlias({ id: Key });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKey]);

  return (
    <>
      <Header
        {...{
          handleTheme,
          Theme,
          Cluster,
          changeCluster,
          customCluster,
          setCustomCluster,
          CustomCluster,
          ContractSnackbarType,
          PriceHandler,
          BalanceHandler,
          storePrice,
          storeBal,
          wallet,
          handleCbsInfo,
          handleCbsUserInfo,
          handleTreasuryInfo,
          handle_nlp_user_info,
          handle_nlp_Info,
          handle_stability_fee,
          handle_token_infos,
          CommandMess,
          OpenCommand,
          CommandType,
          closeCommand,
        }}
      />
      <FooterWrapper>{children}</FooterWrapper>
      <Footer Theme={Theme} />
    </>
  );
};

export default Layout;
