import { TokenImgRegistry } from "./index";
import { MintAddress } from "constants/global";

export const LPIncentivesTokenRegistry = [
  {
    address: MintAddress.nLP_MINT,
    chainId: 101,
    decimals: 9,
    piarA: TokenImgRegistry.SOL,
    piarB: TokenImgRegistry.zSOL,
    name: "SOL-zSOL(Saber)",
    symbol: "SOL-zSOL",
  },
];

export const NlpTotalStakedChartList = [
  {
    id: 1,
    name: "Total Staked",
    dataKey: "totalStakedAmount",
    fill: "url(#totalStakedAmount)",
    stroke: "#73d6d9",
    checked: true,
  },
];

export const NlpZsolAprChartList = [
  {
    id: 1,
    name: "Rewards APY",
    dataKey: "RewardsAPY",
    fill: "url(#RewardsAPY)",
    stroke: "#0c0",
    checked: true,
  },
];
