import styled from "styled-components";

const WalletButtonWrapper = styled.div`
  .wallet-adapter-button-trigger {
    font-style: normal;
    font-weight: ${(props) => props.fw};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${(props) => props.size};
    padding: ${(props) => props.p};
    border-radius: ${(props) => props.br};
    width: 100%;

    ${(props) =>
      props.active === 1 &&
      (props.Theme === "DARK"
        ? `
      color:  #0c0;
      background: #161724;
      border-color: rgba(9, 9, 14, 0.5);
      box-shadow: 7px 7px 10px rgba(9, 9, 14, 0.5), -7px -7px 10px rgba(87, 87, 87, 0.2);
      font-weight:600;
      
      &:focus {
        box-shadow: inset -4px -4px 5px rgba(87, 87, 87, 0.2), inset 4px 4px 5px rgba(9, 9, 14, 0.5);
      }

      &:hover {
        background-color: transparent !important;
        color: #0c0;
      }
      
      `
        : `
        color: #7620e0ff;
        background: #e4ebf5;
        border-color: #bec8e4;
        box-shadow: 3px 3px 6px #bec8e4, -3px -3px 6px #fff;
        font-weight:600;
    
        &:focus {
          box-shadow: inset -2px -2px 5px  #fff,inset 2px 2px 5px #bec8e4;
        }
        
        &:hover {
          background-color: transparent !important;
          color: #7620e0ff;
        }
         
    `)}
  }
`;

export default WalletButtonWrapper;
