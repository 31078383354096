import React from "react";
import { useNavigate } from "react-router-dom";
import ErrorWrapper from "./Error.style";
import Button from "Layout/Button";
import { useTheme } from "contexts/ThemeContext";

const Error = () => {
  const navigate = useNavigate();
  const { Theme } = useTheme();

  return (
    <ErrorWrapper>
      <div className="container Error_Page">
        <div className="Error">
          <div className="row d-flex justify-content-center my-lg-5 my-md-5 my-sm-5 mt-5">
            <div className="col-12 d-flex justify-content-center  flex-column text-center">
              <h1>404</h1>
              <p>Page Not Found</p>
            </div>
            <div className="col-lg-12 col-md-4 col-6 d-flex justify-content-center mt-3">
              <Button
                active={5}
                br="10px"
                p="0.5rem 0rem"
                id="btn"
                onClick={() => navigate("/")}
                Theme={Theme}
              >
                Go To Home
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ErrorWrapper>
  );
};

export default Error;
