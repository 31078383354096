import { getProgram, getConnection, getNetwork } from "../contract";
import { loadSwitchboardProgram } from "@switchboard-xyz/switchboard-v2";
import { Keypair, PublicKey } from "@solana/web3.js";
import {
  convert_from_wei_value,
  getSwitchboardPrice,
  convert_from_wei_value_with_decimal,
} from "../contract";
import {
  switchboardSolAccount,
  switchboardbsolAccount,
  switchboardjitosolAccount,
  SEED_TRV_PDA,
  mSOL_MINT,
  zSOL_DECIMAL,
  STATE_PUB,
  JITOSOL_MINT,
  BSOL_MINT,
  SEED_PDA,
  MSOL_DENOMINATOR,
} from "constants/global";

export const fetch_treasury_info = async (wallet) => {
  try {
    const network = getNetwork();
    const connection = getConnection();
    const program = getProgram(wallet, "lpIdl");
    const marinadeProgram = getProgram(wallet, "marinade_idl");

    const marinadeStateData = await marinadeProgram.account.state.fetch(
      STATE_PUB
    );

    const switchboardProgram = await loadSwitchboardProgram(
      network,
      connection,
      Keypair.fromSeed(new Uint8Array(32).fill(1))
    );

    const TreasuryPDA = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_TRV_PDA)],
      program.programId
    );

    // eslint-disable-next-line no-unused-vars
    const [stability_fee, _bump] = await PublicKey.findProgramAddress(
      [Buffer.from(SEED_PDA), Buffer.from("stability_fee")],
      program.programId
    );

    const TreasuryAccountData =
      await program.account.typelessRepaymentVault.fetch(TreasuryPDA[0]);

    const stabilityFeeData = await program.account.stabilityFee.fetch(
      stability_fee
    );

    const frequency = Number(stabilityFeeData.frequency);
    const lastActionTs = Number(stabilityFeeData.lastActionTs);
    const curTime = Date.now() / 1000;
    const epochCount = parseInt(
      ((curTime - lastActionTs) / frequency).toString()
    );

    const to_zsol_paused = TreasuryAccountData.toZsolPaused;
    const to_Xsol_paused = TreasuryAccountData.toXsolPaused;
    const is_trvc_deposit_paused = TreasuryAccountData.isTrvcDepositPaused;
    const is_trvc_withdraw_paused = TreasuryAccountData.isTrvcWithdrawPaused;
    const is_trvc_borrow_paused = TreasuryAccountData.isTrvcBorrowPaused;
    const is_trvc_repay_paused = TreasuryAccountData.isTrvcRepayPaused;
    const psm_zsol_xsol_swap_fee = TreasuryAccountData.psmZsolXsolSwapFee;

    const loaned_msol_amount = TreasuryAccountData.msolAmount.toNumber();
    const loaned_zsol_amount = TreasuryAccountData.zsolAmount.toNumber();
    const loaned_bsol_amount = TreasuryAccountData.bsolAmount.toNumber();
    const loaned_jitosol_amount = TreasuryAccountData.jitosolAmount.toNumber();
    const trvc_fee_rate = Number(TreasuryAccountData.stabilityFeeRate);
    const cur_global_fee_rate = Number(stabilityFeeData.totalMultiplier);
    const daily_fee_multiplier = Number(stabilityFeeData.dailyMultiplier);
    const multiplier = Math.pow(daily_fee_multiplier, epochCount);
    const new_global_fee_rate = cur_global_fee_rate * multiplier;

    console.log("loaned_msol_amount", loaned_msol_amount);

    const zSOL_amount_wei =
      Number(loaned_zsol_amount) * (new_global_fee_rate / trvc_fee_rate);

    // token prices cal
    const SOL_price = await getSwitchboardPrice(
      switchboardProgram,
      switchboardSolAccount
    );
    const jitosol_price = await getSwitchboardPrice(
      switchboardProgram,
      switchboardjitosolAccount
    );
    const bsol_price = await getSwitchboardPrice(
      switchboardProgram,
      switchboardbsolAccount
    );
    const mSOL_price =
      (SOL_price * Number(marinadeStateData.msolPrice.toString())) /
      MSOL_DENOMINATOR;

    // token amounts cal
    const mSOL_amount = convert_from_wei_value(mSOL_MINT, loaned_msol_amount);
    const bSOL_amount = convert_from_wei_value(BSOL_MINT, loaned_bsol_amount);
    const jitoSOL_amount = convert_from_wei_value(
      JITOSOL_MINT,
      loaned_jitosol_amount
    );
    const zSOL_amount = convert_from_wei_value_with_decimal(
      zSOL_amount_wei,
      zSOL_DECIMAL
    );

    const mSOL_value = mSOL_amount * mSOL_price;
    const zSOL_value = zSOL_amount * SOL_price;
    const bSOL_value = bSOL_amount * bsol_price;
    const jitoSOL_value = jitoSOL_amount * jitosol_price;

    console.log("mSOL_value", mSOL_value);
    console.log("zSOL_value", zSOL_value);
    console.log("bSOL_value", bSOL_value);
    console.log("jitoSOL_value", jitoSOL_value);

    // calculate infos
    const TotalSupply = mSOL_value + bSOL_value + jitoSOL_value;
    const TotalBorrowed = zSOL_value;
    const Cal_NetLTV = (TotalBorrowed / TotalSupply) * 100;
    const NetLTV = !isNaN(Cal_NetLTV) ? Cal_NetLTV : 0;

    const LiquidStakingInfos = [
      {
        name: "mSOL",
        balance: mSOL_amount,
        value: mSOL_value,
      },
      {
        name: "zSOL",
        balance: zSOL_amount,
        value: zSOL_value,
      },
      {
        name: "JITOSOL",
        balance: jitoSOL_amount,
        value: jitoSOL_value,
      },
      {
        name: "BSOL",
        balance: bSOL_amount,
        value: bSOL_value,
      },
      {
        name: "USDC",
        balance: 0,
        value: 0,
      },
      {
        name: "SOL",
        balance: 0,
        value: 0,
      },
    ];

    return {
      TotalSupply,
      TotalBorrowed,
      NetLTV,
      LiquidStakingInfos,
      to_zsol_paused,
      to_Xsol_paused,
      is_trvc_deposit_paused,
      is_trvc_withdraw_paused,
      is_trvc_borrow_paused,
      is_trvc_repay_paused,
      stability_fee_rate: psm_zsol_xsol_swap_fee,
    };
  } catch (error) {
    return {
      to_zsol_paused: true,
      to_msol_paused: true,
      is_trvc_deposit_paused: true,
      is_trvc_withdraw_paused: true,
      is_trvc_borrow_paused: true,
      is_trvc_repay_paused: true,
      stability_fee_rate: 0,
      TotalSupply: 0,
      TotalBorrowed: 0,
      NetLTV: 0,
      LiquidStakingInfos: [
        {
          name: "mSOL",
          balance: 0,
          value: 0,
        },
        {
          name: "zSOL",
          balance: 0,
          value: 0,
        },
        {
          name: "JITOSOL",
          balance: 0,
          value: 0,
        },
        {
          name: "BSOL",
          balance: 0,
          value: 0,
        },
        {
          name: "USDC",
          balance: 0,
          value: 0,
        },
        {
          name: "SOL",
          balance: 0,
          value: 0,
        },
      ],
    };
  }
};
