import React, { useMemo, useState, useEffect } from "react";
import TreasuryWrapper from "styles/Treasury.style";
import Card from "Layout/Card";
import Image from "Layout/Image";
import { TokenImgRegistry, DayList } from "assets/registry";
import GlobalChart from "components/globalComponents/GlobalChart";
import { useGlobal } from "contexts/GlobalContext";
import { numFormatter, calc, getDecimal } from "helper";
import Tabs from "./Tabs";
import { useCrypto } from "contexts/CryptoContext";
import { useContractSnackbar } from "contexts/ContractSnackbarContext";
import DataLoader from "components/globalComponents/Loaders/DataLoader";
import Button from "Layout/Button";
import {
  xTreasuryChartList,
  TreasuryChartList,
} from "assets/registry/TreasuryRegistry";
import { useTheme } from "contexts/ThemeContext";

const Treasury = () => {
  const { Theme } = useTheme();
  const {
    PriceList,
    PriceHandler,
    BalanceList,
    BalanceHandler,
    wallet,
    publicKey,
  } = useCrypto();
  const { OpenContractSnackbar, ContractSnackbarType } = useContractSnackbar();
  const [isAdmin, setIsAdmin] = useState(false);
  const {
    treasuryInfo,
    treasuryChart,
    setTreasurydays,
    Treasurydays,
    handleTreasuryInfo,
  } = useGlobal();

  useMemo(() => {
    if (
      (publicKey &&
        publicKey?.toBase58() ===
          "4y4wMTVz2FuTisGjcRYDnDYDPhfgpucUhH4p2CNwTdBF") ||
      publicKey?.toBase58() ===
        "EN2CV9nCnH9nBF9GyGYG9B3haNriNBkrPo8jF4c6mzUi" ||
      publicKey?.toBase58() ===
        "HwX6mV9u3Nn1D2dZQ1pZZT59WikUG2bQS71eEk55Ni5F" ||
      publicKey?.toBase58() ===
        "AZzscKGxcnS25oyvcLWoYWAQPE4uv4pycXR8ANq1HkmD" ||
      publicKey?.toBase58() ===
        "7KDQhb9KX8y9rkrtyAw4arkRVctGhaRhaUMCadfg4bEk" ||
      publicKey?.toBase58() ===
        "9pvCGNF2aw43Smb4J1pdyobq6PnjwkhXkuFov8P42S5w" ||
      publicKey?.toBase58() === "95Xw8G5nTAk5aQdu45PV2m3HdgcUeBmiAkRynwr64go8"
    ) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [publicKey]);

  useEffect(() => {
    if (ContractSnackbarType === "Success") {
      handleTreasuryInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ContractSnackbarType]);

  return (
    <TreasuryWrapper pie={treasuryInfo.NetLTV} Theme={Theme}>
      <div className="container Treasury">
        <div className="row">
          <div className="col-12 mt-lg-5 mt-md-4 mt-2">
            <div className="Treasury_section">
              <div className="row my-3 d-flex align-items-center">
                <div className="col-lg-6 col-12 Treasury_Details mb-lg-4 mb-4 mt-lg-0">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="Liquid_title">
                        <p>Treasury Details</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="overview_img">
                        <hr />
                        <Image
                          src={
                            Theme === "DARK"
                              ? "/images/figma/diamond.png"
                              : "/images/figma/xdiamond.png"
                          }
                          alt="Loading..."
                          h="0.6rem"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="overview_card d-flex flex-row">
                    <div className="bottom_borrow_img text-center">
                      <hr />
                      <Image
                        src={
                          Theme === "DARK"
                            ? "/images/figma/diamond.png"
                            : "/images/figma/xdiamond.png"
                        }
                        alt="Loading..."
                        h="0.6rem"
                      />
                    </div>

                    <Card
                      active={1}
                      br="18px"
                      className="mt-3 details_cart_section ml-lg-4 ml-md-4 ml-sm-3 ml-0"
                      Theme={Theme}
                    >
                      <div className="row d-flex flex-row align-items-center">
                        <div className="col-lg-6 col-md-5 col-12 details_cart_section_left">
                          <div className="cart">
                            <div className="pie animate no-round"></div>
                            <div className="totalSupplyPie">
                              <Image
                                src={
                                  Theme === "DARK"
                                    ? "/images/figma/ellipse.png"
                                    : "/images/figma/xellipse.png"
                                }
                                alt="Loading..."
                                h="5.5rem"
                              />
                            </div>
                          </div>

                          <div className="miter1">
                            <p className="ml-5 pl-2">100%</p>
                            <Image
                              src={
                                Theme === "DARK"
                                  ? "/images/figma/cartLine1.png"
                                  : "/images/figma/xcartLine1.png"
                              }
                              alt="Loading..."
                              w="140px"
                            />
                          </div>
                          <div className="miter2">
                            <p className="ml-5 pl-2">
                              {calc(treasuryInfo.NetLTV)}%
                            </p>
                            <Image
                              src={
                                Theme === "DARK"
                                  ? "/images/figma/cartLine2.png"
                                  : "/images/figma/xcartLine2.png"
                              }
                              alt="loading..."
                              w="136px"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-7 col-12 details_cart_section_right">
                          <div className="row">
                            <div className="col-lg-12 col-md-12 col-6 cart_details">
                              <p>Net Collateral</p>
                              <span>
                                ${numFormatter(treasuryInfo.TotalSupply)}
                              </span>
                            </div>

                            <div className="col-lg-12 col-md-12 col-6 cart_details mt-lg-5 mt-0">
                              <p>Net Borrowed</p>
                              <span>
                                ${numFormatter(treasuryInfo.TotalBorrowed)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
                {treasuryChart?.length > 0 && (
                  <div className="col-lg-6 col-12 mt-lg-0 mt-4">
                    <div className="row">
                      <div className="col-12 period_list d-flex justify-content-lg-end justify-content-md-end justify-content-center">
                        <ul>
                          {DayList.map((item, ind) => {
                            return (
                              <li
                                onClick={() => setTreasurydays(item.day)}
                                key={ind}
                              >
                                <Button
                                  active={Treasurydays === item.day ? 5 : 6}
                                  br="4px"
                                  p="0.2rem 0.5rem"
                                  id="btn"
                                  size="0.8rem"
                                  Theme={Theme}
                                >
                                  {item.title}
                                </Button>
                              </li>
                            );
                          })}
                        </ul>
                      </div>

                      <div className="col-12">
                        <div className="mt-3">
                          <GlobalChart
                            {...{
                              list: treasuryChart,
                              filterList:
                                Theme === "DARK"
                                  ? TreasuryChartList
                                  : xTreasuryChartList,
                              height: "250px",
                              Theme,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div
                className={`row  ${
                  isAdmin
                    ? "my-lg-5 my-md-5 my-sm-4 my-5 pb-lg-4 pb-md-4 pb-sm-3 pb-3 pt-lg-2 pt-md-2 pt-sm-2 pt-0"
                    : "mb-5"
                } `}
              >
                {isAdmin && (
                  <div className="col-lg-6 col-12">
                    <Tabs
                      {...{
                        wallet,
                        publicKey,
                        PriceList,
                        BalanceList,
                        BalanceHandler,
                        PriceHandler,
                        Theme,
                        OpenContractSnackbar,
                        is_trvc_deposit_paused:
                          treasuryInfo.is_trvc_deposit_paused,
                        is_trvc_withdraw_paused:
                          treasuryInfo.is_trvc_withdraw_paused,
                        is_trvc_borrow_paused:
                          treasuryInfo.is_trvc_borrow_paused,
                        is_trvc_repay_paused: treasuryInfo.is_trvc_repay_paused,
                      }}
                    />
                  </div>
                )}

                <div
                  className={`${
                    isAdmin
                      ? "col-lg-6 col-12 mt-lg-0 mt-5"
                      : "col-12 mt-lg-3 mt-5"
                  } Liquid_Staking`}
                >
                  <div className="row d-flex justify-content-lg-end justify-content-center">
                    <div
                      className={`${
                        isAdmin ? "col-lg-11 col-12" : "col-12"
                      } Liquid_title mb-3`}
                    >
                      <p>Liquid Staking</p>
                    </div>
                    <div className={isAdmin ? "col-lg-11 col-12" : "col-12"}>
                      <Card active={1} br="18px" p="2rem 1.5rem" Theme={Theme}>
                        <div className="row">
                          {treasuryInfo?.LiquidStakingInfos.length === 0 ? (
                            <div className="col-12">
                              <DataLoader size="2rem" h="100px" Theme={Theme} />
                            </div>
                          ) : (
                            <>
                              {treasuryInfo?.LiquidStakingInfos.slice(0, 4).map(
                                (list, ind) => {
                                  return (
                                    <div
                                      className={`${
                                        isAdmin ? "col-12" : "col-lg-6 col-12"
                                      } card_section d-flex flex-row pt-4`}
                                      key={ind}
                                    >
                                      <div className="img_section">
                                        <Image
                                          src={TokenImgRegistry[list.name]}
                                          alt={list.name}
                                          h="2.3rem"
                                        />
                                      </div>
                                      <div className="details ml-3">
                                        <div className="balance">
                                          {ind === 1 ? (
                                            <p>Total Debt</p>
                                          ) : (
                                            <p>Total Balance</p>
                                          )}

                                          <span>
                                            {getDecimal(list.balance, 5)}{" "}
                                            {list.name} (≈$
                                            {numFormatter(list.value)})
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </>
                          )}
                        </div>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TreasuryWrapper>
  );
};

export default Treasury;
