import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import ModeProvider from "./assets/theme";
import App from "./App";
import { ClusterProvider } from "contexts/ClusterContext";
import { ContractSnackbarProvider } from "contexts/ContractSnackbarContext";
import ContractSnackbar from "components/globalComponents/ContractSnackbar";
import { CommandProvider } from "contexts/CommandContext";
import { ThemeProvider } from "contexts/ThemeContext";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <ModeProvider>
        <ClusterProvider>
          <ContractSnackbarProvider>
            <CommandProvider>
              <ContractSnackbar />
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </CommandProvider>
          </ContractSnackbarProvider>
        </ClusterProvider>
      </ModeProvider>
    </ThemeProvider>
  </React.StrictMode>,

  document.getElementById("root")
);

reportWebVitals();
