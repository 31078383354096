import React, { useContext, createContext, useState, useEffect } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import {
  fetch_cbs_infos,
  get_stability_fee,
} from "utils/lp-protocol/get_cbs_info";
import { fetch_user_infos } from "utils/lp-protocol/get_user_info";
import { fetch_treasury_info } from "utils/treasury/get_treasury_info";
import api from "api";
import axios from "axios";
import { get_staker_account_info, get_config_info } from "utils/lpIncentives";
import { fetch_token_infos } from "utils/lp-protocol/fetch_token_infos";

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const wallet = useWallet();
  const { publicKey } = wallet;
  const [treasuryChart, setTreasuryChart] = useState([]);
  const [Treasurydays, setTreasurydays] = useState(7);
  const [stabilityFeeInfo, setStabilityFeeInfo] = useState({
    nextEpochDate: "0hr : 0m",
    stabilityFee: 0,
    maxMsolApy: 0,
  });

  const [cbsInfo, setCbsInfo] = useState({
    TotalSupply: 0,
    collateral_infos: [],
    borrowed_collateral_infos: [],
    TotalBorrowed: 0,
    NET_LTV: 0,
    TVL: 0,
    is_deposit_paused: false,
    is_withdraw_paused: false,
    is_repay_paused: false,
    is_borrow_paused: false,
    deposit_fee: 0,
  });

  const [cbsUserInfo, setCbsUserInfo] = useState({
    TotalDeposited: 0,
    CollateralInfos: [],
    TotalBorrowed: 0,
    BorrowedInfos: [],
    BorrowLimit: 0,
    LTV: 0,
    collateral_type: "",
  });

  const [treasuryInfo, setTreasuryInfo] = useState({
    TotalSupply: 0,
    TotalBorrowed: 0,
    NetLTV: 0,
    LiquidStakingInfos: [],
    to_zsol_paused: false,
    to_Xsol_paused: false,
    is_trvc_deposit_paused: false,
    is_trvc_withdraw_paused: false,
    is_trvc_borrow_paused: false,
    is_trvc_repay_paused: false,
    stability_fee_rate: 0,
  });

  const [nLPInfo, setNLPInfo] = useState({
    total_staked_amount: 0,
    is_paused: false,
  });

  const [nLPUserInfo, setNLPUserInfo] = useState({
    staked_amount: 0,
    RewardList: [],
  });

  const [liquidate_info, set_liquidate_info] = useState({
    docs: [],
    totalPages: 0,
  });

  const [token_infos, set_token_infos] = useState({
    cTokenData: {},
    ctokenInfos: [],
  });

  const handleTreasuryInfo = async () => {
    const {
      TotalSupply,
      TotalBorrowed,
      LiquidStakingInfos,
      NetLTV,
      to_zsol_paused,
      to_Xsol_paused,
      is_trvc_deposit_paused,
      is_trvc_withdraw_paused,
      is_trvc_borrow_paused,
      is_trvc_repay_paused,
      stability_fee_rate,
    } = await fetch_treasury_info(wallet);

    setTreasuryInfo({
      TotalSupply,
      TotalBorrowed,
      NetLTV,
      LiquidStakingInfos,
      to_zsol_paused,
      to_Xsol_paused,
      is_trvc_deposit_paused,
      is_trvc_withdraw_paused,
      is_trvc_borrow_paused,
      is_trvc_repay_paused,
      stability_fee_rate,
    });
  };

  const handleCbsInfo = async () => {
    const {
      TotalSupply,
      collateral_infos,
      TotalBorrowed,
      NET_LTV,
      TVL,
      borrowed_collateral_infos,
      is_deposit_paused,
      is_withdraw_paused,
      is_repay_paused,
      is_borrow_paused,
      deposit_fee,
    } = await fetch_cbs_infos(wallet);

    setCbsInfo({
      TotalSupply,
      collateral_infos,
      borrowed_collateral_infos,
      TotalBorrowed,
      NET_LTV,
      TVL,
      is_deposit_paused,
      is_withdraw_paused,
      is_repay_paused,
      is_borrow_paused,
      deposit_fee,
    });
  };

  const handleCbsUserInfo = async (account, Key) => {
    const {
      TotalDeposited,
      CollateralInfos,
      TotalBorrowed,
      BorrowedInfos,
      BorrowLimit,
      LTV,
      collateral_type,
    } = await fetch_user_infos(account, Key);

    setCbsUserInfo({
      TotalDeposited,
      CollateralInfos,
      TotalBorrowed,
      BorrowedInfos,
      BorrowLimit,
      LTV,
      collateral_type,
    });
  };

  const handle_nlp_user_info = async () => {
    const { staked_amount, RewardList } = await get_staker_account_info(wallet);

    setNLPUserInfo({
      staked_amount,
      RewardList,
    });
  };

  const handle_nlp_Info = async () => {
    const { total_staked_amount, is_paused } = await get_config_info(wallet);

    setNLPInfo({
      total_staked_amount,
      is_paused,
    });
  };

  const handleCbsChart = async (
    CbsDataDays,
    setLoadingChart,
    setCbsChartData
  ) => {
    try {
      setLoadingChart(true);
      const response = await axios.get(api.getCbsOverviewData, {
        params: {
          day: CbsDataDays,
        },
      });
      if (response.status === 200) {
        setCbsChartData(response.data);
        setLoadingChart(false);
      }
    } catch (error) {
      setCbsChartData([]);
      setLoadingChart(false);
    }
  };

  const handleTreasuryChart = async (day) => {
    try {
      const response = await axios.get(api.getTreasuryData, {
        params: { day },
      });
      if (response.status === 200) {
        setTreasuryChart(response.data);
      }
    } catch (error) {
      setTreasuryChart([]);
    }
  };

  const handlePsmChart = async (PsmDays, setLoadingChart, setPsmChart) => {
    try {
      setLoadingChart(true);
      const response = await axios.get(api.getDaySwapSize, {
        params: {
          day: PsmDays,
        },
      });
      if (response.status === 200) {
        setPsmChart(response.data);
        setLoadingChart(false);
      }
    } catch (error) {
      setPsmChart([]);
      setLoadingChart(false);
    }
  };

  const handleNlpChart = async (day, setLoadingChart, setChartData) => {
    try {
      setLoadingChart(true);

      const response = await axios.get(api.get_lp_incentives_info, {
        params: {
          day,
        },
      });
      if (response.status === 200) {
        setChartData(response.data);
        setLoadingChart(false);
      }
    } catch (error) {
      setChartData([]);
      setLoadingChart(false);
    }
  };

  const handle_stability_fee = async () => {
    const { nextEpochDate, stabilityFee, maxMsolApy } = await get_stability_fee(
      wallet
    );
    setStabilityFeeInfo({
      nextEpochDate,
      stabilityFee,
      maxMsolApy,
    });
  };

  const handle_liquidate = async (
    page,
    size,
    search,
    order,
    filterKey,
    setLoading
  ) => {
    try {
      setLoading(true);
      const response = await axios.get(api.get_liquidate_users, {
        params: { page, size, search, order, filterKey },
      });

      if (response.status === 200) {
        const { docs, totalPages } = response.data;
        set_liquidate_info({
          docs,
          totalPages,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      set_liquidate_info({
        docs: [],
        totalPages: 0,
      });
    }
  };

  const handle_token_infos = async () => {
    const { cTokenData, ctokenInfos } = await fetch_token_infos(wallet);
    set_token_infos({
      cTokenData,
      ctokenInfos,
    });
  };

  useEffect(() => {
    if (wallet) {
      handleCbsInfo();
      handleTreasuryInfo();
      handle_nlp_Info();
      handle_stability_fee();
      handle_token_infos();
    }

    let stabilityFeeInterval = setInterval(async () => {
      handle_stability_fee();
    }, 60000 * 5);

    return () => {
      clearInterval(stabilityFeeInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (publicKey) {
      handle_nlp_user_info();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKey]);

  useEffect(() => {
    handleTreasuryChart(Treasurydays);
  }, [Treasurydays]);

  return (
    <GlobalContext.Provider
      value={{
        cbsInfo,
        handleCbsInfo,
        handle_liquidate,
        cbsUserInfo,
        handleCbsUserInfo,
        handleTreasuryInfo,
        treasuryInfo,
        treasuryChart,
        nLPUserInfo,
        setNLPUserInfo,
        handle_nlp_user_info,
        handle_nlp_Info,
        nLPInfo,
        handlePsmChart,
        handleCbsChart,
        setTreasurydays,
        Treasurydays,
        handle_stability_fee,
        stabilityFeeInfo,
        liquidate_info,
        handleNlpChart,
        token_infos,
        handle_token_infos,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobal = () => useContext(GlobalContext);
