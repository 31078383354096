import styled from "styled-components";

const LiquidateT2Wrapper = styled.div`
  .popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.8);
    transition: all 0ms ease-in-out 200ms;
    z-index: -1;
    visibility: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
      background: inherit;
    }
  }

  .popup.active {
    transition: all 0ms ease-in-out;
    z-index: 1000;
    visibility: visible;
  }

  .popup .popup-container {
    position: relative;
    transform: scale(0);
    max-width: ${(props) => props.width} !important;
    min-width: ${(props) => props.width} !important;
    margin: auto;
    height: auto !important;
    border-radius: 18px;
    opacity: 0.5;
    transition: all 300ms ease-in-out;
    background: ${(props) => props.theme.lightBg};
    border: 2px solid ${(props) => props.theme.lightBgLightShadow};
  }

  .popup.active .popup-container {
    opacity: 1;
    transform: scale(1);
  }

  .popup {
    .LiquidateT2Model {
      padding: 0rem 0rem;

      .LiquidateT2Model_top_Section {
        ${(props) =>
          props.Theme === "DARK"
            ? `
            border-bottom: 3px solid rgba(255, 255, 255, 0.2);
            `
            : `
          border-bottom: 3px solid rgba(0, 0, 0, 0.2);
        `}

        .title {
          p {
            font-size: 1.4rem;
            color: ${(props) => props.theme.BodyMainColor};
            font-weight: bold;
          }
        }
        .close {
          i {
            color: ${(props) => props.theme.BodyMainColor};
            font-size: 1.3rem;
            cursor: pointer;
          }
        }
      }

      .Pay_section,
      .Receive_section {
        .title {
          font-size: 1.05rem;
          font-weight: 500;
          color: ${(props) => props.theme.BodyMainColor};
        }
        .balance {
          p {
            font-size: 0.8rem;
            color: ${(props) => props.theme.BodySecondaryColor};
          }
        }
      }
      .switch_section {
        .switch_icon_section {
          height: 2.4rem;
          width: 2.4rem;
          border: 1px solid rgba(118, 34, 254, 0.5);
          padding: 0rem;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          background: ${(props) => props.theme.lightBg};
          border-color: ${(props) => props.theme.lightBgDarkShadow};
          box-shadow: ${(props) => props.theme.boxShadowSecondary};
        }
      }
      .values {
        p {
          color: ${(props) => props.theme.BodySecondaryColor};
          font-size: 0.8rem;
        }
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .popup .popup-container {
      width: 95% !important;
      max-width: auto !important;
      min-width: auto !important;
    }
  }
`;

export default LiquidateT2Wrapper;
