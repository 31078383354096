import { PublicKey } from "@solana/web3.js";
import { getProgram, convert_from_wei_value_with_decimal } from "../contract";
import {
  zSOL_MINT,
  TRVC_SWAP_FEE,
  getMint,
  SEED_TRV_PDA,
  STATE_PUB,
  MSOL_DENOMINATOR,
} from "constants/global";

const getMaxInputAmount = async (
  input_token,
  token_balance,
  Treasury_data,
  msol_rate
) => {
  const mSOL_amount = convert_from_wei_value_with_decimal(
    Treasury_data.msolAmount,
    9
  );
  const zsol_amount = convert_from_wei_value_with_decimal(
    Treasury_data.zsolAmount,
    9
  );

  if (input_token.equals(zSOL_MINT)) {
    const max_output_amount = mSOL_amount;

    const max_input_amount =
      (Number(max_output_amount) * msol_rate) /
      MSOL_DENOMINATOR /
      (1 - TRVC_SWAP_FEE / 100);

    return Math.min(token_balance, max_input_amount, zsol_amount);
  } else {
    return token_balance;
  }
};

const getSwapRate = async (input_token, input_amount, msol_rate) => {
  try {
    if (input_token.equals(zSOL_MINT)) {
      const return_amount =
        (Number(input_amount) * MSOL_DENOMINATOR) / msol_rate;
      const output_amount = return_amount * (1 - TRVC_SWAP_FEE / 100);
      const fee_amount = return_amount - output_amount;

      return {
        fee_amount,
        output_amount,
      };
    } else {
      const zsol_amount = (msol_rate * Number(input_amount)) / MSOL_DENOMINATOR;

      return {
        fee_amount: 0,
        output_amount: zsol_amount,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      fee_amount: 0,
      output_amount: 0,
    };
  }
};

export const fetch_psm_rate = async (symbolA, amount, wallet) => {
  try {
    if (!amount) {
      return 0;
    } else {
      const input_token = getMint(symbolA);
      const marinadeProgram = getProgram(wallet, "marinade_idl");
      const marinadeStateData = await marinadeProgram.account.state.fetch(
        STATE_PUB
      );

      const { fee_amount, output_amount } = await getSwapRate(
        input_token,
        amount,
        Number(marinadeStateData.msolPrice.toString())
      );

      return {
        output_amount,
        fee_amount,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      fee_amount: 0,
      output_amount: 0,
    };
  }
};

export const getMxAmount = async (wallet, symbolA, balance) => {
  try {
    if (balance > 0) {
      const input_token = getMint(symbolA);

      const program = getProgram(wallet, "lpIdl");
      const marinadeProgram = getProgram(wallet, "marinade_idl");

      const marinadeStateData = await marinadeProgram.account.state.fetch(
        STATE_PUB
      );

      const TreasuryPDA = await PublicKey.findProgramAddress(
        [Buffer.from(SEED_TRV_PDA)],
        program.programId
      );

      const TreasuryAccountData =
        await program.account.typelessRepaymentVault.fetch(TreasuryPDA[0]);

      const max_input_amount = await getMaxInputAmount(
        input_token,
        balance,
        TreasuryAccountData,
        Number(marinadeStateData.msolPrice.toString())
      );

      return max_input_amount;
    } else {
      return 0;
    }
  } catch (error) {
    return 0;
  }
};
