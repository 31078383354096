import styled from "styled-components";

const CardWrapper = styled.div`
  width: 100%;
  border-radius: ${(props) => props.br};
  padding: ${(props) => props.p};

  ${(props) =>
    props.active === 1 &&
    (props.Theme === "DARK"
      ? `
      background: #161724;
      border-color: rgba(9, 9, 14, 0.5);
      box-shadow: 7px 7px 10px rgba(9, 9, 14, 0.5), -7px -7px 10px rgba(87, 87, 87, 0.2);
     `
      : `

      background: #e4ebf5;
      border-color:#bec8e4;
      box-shadow: 3px 3px 6px #bec8e4, -3px -3px 6px #fff;

  `)}

  @media only screen and (max-width: 600px) {
    padding: 0.5rem 0.5rem;
  }
`;

export default CardWrapper;
